import styled from "styled-components";

export const LoadingTag = styled.div`
  margin: "0 auto";
  text-align: center;
  float: "none";
`;

export const LoadingGif = styled.img`
  text-align: "center";
  margin: "0 auto";
  display: "block";
  width: "280px";
  height: "280px";
  left: "0px";
  top: "0px";
  opacity: 0.2;
`;