import React from 'react';
import './menu.styles.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { setUserAsStart, signOutStart } from '../../redux/user/user.actions';
import { Navbar, Icon, Button, Modal, TextInput } from 'react-materialize';
import { withRouter } from 'react-router-dom';
import SplashComponent from '../splash/splash.component';
import logo from '../../assets/logo2.png';
import { useState } from 'react';

const Header = ({ currentUser, signOutStart, history, titlePage, setUserAsEmail }) => {

  const [userAsEmail, setUserAsEmailState] = useState('');

  const setUserAsEmailChange = ev => {
    const { value, name } = ev.target;
    switch (name) {
      case 'email':
        setUserAsEmailState(value);
    }
  };

  return <>
    <header className='main-header'>
      <Navbar
        alignLinks="left"
        centerLogo
        brand={
          <img src={logo} className="App-logo" alt="logo" />
        }
        id="mobile-nav"
        className="transparent"
        menuIcon={<Icon style={{ color: "#000" }}>menu</Icon>}
        options={{
          draggable: true,
          edge: 'left',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          outDuration: 200,
          preventScrolling: true
        }}
      >
        <Link to="/">
          <div className="option">Home</div>
        </Link>

        {
          currentUser ? <>
            <Link className='option' to="/user/dashboard">
              Dashboard
            </Link>
          </> : <></>
        }
        <><Link className='option' to='/'>How It Works</Link></>
        <><Link className='option' to='/'>Faq</Link></>
        <div className="faq-width">
          &nbsp;
        </div>


        {
          currentUser ? <>
            <Link className='option' to="/user">
              Settings
            </Link>
          </> : <></>
        }


        {
          currentUser ? (
            <Link to="" onClick={() => { history.push("/"); signOutStart(); }} className="option">Log out</Link>
          ) : (
            <Link to="/signin"><div className="option">Login</div></Link>
          )
        }
        {/* <Link className="option" to="/contact">
          Contact
        </Link> */}
      </Navbar>


      {
        currentUser ? <>
          {
            titlePage ? <>

              <div className="showcase container">
                <div className="row">
                  <div className="col s12 m10 offset-m1 center white-text">
                    <h1>{titlePage}</h1>
                  </div>
                </div>
              </div>
            </> : <>


            </>
          }
        </> : <>
          {
            titlePage ? <>

              <div className="showcase container">
                <div className="row">
                  <div className="col s12 m10 offset-m1 center white-text">
                    <h1>{titlePage}</h1>
                  </div>
                </div>
              </div>
            </> : <>


            </>
          }
        </>
      }

    </header>
  </>;
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart()),
  setUserAsEmail: userEmail => dispatch(setUserAsStart(userEmail))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
