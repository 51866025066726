import { END, eventChannel } from 'redux-saga';
import {
  sendOtpAsync,
  insertUser,
  verifyAmazon,
  resetCode,
  getPendingRecipients,
  executePurchase,
  verifyAmazonAccountAvailable,
  getAmazonAccountService,
  saveTemplateService,
  getGiftTemplateService,
  fetchGiftHtmlTemplateService,
  saveAmazonEmailService,
  saveAmazonPkeService,
  updateCreditCardService,
  updateTemplateDataService,
  fetchTemplateDataService,
  fetchSelectedGiftUrlService,
  fetchAllTemplatesService,
  updateActiveEcardService,
  deleteRecipientByCreatedIdService,
  updateApprovedRecipientService,
  logoutService,
  loginService,
  fetchCCInfoService,
  fetchOrdersService,
  getRecipientsByTemplatename
} from './user.services';

let emit = null;

const channel = () => eventChannel(emitter => {
  emit = emitter;
  return () => { };
});

const thePromise = (theService, payload) => theService(payload, event => {
  if (event.loaded.total === 1) {
    emit(END);
  }
  emit(event.loaded.total);
});

export function createResetCode(payload) {
  const theChannel = channel();
  const resetCodePromise = thePromise(resetCode, payload);
  return [resetCodePromise, theChannel];
}

export function goGetAmazonAccountStart(payload) {
  const theChannel = channel();
  const getAmazonAccountPromise = thePromise(getAmazonAccountService, payload);
  return [getAmazonAccountPromise, theChannel]
}

export function createVerifyAmazonAccountAvailableStart(payload) {
  const theChannel = channel();
  const verifyAmazonAccountAvailablePromise = thePromise(verifyAmazonAccountAvailable, payload);
  return [verifyAmazonAccountAvailablePromise, theChannel];
}

export function createExecutePurchaseStart(payload) {
  const theChannel = channel();
  const executePurchaseStartPromise = thePromise(executePurchase, payload);
  return [executePurchaseStartPromise, theChannel];
}

export function createGetPendingRecipients(payload) {
  const theChannel = channel();
  const getPendingRecipientsPromise = thePromise(getPendingRecipients, payload);
  return [getPendingRecipientsPromise, theChannel];
}

export function createGetRecipientsByTemplatename(payload) {
  const theChannel = channel();
  const getRecipientsPromise = thePromise(getRecipientsByTemplatename, payload);
  return [getRecipientsPromise, theChannel];
}

export function createVerifyAmazon(payload) {
  const theChannel = channel();
  const verifyAmazonPromise = thePromise(verifyAmazon, payload);
  return [verifyAmazonPromise, theChannel];
};


export function createInsertUser(payload) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => { };
  });

  const insertUserPromise = insertUser(payload, event => {
    if (event.loaded.total === 1) {
      emit(END);
    }
    emit(event.loaded.total);
  });

  return [insertUserPromise, chan];
};

export function createSendOtp(payload) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => { };
  });

  const sendOtpPromise = sendOtpAsync(payload, event => {
    if (event.loaded.total === 1) {
      emit(END);
    }
    emit(event.loaded.total);
  });

  return [sendOtpPromise, chan];
}

export function getGiftTemplate(payload) {
  const theChannel = channel();
  const getGiftTemplatePromise = thePromise(getGiftTemplateService, payload);
  return [getGiftTemplatePromise, theChannel]
}

export function saveTemplate(payload) {
  const theChannel = channel();
  const saveTemplatePromise = thePromise(saveTemplateService, payload);
  return [saveTemplatePromise, theChannel];
}

export function fetchGiftHtmlTemplate(payload) {
  const theChannel = channel();
  const fetchGiftHtmlTemplatePromise = thePromise(fetchGiftHtmlTemplateService, payload);
  return [fetchGiftHtmlTemplatePromise, theChannel];
}

export function saveAmazonEmail(payload) {
  const theChannel = channel();
  const saveAmazonEmailPromise = thePromise(saveAmazonEmailService, payload);
  return [saveAmazonEmailPromise, theChannel];
}


export function saveAmazonPke(payload) {
  const theChannel = channel();
  const saveAmazonPkePromise = thePromise(saveAmazonPkeService, payload);
  return [saveAmazonPkePromise, theChannel];
}

export function updateCreditCard(payload) {
  const theChannel = channel();
  const updateCreditCardPromise = thePromise(updateCreditCardService, payload);
  return [updateCreditCardPromise, theChannel];
}

export function updateTemplateData(payload) {
  const theChannel = channel();
  const updateTemplateDataPromise = thePromise(updateTemplateDataService, payload);
  return [updateTemplateDataPromise, theChannel];
}

export function fetchTemplateData(payload) {
  const theChannel = channel();
  const fetchTemplateDataPromise = thePromise(fetchTemplateDataService, payload);
  return [fetchTemplateDataPromise, theChannel];
}

export function fetchSelectedGiftUrl(payload) {
  const theChannel = channel();
  const fetchSelectedGiftUrlPromise = thePromise(fetchSelectedGiftUrlService, payload);
  return [fetchSelectedGiftUrlPromise, theChannel];
}

export function fetchAllTemplates(payload) {
  const theChannel = channel();
  const fetchAllTemplatesPromise = thePromise(fetchAllTemplatesService, payload);
  return [fetchAllTemplatesPromise, theChannel];
}

export function updateActiveEcard(payload) {
  const theChannel = channel();
  const updateActiveEcard = thePromise(updateActiveEcardService, payload);
  return [updateActiveEcard, theChannel];
}

export function deleteRecipientByCreatedId(payload) {
  const theChannel = channel();
  const deleteRecipient = thePromise(deleteRecipientByCreatedIdService, payload);
  return [deleteRecipient, theChannel];
};

export function updateApprovedRecipient(payload) {
  const theChannel = channel();
  const updateApprovedRecipient = thePromise(updateApprovedRecipientService, payload);
  return [updateApprovedRecipient, theChannel];
}

export function fetchCCInfo(payload) {
  const theChannel = channel();
  const fetchCCData = thePromise(fetchCCInfoService, payload);
  return [fetchCCData, theChannel];
}

export function fetchOrders(payload) {
  const theChannel = channel();
  const fetchTheOrders = thePromise(fetchOrdersService, payload);
  return [fetchTheOrders, theChannel];
}


export function logout() {
  const theChannel = channel();
  const logoutUser = thePromise(logoutService);
  return [logoutUser, theChannel];
}

export function login() {
  const theChannel = channel();
  const loginUser = thePromise(loginService);
  return [loginUser, theChannel];

}
