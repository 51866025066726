import { Link } from "react-router-dom";
import logoFooter from "../../../assets/logo-footer.png";
import "./links-footer.styles.scss";
const LinksFooter = _ => {

  return <>
    <div style={{ display: 'flex', justifyContent: 'space-around', color: '#ffffff', margin: '0' }}>
      <div className="col s4">
        <img src={logoFooter} alt="Zen Giving Logo Footer" />
      </div>
      <div className="col s4 m5 l6">
        <h6><b>ZEN GIVING</b></h6>
        <Link className="links-color" to="">How it works?</Link><br />
        {/* <Link className="links-color" to="/contact">Contact</Link><br /> */}
        <Link className="links-color" to="/DMCA">DMCA</Link><br />
        <Link className="links-color" to="/privacy-policy">Privacy Policy</Link><br />
        <Link className="links-color" to="/terms-condition">Terms & Conditions</Link><br />
        <span>v.{process.env.REACT_APP_VERSION}</span><br />
      </div>
    </div>
  </>;
};


export default LinksFooter;
