import { connect } from "react-redux";
import { GridContainer } from "./card-list.styles";
import { createStructuredSelector } from "reselect";
import { selectCardCategory } from "../../redux/gift-sets/gift-sets.selectors";
import Card from "../card/card.component";
import React from "react";
import { selectAllCards } from "../../redux/cards/cards.selectors";
import { setSelectedCard, updateCards } from "../../redux/cards/cards.actions";
const CardList = ({ getCategoryName, getCards, updateTheCards, setSelectedCard }) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleChange = (theIndex, theType) => {
    // update selected
    let theCards = getCards;
    theCards.forEach((card, index) => {
      if (card.type === theType) {
        card.pathSrcs.forEach((path, idx) => {
          path.selected = false;
        });
        card.pathSrcs[theIndex].selected = true;
        setSelectedCard({
          cardUrl: card.pathSrcs[theIndex].src,
          cardIndex: theIndex,
          categoryIndex: index
        });
      } else {
        card.pathSrcs.forEach((path, idx) => {
          path.selected = false;
        });
      }
    });
    updateTheCards(theCards);
    forceUpdate();
  };

  const showCardsOfSelectedCategory = () => {
    return getCards?.map((card, idx) => {
      if (card.type === getCategoryName) {
        return card.pathSrcs.map((s, index) => {
          return (
            <div key={index} className="col s12 m6 l6 text-lighten-4">
              <Card
                pathSrc={s.src}
                key={index}
                position={index}
                type={getCategoryName}
                selected={s.selected}
                changeHandler={handleChange}
              />
            </div>

          );
        });
      }
      return <React.Fragment key={idx}></React.Fragment>;
    });
  };
  return (
    <div className="card-list">
      <GridContainer>{showCardsOfSelectedCategory()}</GridContainer>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  getCategoryName: selectCardCategory,
  getCards: selectAllCards,
});
const mapDispatchToProps = (dispatch) => ({
  updateTheCards: (cards) => dispatch(updateCards(cards)),
  setSelectedCard: (payload) => dispatch(setSelectedCard(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CardList);