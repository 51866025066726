import { memo, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import {
  fetchAllTemplatesStart,
  updateActiveEcardStart,
} from "../../redux/user/user.actions";
import {
  selectCurrentUser,
  selectTemplateArray,
} from "../../redux/user/user.selectors";

const EcardControlContainer = ({
  children,
  currentUser,
  fetchAllTemplates,
  updateActiveEcardByCreatedId,
}) => {
  const [allTheTemplates, indexAllTheTemplates] = useState([]);

  const fetchAllTemplatesStatuses = useCallback(() => {
    fetchAllTemplates(currentUser);
  }, [fetchAllTemplates, currentUser]);

  const onChecked = useCallback(
    (obj, val) => {
      let templates = allTheTemplates;
      templates[parseInt(obj.id)].expired = !val;
      indexAllTheTemplates((_) => templates);
      updateActiveEcardByCreatedId(obj);
    },
    [allTheTemplates, updateActiveEcardByCreatedId]
  );

  const onSubmitForm = (ev) => {
    ev.preventDefault();
  };

  useEffect(() => {
    fetchAllTemplatesStatuses();
  }, [fetchAllTemplatesStatuses]);

  return children(onSubmitForm, onChecked);
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  allTemplates: selectTemplateArray,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllTemplates: (currentUser) =>
    dispatch(fetchAllTemplatesStart(currentUser)),
  updateActiveEcardByCreatedId: (payload) =>
    dispatch(updateActiveEcardStart(payload)),
});

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps)
)(EcardControlContainer);
