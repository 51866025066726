import UserActionTypes from './user.types';

const INITIAL_STATE = {
  currentUser: {
    createdAt: {},
    displayName: "",
    displayEmail: "", // account email
    id: "",
    idToken: "",
    roles: [],
    currentOrgName: "",
    email: "", // current organization email context
    orders: {
      currentPage: 0,
      0: [
        {
          selected_itemname: "XNITO Bike Helmet with LED Lights - Urban Bicycle Helmet for Adults, Men amp Women - CPSC amp NTA-8776 Dual Certified - Class 3 E-Bikes, Scooters, Onewheel, Commuter, Mountain Bikes, MTB, BMX, Cycling",
          selected_itemimage: "https://m.media-amazon.com/images/I/71bpGn4K7hL._AC_SX425_.jpg",
          log: ['Gathering data...', 'Purchasing starting...', 'Entered recipient full name.', 'Entered address line 1.', 'Entered address line 2.', 'Entered city.', 'Entered state.', 'Entered phone.', 'Entered zip.', 'Set address.', 'Address might be okay.', 'Added address.'],
          final_message: "PURCHASED",
          purchased_time: "2023-07-06 22:18:28.680000+0000",
        }
      ]
    },
  },
  organizations: [ // orgs that this user belongs to
    {
      id: "",
      name: "",
      email: "",
      members: {
        "name": "",
        "email": "",
        "accepted": false,
        "roles": [],
      },
    }
  ],
  amazonAccountVerified: false,
  amazonAccount: null,
  showModal: false,
  otp: '',
  error: null,
  processing: true,
  processingCard: false,
  destinationPath: '/',
  paycards: [
    // {
    //     name: "",
    //     card_number: "",
    //     expiry: "",
    //     cvv: "",
    //     isDefault: true
    // }
  ],
  pendingRecipients: [
    {
      recipientemail: '',
      selected_url: '',
      created_id: '',
      is_approved: false
    }
  ],
  templateArray: [],
  currentTemplate: null,
  templateData: {
    template_name: "",
    template_data: ""
  },
  selectedGiftUrl: null,
  authAttempt: {
    status: "", // success, or error
    code: "",
    message: "",
    processing: true,
  },
};

const userReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case UserActionTypes.CHECK_USER_SESSION:
      return {
        ...state,
        showModal: false,
        processing: false
      }
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null
      }
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SEND_OTP_FAILURE:
    case UserActionTypes.INSERT_USER_FAILURE:
    case UserActionTypes.VERIFY_AMAZON_FAILURE:
      return {
        ...state,
        error: action.payload,
        otp: null,
        showModal: false,
        processing: false
      };
    case UserActionTypes.INSERT_USER_START:
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
        otp: null
      };
    case UserActionTypes.VERIFY_AMAZON_START:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
        otp: null,
        showModal: true,
        processing: false
      };
    case UserActionTypes.VERIFY_AMAZON_SUCCESS:
      return {
        ...state,
        otp: null,
        error: null,
        showModal: false,
        processing: false
      }
    case UserActionTypes.SHOW_OTP_MODAL:
      return {
        ...state,
        showModal: action.payload
      }
    case UserActionTypes.SEND_OTP_START:
      return {
        ...state,
        otp: action.payload,
        processing: true
      }
    case UserActionTypes.SEND_OTP_SUCCESS:
      return {
        ...state,
        otp: null,
        error: null
      }
    case UserActionTypes.RESET_CODE_START:
      return {
        ...state,
        processing: true
      }
    case UserActionTypes.RESET_CODE_SUCCESS:
      return {
        ...state,
        processing: false
      }
    case UserActionTypes.RESET_CODE_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.payload
      }
    case UserActionTypes.SET_DESTINATION_PATH:
      return {
        ...state,
        destinationPath: action.payload
      }
    case UserActionTypes.RESET_DESTINATION_PATH:
      return {
        ...state,
        destinationPath: '/'
      }
    case UserActionTypes.GET_PENDING_RECIPIENTS_SUCCESS:
    case UserActionTypes.GET_RECIPIENTS_BY_TEMPLATENAME_SUCCESS:
      return {
        ...state,
        error: null,
        processing: false,
        pendingRecipients: action.payload
      }
    case UserActionTypes.GET_PENDING_RECIPIENTS_FAILURE:
    case UserActionTypes.GET_RECIPIENTS_BY_TEMPLATENAME_FAILED:
      return {
        ...state,
        error: action.payload
      }
    case UserActionTypes.EXECUTE_PURCHASE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case UserActionTypes.EXECUTE_PURCHASE_SUCCESS:
      return {
        ...state,
        error: null
      }
    case UserActionTypes.EXECUTE_PURCHASE_START:
      return {
        ...state,
        error: null,
        currentUser: action.payload.currentUser,
        pendingRecipients: action.payload.recipients
      }
    case UserActionTypes.VERIFY_AMAZON_ACCOUNT_AVAILABLE_SUCCESS:
      return {
        ...state,
        error: null,
        amazonAccountVerified: true
      }
    case UserActionTypes.VERIFY_AMAZON_ACCOUNT_AVAILABLE_FAILURE:
      return {
        ...state,
        error: action.payload,
        amazonAccountVerified: false
      }
    case UserActionTypes.GET_AMAZON_ACCOUNT_START:
      return {
        ...state,
        currentUser: action.payload,
        error: null
      }
    case UserActionTypes.GET_AMAZON_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: null,
        amazonAccount: action.payload,
        amazonAccountVerified: true
      }
    case UserActionTypes.GET_AMAZON_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
        amazonAccount: null,
        amazonAccountVerified: false
      }
    case UserActionTypes.SAVE_TEMPLATE_START:
      return {
        ...state,
        currentTemplate: action.payload.template_html,
        error: null
      }
    case UserActionTypes.SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: null
      }
    case UserActionTypes.SAVE_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case UserActionTypes.GET_GIFT_TEMPLATE_ARRAY_START:
      return {
        ...state,
        error: null
      }
    case UserActionTypes.GET_GIFT_TEMPLATE_ARRAY_SUCCESS:
    case UserActionTypes.FETCH_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        templateArray: action.payload,
        error: null
      }
    case UserActionTypes.GET_GIFT_TEMPLATE_ARRAY_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case UserActionTypes.FETCH_GIFT_HTML_TEMPLATE_START:
      return {
        ...state,
        error: null,
        currentTemplate: null
      }
    case UserActionTypes.FETCH_GIFT_HTML_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: null,
        currentTemplate: action.payload
      }
    case UserActionTypes.FETCH_GIFT_HTML_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        currentTemplate: null
      }
    case UserActionTypes.SET_AMAZON_EMAIL:
      return {
        ...state,
        error: null,
        amazonAccount: {
          ...state.amazonAccount,
          amazonEmail: action.payload
        }
      }
    case UserActionTypes.SET_AMAZON_EMAIL_FAILURE:
    case UserActionTypes.SET_AMAZON_PKE_FAILURE:
      return {
        ...state,
        error: action.payload,
        amazonAccount: null
      }
    case UserActionTypes.SET_AMAZON_PKE:
      return {
        ...state,
        error: null,
        amazonAccount: {
          ...state.amazonAccount,
          amazonPassword: action.payload
        }
      }
    case UserActionTypes.UPDATE_CREDITCARD_START:
      var filtered = state.paycards.filter(function (value) {
        return value.card_number !== "";
      });
      filtered.forEach((val) => {
        val.isDefault = false;
      });
      state.paycards.push({ ...action.payload, isDefault: true });
      return {
        ...state,
        error: null,
        paycards: filtered
      }
    case UserActionTypes.UPDATE_CREDITCARD_FAILED:
    case UserActionTypes.UPDATE_TEMPLATE_DATA_FAILED:
    case UserActionTypes.FETCH_TEMPLATE_DATA_FAILED:
    case UserActionTypes.FETCH_SELECTED_GIFT_URL_FAILED:
    case UserActionTypes.SET_USER_AS_FAILURE:
    case UserActionTypes.FETCH_ALL_TEMPLATES_FAILURE:
    case UserActionTypes.DELETE_RECIPIENT_BY_CREATED_ID_FAILURE:
      return {
        ...state,
        error: action.payload,
        processingCard: false,
      }
    case UserActionTypes.FETCH_CC_INFO_FAILURE:
      return {
        ...state,
        error: action.payload,
        processingCard: false,
        paycards: [],
      }
    case UserActionTypes.UPDATE_CREDITCARD_SUCCESS:
    case UserActionTypes.DELETE_RECIPIENT_BY_CREATED_ID_SUCCESS:
      return {
        ...state,
        error: null
      }
    case UserActionTypes.FETCH_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        templateData: action.payload
      }
    case UserActionTypes.FETCH_SELECTED_GIFT_URL_SUCCESS:
      return {
        ...state,
        selectedGiftUrl: action.payload
      }
    case UserActionTypes.SET_USER_AS_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          userAs: action.payload
        }
      }
    case UserActionTypes.PROCESSING_START:
      return {
        ...state,
        processing: true,
      }
    case UserActionTypes.PROCESSING_END:
      return {
        ...state,
        processing: false,
      }
    case UserActionTypes.AUTH_ATTEMPT:
      return {
        ...state,
        authAttempt: action.payload,
      }
    case UserActionTypes.RESET_AUTH_ATTEMPT:
      return {
        ...state,
        authAttempt: {
          status: "", // success, or error
          code: "",
          message: "",
          processing: true,
        },
      }
    case UserActionTypes.SET_NEW_ORG_START:
      return {
        ...state,
        organizations: [
          ...state.organizations,
          {
            name: action.payload.name,
            email: action.payload.email,
          }
        ],
      }
    case UserActionTypes.FETCH_CC_INFO_START:
      return {
        ...state,
        error: null,
        processingCard: true,
      }
    case UserActionTypes.FETCH_CC_INFO_SUCCESS:
      return {
        ...state,
        error: null,
        processingCard: false,
        paycards: [
          {
            card_number: action.payload.card_number,
            expiry: action.payload.expiry,
            isDefault: true
          }
        ],
      }
    case UserActionTypes.FETCH_ORDERS_START:
      console.log("VIEW START: ", state.orders);
      const { payload: { perPage, pageNum } } = action;
      return {
        ...state,
        error: null,
        orders: {
          ...state.orders,
          perPage: perPage ?? 5000,
          currentPage: pageNum ?? 1,
        },
      }
    case UserActionTypes.FETCH_ORDERS_SUCCESS:
      // TODO update the currentPage, and add on the page array of orders as property.
      return {
        ...state,
        error: null,
        orders: {
          ...state.orders,
          ...action.payload.orders,
        },
      }
    case UserActionTypes.FETCH_ORDERS_FAILURE:
      // we'll retain the previous state of orders instead of replacing.
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
}

export default userReducer;
