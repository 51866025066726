import React, { useEffect, Suspense } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/spinner.component";
import PendingRecipients from "../../pages/pending/pending.component";
import EcardControl from "../ecard-control/ecard-control.component";
import OrdersPage from "../orders/orders.component";
import PendingOverview from "../../components/pending-overview/pending-overview.component";
import Header from "../../components/header/header.component";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";

import { setDestinationPath } from "../../redux/user/user.actions";
import FooterFinal from "../footer/footer.component";
import Organization from "../organization/organization.component";

const PendingDashboard = ({
  setDestinationPath,
  currentUser,
  history,
  match: { url, path },
}) => {
  useEffect(() => {
    if (!currentUser) {
      setDestinationPath(url);
      history.push(`/signin`);
    }
  });
  return (
    <>
      <Header titlePage={"Dashboard"} />
      <section className="section section-pending-list">
        <div className="container">
          <Suspense fallback={<Spinner />}>
            <Route exact path={`${path}`} component={EcardControl} />
            <Route path={`${path}/recipients`} component={PendingRecipients} />
            <Route path={`${path}/overview`} component={PendingOverview} />
            <Route path={`${path}/orders`} component={OrdersPage} />
          </Suspense>
        </div>
      </section>

      <FooterFinal />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setDestinationPath: (path) => dispatch(setDestinationPath(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingDashboard);
