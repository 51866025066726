import styled from 'styled-components';

export const AddCredCardText = styled.span`
  color: #5938BA;
`;

export const DataContainer = styled.div`
  background-color: white;
  border: 1px solid #9e9e9e;
  input[type=text]:not(.browser-default) {
  border-bottom:0px solid #9e9e9e !important;
  }
`;
