import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  getGiftsImagesTextStart
} from "../../../redux/gift-sets/gift-sets.actions";
import { selectedGiftSet } from "../../../redux/gift-sets/gift-sets.selectors";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { isUndefinedAndNullProductUrl } from "./isUndefinedAndNullProductUrl";


const GiftUrlInputContainer = ({
  children,
  currentUser,
  giftSet,
  getNamesImages,
}) => {
  const arr = [
    {
      selected: true,
      gifts: {
        giftChoice_0_0: isUndefinedAndNullProductUrl(giftSet[0]),
        giftChoice_0_1: isUndefinedAndNullProductUrl(giftSet[1]),
        giftChoice_0_2: isUndefinedAndNullProductUrl(giftSet[2]),
      },
    },
  ];


  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
      // if it's an amazon.com url
      let pattern = /(?:https:\/\/(?:www\.){0,1}amazon\.com(?:\/.*){0,1}(?:\/dp\/|\/gp\/product\/))(.*?)(?:\/.*|$)/g

      if (!pattern.test(value)) {
        event.target.classList.add('danger')
        // if url not from amazon
      } else {
        event.target.classList.remove('danger')
      }

      if (value === "") {
        event.target.classList.add('danger')
      }

  };
  return children({ handleChange });
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  giftSet: selectedGiftSet,
});

const mapDispatchToProps = (dispatch) => ({
  getNamesImages: (payload) => dispatch(getGiftsImagesTextStart(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftUrlInputContainer);
