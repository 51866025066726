import { ReceiveActionTypes } from './receive.types';

const INITIAL_STATE = {
    authorized: false,
    password: '',
    items: [],
    giver: '',
    confirmGift: null,
    error: null,
    emailConfirmProgress: false,
    recipientEmail: "",
    wasGiftClaimed: false,
    verifyEmailDone: false,
};

const receiveReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReceiveActionTypes.SET_AUTHORIZED:
            return {
                ...state,
                authorized: action.payload
            };
        case ReceiveActionTypes.SET_PASSWORD:
            return {
                ...state,
                password: action.payload
            }
        case ReceiveActionTypes.SET_ITEMS:
            return {
                ...state,
                items: action.payload
            }
        case ReceiveActionTypes.SET_GIVER:
            return {
                ...state,
                giver: action.payload
            }
        case ReceiveActionTypes.CONFIRM_GIFT_TREE_FAILED:
          return {
            ...state,
            error: action.payload
          }
        case ReceiveActionTypes.CONFIRM_GIFT_SELECTION_START:
            const { giverEmail, recipient, selectedUrl } = action.payload;
            return {
                ...state,
                confirmGift: {
                    giverEmail,
                    recipient,
                    selectedUrl
                }
            }
        case ReceiveActionTypes.CONFIRM_GIFT_SELECTION_SUCCESS:
            return {
                ...state,
                error: null,
                confirmGift: null
            }
        case ReceiveActionTypes.CONFIRM_GIFT_SELECTION_FAILURE:
            return {
                ...state,
                error: action.payload,
                confirmGift: null
            }
        case ReceiveActionTypes.EMAIL_CONFIRM_PROGRESS_START:
            return {
                ...state,
                error: null,
                emailConfirmProgress: true,
                recipientEmail: action.payload.recipientEmail,
                wasGiftClaimed: false,
                verifyEmailDone: false,
            }
        case ReceiveActionTypes.EMAIL_CONFIRM_PROGRESS_SUCCESS:
            return {
                ...state,
                error: null,
                emailConfirmProgress: false,
                wasGiftClaimed: action.payload.wasGiftClaimed,
                verifyEmailDone: true,
            }
        case ReceiveActionTypes.EMAIL_CONFIRM_PROGRESS_FAILED:
            return {
                ...state,
                error: action.payload.error,
                emailConfirmProgress: false,
                wasGiftClaimed: true,
                verifyEmailDone: true,
            }
        case ReceiveActionTypes.RESET_GIFT_CLAIMED_TO_FALSE:
            return {
                ...state,
                error: null,
                emailConfirmProgress: false,
                wasGiftClaimed: false,
                verifyEmailDone: false,
            }
        // case ReceiveActionTypes.TOGGLE_SELECTED:
        //     return {
        //         ...state,
        //         items: state.items.map(
        //             (val, index) => {
        //                 if (index === action.payload) { val.selected = true }
        //                 else { val.selected = false }
        //                 return val;
        //             })
        //     }
        default:
            return state;
    }
};

export default receiveReducer;
