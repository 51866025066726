import React, { useState } from "react";
import {Checkbox, Icon} from 'react-materialize';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { Link } from 'react-router-dom';

const EcardRow = ({ theTemplate, onChecked }) => {

  const [checked, setChecked] = useState((theTemplate.expired && (theTemplate.expired === true)) ? false : true);


  const filterName = (cardname) => {
    if (cardname) {
      const namearr = cardname.split("_");
      return namearr[namearr.length - 1];
    }
    return cardname;
  };


  return theTemplate ?
    <tbody><tr>
      <td style={{display: "flex"}}><Link to={{
        pathname: `/user/template/${theTemplate.template_name}`,
      }}><Icon>open_in_new</Icon></Link>&nbsp;&nbsp;&nbsp;<Link to={{
        pathname: "/user/dashboard/recipients",
        data: {template_name: theTemplate.template_name}
      }}>{filterName(theTemplate.template_name)}</Link></td>
      <td>{theTemplate.num_pending_approval ?? 0}</td>
      <td>{theTemplate.num_approved ?? 0}</td>
      <td>{theTemplate.num_purchased ?? 0}</td>
      <td>{theTemplate.num_need_action ?? 0}</td>
    </tr></tbody>
    : <tbody>No data yet.</tbody>;
};

const mapStateToProps = createStructuredSelector({
  // currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  // fetchAllTemplates: current_user => dispatch(fetchAllTemplatesStart(current_user))
});

export default connect(mapStateToProps, mapDispatchToProps)(EcardRow);
