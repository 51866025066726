import styled from 'styled-components';

export const TextSplash = styled.span`
color: #5938BA;
`;

export const HowWorksSectionContainer = styled.div`
background-color: #f3f4f5;
`;

export const CardContainer = styled.div`
width: 24rem;
`;
