import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser, selectOrders } from "../../redux/user/user.selectors";
import { fetchOrdersStart } from "../../redux/user/user.actions";


const OrdersContainer = ({ children, fetchOrdersByPageNum, orders, currentUser }) => {

  useEffect(() => {
    fetchOrdersByPageNum(currentUser);
    return () => { };
  }, [currentUser, fetchOrdersByPageNum]);

  return children({ orders });
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  orders: selectOrders,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrdersByPageNum: (currentUser, pageNum, perPage) => dispatch(fetchOrdersStart({ currentUser, pageNum, perPage })),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrdersContainer);
