import { Card } from "react-materialize";
import CardsTypes from "./cards.types";


export const INITIAL_STATE = {
  selectedCard: {
    cardUrl: "https://www.zengiving.co/templatize/Holidays_01.png",
    categoryIndex: 0,
    cardIndex: 0,
    sender: "",
    message: "",
  },
  cards: [
    {
      type: "holiday",
      pathSrcs: [
        { src: `https://www.zengiving.co/templatize/Holidays_01.png`, selected: true },
        { src: `https://www.zengiving.co/templatize/Holidays-02.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Holidays-03.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Holidays-04.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/happy_holidays_1.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/happy_holidays_2.png`, selected: false }
      ]
    },
    {
      type: "birthday",
      pathSrcs: [
        { src: `https://www.zengiving.co/templatize/Birthday_01.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Birthday-02.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Birthday-03.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Birthday-04.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/happy_birthday_1.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/happy_birthday_2.png`, selected: false }
      ]
    },
    {
      type: "congratulations",
      pathSrcs: [
        { src: `https://www.zengiving.co/templatize/Congrats-01.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Congrats-02.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Congrats-03.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Congrats-04.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/congrats_1.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/congrats_2.png`, selected: false }
      ]
    },
    {
      type: "thank_you",
      pathSrcs: [
        { src: `https://www.zengiving.co/templatize/Thanks-01.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Thanks-02.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Thanks-03.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Thanks-04.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/thank_you_1.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/thank_you_2.png`, selected: false }
      ]
    },
    {
      type: "graduation",
      pathSrcs: [
        { src: `https://www.zengiving.co/templatize/Graduation-01.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Graduation-02.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Graduation-03.png`, selected: false },
        { src: `https://www.zengiving.co/templatize/Graduation-04.png`, selected: false }
      ]
    },
  ],
  ecardTemplatesList: [],
  loading: false,
  error: null
};

export const cardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CardsTypes.UPDATE_CARDS:
      return {
        ...state,
        cards: action.payload
      };
    case CardsTypes.SET_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.payload
      };
    case CardsTypes.SET_SENDER:
      return {
        ...state,
        selectedCard: {
          ...state.selectedCard,
          sender: action.payload,
        }
      };
    case CardsTypes.SET_MESSAGE:
      return {
        ...state,
        selectedCard: {
          ...state.selectedCard,
          message: action.payload,
        }
      };
    default:
      return state;
  }
};

export default cardsReducer;