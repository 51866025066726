export const robotDate = (str) => {
  if (str) {
    var d = new Date(str);
    let ye, mo, da /*, ho, mi*/;
    ye = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(d);
    mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    // ho = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(d);
    // mi = new Intl.DateTimeFormat('en', { minute: '2-digit', hour12: false }).format(d);
    return `${mo} ${da} ${ye}`;
  }
  else { return "" };
};

export const monthString = (dateNow = new Date()) => dateNow.getUTCMonth() + 1 < 10
  ? "0" + (dateNow.getUTCMonth() + 1)
  : dateNow.getUTCMonth() + 1;

export const dayString = (dateNow = new Date()) => dateNow.getUTCDate() < 10
  ? "0" + dateNow.getUTCDate()
  : dateNow.getUTCDate();