import { takeLatest, put, call, all, fork } from 'redux-saga/effects';
import UserActionTypes from './user.types';
import { auth, googleProvider, createUserProfileDocument, getCurrentUser } from '../../firebase/firebase.utils';
import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  sendOtpFailure,
  sendOtpSuccess,
  insertUserSuccess,
  insertUserFailure,
  verifyAmazonFailure,
  resetCodeFailure,
  getPendingRecipientsFailure,
  executePurchaseFailure,
  verifyAmazonAccountAvailableFailure,
  getAmazonAccountFailure,
  saveTemplateFailure,
  getGiftTemplateArrayFailure,
  fetchGiftHtmlTemplateFailure,
  setAmazonEmailFailure,
  setAmazonPkeFailure,
  updateCreditCardFailed,
  updateTemplateDataFailed,
  fetchTemplateDataFailed,
  fetchSelectedGiftUrlFailed,
  fetchAllTemplatesFailure,
  updateActiveEcardFailure,
  deleteRecipientByCreatedIdFailure,
  updateApprovedRecipientFailure,
  fetchCCInfoFailure,
  fetchOrdersFailure, getRecipientsByTemplatenameFailed
} from './user.actions';
import {
  createSendOtp,
  createInsertUser,
  createVerifyAmazon,
  createResetCode,
  createGetPendingRecipients,
  createExecutePurchaseStart,
  createVerifyAmazonAccountAvailableStart,
  goGetAmazonAccountStart,
  saveTemplate,
  getGiftTemplate,
  fetchGiftHtmlTemplate,
  saveAmazonEmail,
  saveAmazonPke,
  updateCreditCard,
  updateTemplateData,
  fetchTemplateData,
  fetchSelectedGiftUrl,
  fetchAllTemplates,
  updateActiveEcard,
  deleteRecipientByCreatedId,
  updateApprovedRecipient,
  logout,
  login,
  fetchCCInfo,
  fetchOrders, createGetRecipientsByTemplatename
} from './user.hooks';

export function* getSnapshotFromUserAuth(userAuth, additionalData) {

  try {
    const userRef = yield call(createUserProfileDocument, userAuth, additionalData);
    const userSnapshot = yield userRef.get();
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data(), ...additionalData }));
  }
  catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup(googleProvider);
    const idToken = yield user.getIdToken(true);
    yield getSnapshotFromUserAuth(user, { idToken });

  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* signInWithFusionAuth() {
  try {
    // const { user } = yield auth.signInWithPopup(googleProvider);
    // const idToken = yield user.getIdToken(true);
    // yield getSnapshotFromUserAuth(user, { idToken });
    yield call(login);
  } catch (error) {
    // yield put(signInFailure(error));
  }
}


export function* onFusionAuthSignInStart() {
  yield takeLatest(UserActionTypes.FUSIONAUTH_SIGN_IN_START, signInWithFusionAuth);
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    const idToken = yield userAuth.getIdToken(true);
    yield getSnapshotFromUserAuth(userAuth, { idToken });
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOut() {
  try {
    yield call(logout);
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}


export function* sendOtp(action) {
  const [sendOtpPromise] = createSendOtp(action.payload);
  try {
    yield call(() => sendOtpPromise);
    yield put(sendOtpSuccess());
  } catch (error) {
    yield put(sendOtpFailure(error));
  }
}

export function* onSendOtpStart() {
  yield takeLatest(UserActionTypes.SEND_OTP_START, sendOtp);
}

export function* insertUserToDb(action) {
  try {
    yield call(createInsertUser, action.payload);
    yield put(insertUserSuccess());
  } catch (error) {
    yield put(insertUserFailure(error));
  }
}

export function* onInsertUserStart() {
  yield takeLatest(UserActionTypes.INSERT_USER_START, insertUserToDb);
}

export function* startVerifyAmazon(action) {
  try {
    yield call(createVerifyAmazon, action.payload);
  } catch (error) {
    // yield put(showOtpModal(false));
    yield put(verifyAmazonFailure(error));
  }
}

export function* onVerifyAmazonStart() {
  yield takeLatest(UserActionTypes.VERIFY_AMAZON_START, startVerifyAmazon);
}

export function* startResetCode(action) {
  try {
    yield call(createResetCode, action.payload);
  } catch (error) {
    yield put(resetCodeFailure(error));
  }
}

export function* onResetCodeStart() {
  yield takeLatest(UserActionTypes.RESET_CODE_START, startResetCode);
}

export function* startGetPendingRecipients(action) {
  try {
    yield call(createGetPendingRecipients, action.payload);
  } catch (error) {
    yield put(getPendingRecipientsFailure(error));
  }
}

export function* onGetPendingRecipientsStart() {
  yield takeLatest(UserActionTypes.GET_PENDING_RECIPIENTS_START, startGetPendingRecipients);
}

export function* startGetRecipientsByTemplatename(action) {
  try {
    yield call(createGetRecipientsByTemplatename, action.payload);
  } catch (e) {
    yield put(getRecipientsByTemplatenameFailed(e));
  }
}

export function* onGetRecipientsByTemplatenameStart() {
  yield takeLatest(UserActionTypes.GET_RECIPIENTS_BY_TEMPLATENAME_START, startGetRecipientsByTemplatename);
}

export function* executePurchaseStart(action) {
  try {
    yield call(createExecutePurchaseStart, action.payload);
  } catch (error) {
    yield put(executePurchaseFailure(error));
  }
}

export function* onExecutePurchaseStart() {
  yield takeLatest(UserActionTypes.EXECUTE_PURCHASE_START, executePurchaseStart);
}

export function* verifyAmazonAccountAvailableStart(action) {
  try {
    yield call(createVerifyAmazonAccountAvailableStart, action.payload);
  } catch (error) {
    yield put(verifyAmazonAccountAvailableFailure(error));
  }
}

export function* onVerifyAmazonAccountAvailableStart() {
  yield takeLatest(UserActionTypes.VERIFY_AMAZON_ACCOUNT_AVAILABLE_START, verifyAmazonAccountAvailableStart);
}

export function* getAmazonAccountStart(action) {
  try {
    yield call(goGetAmazonAccountStart, action.payload);
  }
  catch (error) {
    yield put(getAmazonAccountFailure(error))
  }
}

export function* onGetAmazonAccountStart() {
  yield takeLatest(UserActionTypes.GET_AMAZON_ACCOUNT_START, getAmazonAccountStart);
}

export function* saveTemplateStart(action) {
  try {
    yield call(saveTemplate, action.payload);
  } catch (error) {
    yield put(saveTemplateFailure(error));
  }
}

export function* onSaveTemplateStart() {
  yield takeLatest(UserActionTypes.SAVE_TEMPLATE_START, saveTemplateStart);
}

export function* getGiftTemplateArrayStart(action) {
  try {
    yield call(getGiftTemplate, action.payload);
  } catch (error) {
    yield put(getGiftTemplateArrayFailure(error));
  }
}

export function* onGetTemplateArrayStart() {
  yield takeLatest(UserActionTypes.GET_GIFT_TEMPLATE_ARRAY_START, getGiftTemplateArrayStart);
}

export function* goFetchGiftHtmlTemplateStart(action) {
  try {
    yield call(fetchGiftHtmlTemplate, action.payload);
  }
  catch (error) {
    yield put(fetchGiftHtmlTemplateFailure(error));
  }
}

export function* onFetchGiftHtmlTemplateStart() {
  yield takeLatest(UserActionTypes.FETCH_GIFT_HTML_TEMPLATE_START, goFetchGiftHtmlTemplateStart);
}

export function* goSetAmazonEmail(action) {
  try {
    yield call(saveAmazonEmail, action.payload);
  }
  catch (error) {
    yield put(setAmazonEmailFailure(error));
  }
}


export function* onSetAmazonEmail() {
  yield takeLatest(UserActionTypes.SET_AMAZON_EMAIL, goSetAmazonEmail);
}

export function* goSetAmazonPke(action) {
  try {
    yield call(saveAmazonPke, action.payload);
  }
  catch (error) {
    yield put(setAmazonPkeFailure(error));
  }
}


export function* onSetAmazonPke() {
  yield takeLatest(UserActionTypes.SET_AMAZON_PKE, goSetAmazonPke);
}

export function* goUpdateCreditCard(action) {

  try {
    yield call(updateCreditCard, action.payload);
  } catch (error) {
    yield put(updateCreditCardFailed(error));
  }
}

export function* onUpdateCreditCardStart() {
  yield takeLatest(UserActionTypes.UPDATE_CREDITCARD_START, goUpdateCreditCard);
}

export function* goUpdateTemplateData(action) {
  try {
    yield call(updateTemplateData, action.payload);
  } catch (error) {
    yield put(updateTemplateDataFailed(error));
  }
}

export function* onUpdateTemplateDataStart() {
  yield takeLatest(UserActionTypes.UPDATE_TEMPLATE_DATA_START, goUpdateTemplateData);
}

export function* goFetchTemplateData(action) {
  try {
    yield call(fetchTemplateData, action.payload);
  } catch (error) {
    yield put(fetchTemplateDataFailed(error));
  }
}

export function* onFetchTemplateDataStart() {
  yield takeLatest(UserActionTypes.FETCH_TEMPLATE_DATA_START, goFetchTemplateData);
}

export function* goFetchSelectedGiftUrl(action) {
  try {
    yield call(fetchSelectedGiftUrl, action.payload);
  } catch (error) {
    yield put(fetchSelectedGiftUrlFailed(error));
  }
}

export function* onFetchSelectedGiftUrlStart() {
  yield takeLatest(UserActionTypes.FETCH_SELECTED_GIFT_URL_START, goFetchSelectedGiftUrl);
}

export function* goFetchAllTemplates(action) {
  try {
    yield call(fetchAllTemplates, action.payload);
  } catch (error) {
    yield put(fetchAllTemplatesFailure(error));
  }
}

export function* onFetchAllTemplatesStart() {
  yield takeLatest(UserActionTypes.FETCH_ALL_TEMPLATES_START, goFetchAllTemplates);
}

export function* goUpdateActiveEcards(action) {
  try {
    yield call(updateActiveEcard, action.payload);
  } catch (error) {
    yield put(updateActiveEcardFailure(error));
  }
}

export function* onUpdateActiveEcardStart() {
  yield takeLatest(UserActionTypes.UPDATE_ACTIVE_ECARD_START, goUpdateActiveEcards);
}

export function* goDeleteRecipientByCreatedId(action) {
  try {
    yield call(deleteRecipientByCreatedId, action.payload);
  } catch (error) {
    yield put(deleteRecipientByCreatedIdFailure(error));
  }
}

export function* onDeleteRecipientByCreatedIdStart() {
  yield takeLatest(UserActionTypes.DELETE_RECIPIENT_BY_CREATED_ID_START, goDeleteRecipientByCreatedId);
}

export function* goUpdateApprovedRecipient(action) {
  try {
    yield call(updateApprovedRecipient, action.payload);
  } catch (error) {
    yield put(updateApprovedRecipientFailure(error));
  }
}

export function* onUpdateApprovedRecipientStart() {
  yield takeLatest(UserActionTypes.UPDATE_APPROVED_RECIPIENT_START, goUpdateApprovedRecipient);
}

export function* goFetchCCInfo(action) {
  try {
    yield call(fetchCCInfo, action.payload);
  } catch (error) {
    yield put(fetchCCInfoFailure(error));
  }
}

export function* onFetchCCInfoStart() {
  yield takeLatest(UserActionTypes.FETCH_CC_INFO_START, goFetchCCInfo);
}

export function* goFetchOrders(action) {
  try {
    yield call(fetchOrders, action.payload);
  } catch (error) {
    yield put(fetchOrdersFailure(error));
  }
}

export function* onFetchOrdersStart() {
  yield takeLatest(UserActionTypes.FETCH_ORDERS_START, goFetchOrders);
}

export function* userSagas() {
  yield all([
    fork(onGoogleSignInStart),
    fork(onEmailSignInStart),
    fork(onCheckUserSession),
    fork(onSignOutStart),
    fork(onSendOtpStart),
    fork(onInsertUserStart),
    fork(onVerifyAmazonStart),
    fork(onResetCodeStart),
    fork(onGetPendingRecipientsStart),
    fork(onExecutePurchaseStart),
    fork(onVerifyAmazonAccountAvailableStart),
    fork(onGetAmazonAccountStart),
    fork(onSaveTemplateStart),
    fork(onGetTemplateArrayStart),
    fork(onFetchGiftHtmlTemplateStart),
    fork(onSetAmazonEmail),
    fork(onSetAmazonPke),
    fork(onUpdateCreditCardStart),
    fork(onUpdateTemplateDataStart),
    fork(onFetchTemplateDataStart),
    fork(onFetchSelectedGiftUrlStart),
    fork(onFetchAllTemplatesStart),
    fork(onUpdateActiveEcardStart),
    fork(onDeleteRecipientByCreatedIdStart),
    fork(onUpdateApprovedRecipientStart),
    fork(onFetchCCInfoStart),
    fork(onFetchOrdersStart),
    fork(onGetRecipientsByTemplatenameStart),
  ]);
}
