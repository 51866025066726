import styled, { css } from 'styled-components';
const selectedContainerStyles = css`
  background: rgba(255, 255, 0, .2)
`;
const getSelectedStyles = props => {     
    if (props.selected) { return selectedContainerStyles; }
};

export const GridItem = styled.div`
  font-size: 30px;
  text-align: center;
  ${getSelectedStyles}
`;
export const GridContainer = styled.div`
  grid-template-columns: auto auto;
  padding: 10px;
`;