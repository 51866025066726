import React from 'react';
import './gift-list.styles.scss';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectItems } from '../../redux/receive/receive.selectors';
import Card from '../card/card.component';
import { setItems } from '../../redux/receive/receive.actions';

class GiftList extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            allitems: []
        }
    }

    componentDidMount() {
        // console.log("state:",this.state);
    }
    
    handleChange = (theid) => { 
        const { items, setItems } = this.props;
        setItems(items.map((val, index) => { 
            if (index === theid) {
                val.selected = true;
            }
            else { val.selected = false; }
            return val;
        }));
    }

    render() {        
        const { items } = this.props;
        return <div className="gift-list">
            {
                items.map(item => (
                    <Card key={item.id} item={item} changeHandler={this.handleChange} />
                ))
            }
        </div>
    }
};

const mapStateToProps = createStructuredSelector({
    items: selectItems
});

const mapDispatchToProps = dispatch => ({
    setItems: items => dispatch(setItems(items))
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftList);