import OtpModal from '../../components/otp-modal/otp-modal.component';
import Header from '../../components/header/header.component';
import FooterFinal from '../footer/footer.component';

const VerifyAma = () => {

  return <>
    <Header titlePage={"Verifying your Amazon Credentials"} />    
    
    <section className="section section-verify-ama">
      <div className="container">
        <div className="row">
          <div className="col s12 m8 offset-m2 l15 offset-13">
            <div className="card-panel hoverable verify-ama__65d2355b yellow darken-2">
              <OtpModal />
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterFinal />
  </>;

};

export default VerifyAma;