import { useState } from "react";
import StatusButton from "../status-button/status-button.component";
import { Checkbox, Button, Icon } from "react-materialize";
import { robotDate } from "../../utils/dateformat";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectProcessing } from "../../redux/user/user.selectors";
import {localInfo} from "../../utils/constants";

const OnePendingRecipient = ({
  recipientRow = null,
  onChange,
  id,
  onDelete,
  inProgress,
  key,
}) => {
  const [checked, setChecked] = useState(
    (recipientRow.status === "Approved")
  );
  const itemName = (item) => {
    if (item === undefined || item === null) {
      return "";
    } else {
      return item.substring(0, 36) + "...";
    }
  };

  const filterName = (cardname) => {
    if (cardname) {
      const namearr = cardname.split("_");
      return namearr[namearr.length - 1];
    }
    return cardname;
  };

  const onChangeCheckbox = (_) => {
    if(recipientRow.status === "Approved")
    {
      setChecked((prevState) => {
        onChange({ id, checked: false, recipientRow });
        recipientRow.status = "Denied";
        return false;
      });
    }
    else {
      setChecked((prevState) => {
        onChange({ id, checked: true, recipientRow });
        recipientRow.status = "Approved";
        return true;
      });

    }
    // localInfo("Checked was: ", checked, "Changing to: ", !checked);
    // setChecked((prevState) => {
    //   onChange({ id, checked: !prevState, recipientRow });
    //   return !prevState;
    // });


  };


  return (
    <>
      {recipientRow.recipient_name && inProgress === false && (
        <tr>
          <td>{recipientRow.recipientemail}</td>
          <td>{recipientRow.recipient_name}</td>
          <td>{filterName(recipientRow.template_name)}</td>
          <td>{robotDate(recipientRow?.date_created)}</td>
          <td>{itemName(recipientRow?.item_name)}</td>
          {recipientRow.recipient_name && (
            <td className="green-text text-darken-3">
              {["Pending Approval", "Approved", "Denied", "Purchased", "In Progress"].includes(
                recipientRow.status
              ) ? (
                recipientRow.status
              ) : (
                <StatusButton recipient={recipientRow} />
              )}
            </td>
          )}
          {recipientRow.recipient_name && (
            <td>
              <div className="switch">
                {
                  !["Purchased", "In Progress"].includes(
                    recipientRow.status
                  ) && <Checkbox
                    checked={(recipientRow.status === "Approved")}
                    disabled={((recipientRow.status === "Purchased") || (recipientRow.status === "In Progress")) ? true : false}
                    filledIn
                    id={id + ""}
                    label=""
                    value={id + ""}
                    waves="light"
                    onChange={onChangeCheckbox}
                  />
                }
              </div>
            </td>
          )}
          {recipientRow.recipient_name && (
            <td>
              <Button
                node="button"
                className="red"
                waves="light"
                onClick={() => {
                  onDelete(id);
                }}
              >
                <Icon>delete</Icon>
              </Button>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  inProgress: selectProcessing,
});

export default connect(mapStateToProps)(OnePendingRecipient);
