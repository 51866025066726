import { useCallback, useMemo } from "react";
import { io, Manager } from "socket.io-client";
import { store } from "../redux/store";
import { authAttempt, getPendingRecipientsSuccess } from "../redux/user/user.actions";
import { isProduction, localInfo, PATH_FOR_SOCKET, SOCKET_NAMESPACE_MAIN } from "../utils/constants";

const SERVER = SOCKET_NAMESPACE_MAIN;
let manager = null;
const useSocNsRm = (room, namespace = "/") => {

  const ioOptions = {
    path: PATH_FOR_SOCKET,
    forceNew: false,
    transports: ["polling"],
  };

  if (manager === null) {
    manager = new Manager(`${SERVER}`, ioOptions);
  }
  // const socket = io(SERVER, {
  //   path: "/api/socket.io/",
  //   forceNew: false,
  //   transports: ["polling"],
  // });

  // socket.on('connect', _ => {
  //   console.log("Successfully connected with socket io server");
  //   console.log(socket.id);
  //   // store.dispatch(setSocketId(socket.id));
  // });
  const socket = useMemo(() => manager.socket(namespace), [namespace]);
  const joinTheRoom = useCallback(joinRoom, [socket, room]);
  const leaveTheRoom = useCallback(leaveRoom, [socket, room]);
  return [socket, joinTheRoom, leaveTheRoom, sendData];
};

function sendData(emitterName = "payload", payload, socket) {
  localInfo("[socket] Sending data via socket");
  socket.emit(emitterName, payload, function (serverResponse) {
    localInfo(`[socket] Data Received from server: ${serverResponse}`);
  });
}

const joinRoom = (roomName, nsSocket) => {
  localInfo("joinRoom function");
  nsSocket.emit('joinRoom', (roomName), (socketid) => {
      localInfo(`joined Room ${roomName} with Socket ID: ${socketid}`);
  });
  nsSocket.on("welcome", (dataFromServer) => {
    localInfo("dataFromServer: ", dataFromServer);
  });

  nsSocket.on("buy-status", function (dataReceived) {
    localInfo("Server Says: ", dataReceived);
    store.dispatch(getPendingRecipientsSuccess(dataReceived));
  });

  nsSocket.on("purchase-status", function (dataReceived) {
    localInfo("SERVER VERIFY: ", dataReceived);
    store.dispatch(authAttempt(dataReceived));
  });
};

const leaveRoom = (roomName, socket) => {
  localInfo(`[LOG] leaving room: ${roomName}`);
}

export default useSocNsRm;
