import React, { Suspense, useState } from "react";
import {
  selectAmazonAccount,
  selectCurrentUser,
  selectDestinationPath,
} from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  setCurrentUser,
  showOtpModal,
  insertUserStart,
  verifyAmazonStart,
  resetDestinationPath,
  updateCreditCardStart,
} from "../../redux/user/user.actions";
import Header from "../../components/header/header.component";
import { withRouter } from "react-router-dom";

import FooterFinal from "../footer/footer.component";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { isProduction } from "../../utils/constants";
import { Dropdown, Divider, Button, Icon, Modal, TextInput, Row, Col, Collection, CollectionItem } from "react-materialize";
import Spinner from "../../components/spinner/spinner.component";
import UserSetup from "../user-setup/user-setup.component";
import Organization from "../organization/organization.component";
import Users from "../../components/users/users.component";

const UserSettings = (props) => {
  const { displayName, email, idToken } = props.currentUser;
  const currentRoom = `user-${props.currentUser.id}`;
  let myFormRef;
  const [currentOrg, setCurrentOrg] = useState("khairul@tunabear.com");
  const selectOrg = e => {
    setCurrentOrg(e.target.innerHTML);
    // update the store
  };

  useEffect(() => {
    const { history, selectDestPath, resetDestPath } = props;
    if (selectDestPath !== "/") {
      history.push(selectDestPath);
      resetDestPath();
    }
    if (!isProduction()) {
      console.log("[LOG] room: ", currentRoom);
    }
    return () => { };
  }, []);

  const onSubmit = (ev) => ev.preventDefault();

  const handleChange = async (event) => {
    // const { name, value } = event.target;
    // this.setState({ [name]: value }, () => {
    // });
  };

  const organizationContextDropdown = _ => <div className="row">
    <div className="col s12 m6 l4 black-text dark">
      <h6><b>Organization:</b></h6>
    </div>
    <div className="col s12 m6 l4">
      <Dropdown
        id="organization_dropdown"
        options={{
          alignment: 'left',
          autoTrigger: true,
          closeOnClick: true,
          constrainWidth: true,
          container: null,
          coverTrigger: true,
          hover: false,
          inDuration: 150,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          outDuration: 250
        }}
        trigger={<Button id="current_org" style={{ width: "20rem" }} node="button" className="purple">{currentOrg}</Button>}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          Switch organization context
        </div>
        <Divider />
        <a onClick={selectOrg} id="defaultOrg">
          khairul@tunabear.com
        </a>
        <a onClick={selectOrg} id="tunabear">
          Tunabear Consulting
        </a>

        <Link to="/user/pending/dashboard/org">
          <Icon>
            view_module
          </Icon>
          Manage organizations
        </Link>
        <a className="modal-trigger" href="#new_org">
          <Icon>
            add
          </Icon>
          Create organization
        </a>
      </Dropdown>
    </div>
  </div>;


  return (
    <>
      <Header titlePage={"Settings"} />
      <section className="section-signup">
        <div className="container green-text">
          <div className="row">
            <div className="col s12 m6 orange-text text-darken-4">

              <Row>
                <Col
                  m={6}
                  s={12}
                  style={{ width: '30rem' }}
                >
                  <div className="row" style={{ height: '5rem' }}>
                    <div className="col s12 m6 l4 black-text dark">
                    </div>
                    <div className="col s12 m6 l4">
                      <Modal
                        actions={[
                          <Button
                            onClick={() => { }}
                            modal="close"
                            node="button"
                            waves="light"
                            className="blue lighten-2 white-text"
                          >
                            Confirm
                          </Button>,
                          <span style={{ padding: "10px" }}>&nbsp;</span>,
                          <Button
                            modal="close"
                            node="button"
                            waves="light"
                            className="white black-text"
                          >
                            Cancel
                          </Button>,
                        ]}
                        bottomSheet={false}
                        fixedFooter={false}
                        header="Create new organization"
                        id="new_org"
                        open={false}
                        options={{
                          dismissible: true,
                          endingTop: "10%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "4%",
                        }}
                        className="black-text"
                      >
                        <TextInput
                          id="orgName"
                          placeholder="Organization Name"
                          onChange={() => { }}
                          icon="add"
                          name="orgName"
                          type="text"
                          required
                          value={""}
                        />

                        <TextInput
                          id="orgEmail"
                          placeholder="Organization Email"
                          onChange={() => { }}
                          icon="email"
                          name="email"
                          type="email"
                          required
                          value={""}
                        />
                      </Modal>
                    </div>
                  </div>
                  <Collection header=<span>Current organization: <br /><b>{currentOrg}</b></span>>
                    <CollectionItem>
                      <Link to="/s/settings/">
                        Users Management
                      </Link>
                    </CollectionItem>
                    <CollectionItem>
                      <Link to="/s/settings/org">
                        Organizations Management
                      </Link>
                    </CollectionItem>
                    <CollectionItem>
                      <Link to="/s/settings/billing">Billing Management</Link>
                    </CollectionItem>

                  </Collection>
                </Col>
              </Row>

            </div>
            <div className="col s12 m6">
              <Suspense fallback={<Spinner />}>
                <Route exact path={`${props.match.path}/billing`} component={UserSetup} />
                <Route exact path={`${props.match.path}/org`} component={Organization} />
                <Route exact path={`${props.match.path}/`} component={Users} />
              </Suspense>
            </div>
          </div>
        </div>
      </section>
      <FooterFinal />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  selectDestPath: selectDestinationPath,
  amaAcct: selectAmazonAccount,
});

const mapDispatchToProps = (dispatch) => ({
  // getAmaAcct: (user) => dispatch(getAmazonAccountStart(user)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  showOtpModal: (showIt) => dispatch(showOtpModal(showIt)),
  insertUser: (user) => dispatch(insertUserStart(user)),
  verifyAmazon: (user) => dispatch(verifyAmazonStart(user)),
  resetDestPath: () => dispatch(resetDestinationPath()),
  updateCreditCard: (data) => dispatch(updateCreditCardStart(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSettings)
);
