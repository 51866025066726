import { channel, thePromise } from '../main.hooks';
import {confirmGiftSelection, emailConfirmProgress, giftTreeProgress} from './receive.services';

export function createConfirmGiftSelection(payload) {
    const theChannel = channel();
    const confirmGiftSelectionPromise = thePromise(confirmGiftSelection, payload);
    return [confirmGiftSelectionPromise, theChannel];
}

export function createEmailConfirmProgress(payload) {
    const theChannel = channel();
    const emailConfirmProgressPromise = thePromise(emailConfirmProgress, payload);
    return [emailConfirmProgressPromise, theChannel];
}

export function startGiftTreeProgress(payload) {
  const theChannel = channel();
  const giftTreeConfirmProgressPromise = thePromise(giftTreeProgress, payload);
  return [giftTreeConfirmProgressPromise, theChannel];
}
