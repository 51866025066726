import { connect } from "react-redux";
import OrdersContainer from "./orders.container";
import { Card, CardTitle, Col, Icon, Row } from "react-materialize";
import { ColStyled } from "./orders.styles";

const OrdersPage = _ => {

  return <OrdersContainer>
    {({ orders }) => {
      console.log("orders: ", orders);
      return <>

        <Row style={{ paddingLeft: "9rem", width: "55rem", fontStyle: "italic" }}>
          <ColStyled
            m={12}
            s={12}
            l={9}
          >
            <Card
              actions={[
                <span style={{ color: "orange", fontStyle: "normal", fontWeight: "bolder" }}>
                  IN PROGRESS
                </span>
              ]}
              header={<CardTitle image="https://m.media-amazon.com/images/I/61QwQM2gEUL._AC_SY450_.jpg" />}
              horizontal
            >
              WinSpin 24 Inch Heavy Duty Prize Wheel Dual Use Adjustable Tabletop and Floor Stand Fortune Wheel with Dry Erase Marker amp Eraser 14 Slots Spinning Wheel for Carnival Spinner Game and Tradeshow
            </Card>
          </ColStyled>
        </Row>

        <Row style={{ paddingLeft: "9rem", width: "55rem", fontStyle: "italic" }}>
          <ColStyled
            m={12}
            s={12}
            l={9}
          >
            <Card
              actions={[
                <span style={{ color: "green", fontStyle: "normal", fontWeight: "bolder" }}>
                  PURCHASED
                </span>
              ]}
              header={<CardTitle image="https://m.media-amazon.com/images/I/61Zz6Tc6BkL._AC_UX385_.jpg" />}
              horizontal
            >
              MEETSUN Polarized Sunglasses for Women Men Classic Retro Designer Style
            </Card>
          </ColStyled>
        </Row>

        <Row style={{ paddingLeft: "9rem", width: "55rem", fontStyle: "italic" }}>
          <ColStyled
            m={12}
            s={12}
            l={9}
          >
            <Card
              actions={[
                <span style={{ color: "RED", fontStyle: "normal", fontWeight: "bolder" }}>
                  FAILED
                </span>
              ]}
              header={<CardTitle image="https://m.media-amazon.com/images/I/81QukPDvuwL._AC_SX425_.jpg" />}
              horizontal
            >
              iRobot Roomba i3 EVO 3150 Wi-Fi Connected Robot Vacuum  Now Clean by Room with Smart Mapping Works with Alexa Ideal for Pet Hair Carpets amp Hard Floors, Roomba i3
            </Card>
          </ColStyled>
        </Row>

      </>;
    }}
  </OrdersContainer>;
};

export default connect()(OrdersPage);
