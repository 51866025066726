import { LoadingGif, LoadingTag } from "./loading.styles";
import {Col, ProgressBar, Row} from "react-materialize";


const LoadingComponent = ({ counter, showCounter=false, showProgressBar=false, circleGif=false, message, style }) => <div style={{...style}}>
  <LoadingTag className="col deep-orange-text text-darken-1">
    <h4>Progress</h4>
    <span>
      {(message || "Loading..")}{" "}
    </span>
    <br />
    <>
      {showCounter && <h1>{counter ?? 0}</h1>}
      {counter && showProgressBar && <Row>
        <Col s={12}>
          <ProgressBar progress={(Math.round((counter/100.)*15.))} />
        </Col>
      </Row>}
      {circleGif && <LoadingGif
        src="https://media2.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
        alt="Loading GIF"
      />}
    </>
  </LoadingTag>
</div>;

export default LoadingComponent;
