import { connect } from "react-redux";
import { Button, Modal } from 'react-materialize'
import { useEffect } from "react";
import { Link } from "react-router-dom";

const StatusButton = ({ recipient }) => {

  useEffect(() => {
    // console.log("recipientRow: ", recipient);
  });
  return <>

    <Button
      className="modal-trigger red flow-text"
      style={{ fontSize: "10px", width: "10rem" }}
      href={"#status_purchase_" + recipient.created_id}
      node="button"
      small
    >
      action required
    </Button>
    <Modal
      actions={[
        <Button onClick={() => { }} modal="close" node="button" waves="light" className="blue lighten-2 white-text">Manual Purchase Confirm</Button>,
        <span style={{ padding: '10px' }}>&nbsp;</span>,
        <Button modal="close" node="button" waves="light" className="white black-text">Cancel</Button>
      ]}
      bottomSheet={false}
      fixedFooter={false}
      header="Action Required"
      id={"status_purchase_" + recipient.created_id}
      open={false}
      options={{
        dismissible: true,
        endingTop: '10%',
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: '4%'
      }}
      className="black-text"
    >
      Status: <p>Was approved. <br />
        But Purchase failed. </p>

      {recipient.status === "Card Failed" && <div>Amazon may require credit card verification while making this purchase. <br />And we were not able to provide the correct credit card details. <br />Before you try again, please provide us with the credit card details that you set as default with Amazon in this page: <Link to="/user">Setting</Link></div>}

      {recipient.status === "Review Address" && <div>Amazon was not able to verify the address the recipient provided.</div>}

      {recipient.status === "Auth Error" && <div>We were not able to sign in to your Amazon account. Please review your username, and password and try again. </div>}

      {recipient.status === "OTP Error" && <div>We were not able to sign in with the OTP. There was probably a large time gap between your receiving and your entering the OTP or that you entered an incorrect OTP. Please try again. </div>}
      <p>
        Item name: <br />
        {recipient.item_name}
      </p>
      <p>
        Where to purchase manually: <br />
        <a href={recipient.selected_url} rel="noreferrer" target="_blank">{recipient.selected_url}</a>
      </p>

    </Modal>

  </>;
};

export default connect(null, null)(StatusButton);