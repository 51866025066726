/**
 * represents several sets of gifts.
 * */

import {useEffect, useState} from "react";


export default function useGiftsetForm(initial = {
    ["0"]: {
        selected: true,
        gifts: {
            giftChoice_0_0:
                "https://www.amazon.com/HP-Wireless-Mouse-X3000-28Y30AA/dp/B08NM2GF2V/ref=sr_1_3?crid=28BE0771WVW08&keywords=wireless+mouse&qid=1641235011&sprefix=wireless+mouse%2Caps%2C122&sr=8-3",
            giftChoice_0_1:
                "https://www.amazon.com/Evriholder-WCS-AMZ-WCS-Winged-Corkscrew/dp/B0056DGNYI/ref=sr_1_6?crid=3DRM1HIQ0J5QA&keywords=wine+opener&qid=1641235103&sprefix=wine%2Caps%2C154&sr=8-6",
            giftChoice_0_2:
                "https://www.amazon.com/Nyuevo-Motivational-Leakproof-Temperater-Resistant/dp/B094TZN98Q/ref=sr_1_1?crid=23WS0TTIDI2VM&keywords=unisex+gifts+for+adults&qid=1641235184&refinements=p_36%3A-1000&rnid=386465011&sprefix=unisex+%2Caps%2C133&sr=8-1",
        }
    }
}, selectAllGifts, updateGiftSet) {
    const [inputs, setInputs] = useState(initial);
    const initialValues = Object.values(initial).join('');

    useEffect(() => {
        setInputs(initial);
    }, [initialValues]);

    function handleChange(e) {
        e.preventDefault();

        let { value, name } = e.target;

        let pattern = /(?:https:\/\/(?:www\.){0,1}amazon\.com(?:\/.*){0,1}(?:\/dp\/|\/gp\/product\/))(.*?)(?:\/.*|$)/g

        if (!pattern.test(value)) {
            e.target.classList.add('danger')
            // if url not from amazon
        } else {
            e.target.classList.remove('danger')
        }

        if (value === "") {
            e.target.classList.add('danger')
        }

      setInputs((prevInputs) => {
          const u = {
            ...selectAllGifts,
            [e.target.name]: e.target.value,
          };
          updateGiftSet(u);

          return {
                  ...prevInputs,
                  ["0"]: {
                      selected: true,
                      gifts: {
                          ...prevInputs["0"].gifts,
                          [name]: value
                      }
                  }
          }
      });
    }

    return { handleChange, inputs, setInputs };
}
