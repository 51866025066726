import axios from 'axios';
import { API_BASE_URL, localInfo } from '../../utils/constants';
import { store } from '../../redux/store';
import {
    setGiftSetsCount,
    countGiftSetsSuccessful,
    countGiftSetsFailure,
    insertGiftSetByOrderFailure,
    insertGiftSetByOrderSuccess,
    loadGiftSetsSuccess,
    loadGiftSetsFailure,
    getGiftsImagesTextSuccess,
    setGiftsNamesSuccess,
    setGiftsNamesStart,
    setGiftsNamesFailed
} from './gift-sets.actions';

export const countGiftSets = async (payload) => {
    const { idToken, email } = payload;
    const thePayload = { email };
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Type firebase Token ${idToken}`,
            'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
        },
        // withCredentials: process.env.NODE_ENV === 'production' ? true : false
    };
    axios.post(`${API_BASE_URL}/post/count-gift-sets`, thePayload, options)
        .then(res => {
            store.dispatch(countGiftSetsSuccessful());
            store.dispatch(setGiftSetsCount(parseInt(res.data.result)));
        }, error => {
            store.dispatch(countGiftSetsFailure(error));
        })
}

export const insertGiftSetByOrder = async (payload) => {
    const { currentUser: { idToken, email }, setIndex, arr } = payload;
    const thePayload = {
        setIndex,
        email,
        urls: Object.values(arr[setIndex].gifts)
    };
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Type firebase Token ${idToken}`,
            'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
        }
    };
    await axios.post(`${API_BASE_URL}/post/insert-gift-set`, thePayload, options)
        .then(res => {
            store.dispatch(insertGiftSetByOrderSuccess());
        }, error => {
            store.dispatch(insertGiftSetByOrderFailure(error));
        })
}

export const loadGiftSet = async (payload) => {
    const { idToken, email } = payload;
    const thePayload = {
        email
    };
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Type firebase Token ${idToken}`,
            'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
        }
    };
    axios.post(`${API_BASE_URL}/post/all-gifts-by-set`, thePayload, options)
        .then(res => {
            if (res.data.length !== 0) {
                store.dispatch(loadGiftSetsSuccess(res.data));
            }
            else {
                store.dispatch(loadGiftSetsSuccess([]));
            }

        }, error => {
            store.dispatch(loadGiftSetsFailure(error));
        })
}

function appendDonateATreeToChoices(finalPayload) {
    localInfo("finalPayload.giftSet: ", finalPayload.giftSet);
  return {
    ...finalPayload,
    landingImages: [...finalPayload.landingImages, "https://zengiving.co/templatize/plantree.png"],
    names: [...finalPayload.names, "Donate a Tree to Ethiopia"],
    gifts: [...finalPayload.giftSet, "https://onetreeplanted.org/collections/africa/products/africa"]
  }
}

export const getGiftsImageText = async (payload) => {
    const { giftSet, currentUser: { idToken, email } } = payload;
    const { currentUser } = payload;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Type firebase Token ${idToken}`,
            'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
        }
    };
    let finalPayload = {
        currentUser,
        names: [],
        landingImages: [],
        order_num: 0,
        giftSet
    };

    localInfo("giftSet: ", giftSet);

        await axios.post(`${API_BASE_URL}/post/get-names-images`, { giftSet, email }, options).then(async (res) => {
          localInfo("RESPONSE: ", res);
          await res.data.status.map((item, index) => {
            finalPayload.landingImages.push(item.value.data.landingImage);
            finalPayload.names.push(item.value.data.productTitle);
          });
          // finalPayload = appendDonateATreeToChoices(finalPayload);
          // localInfo("finalPayload: ", finalPayload);
            // for (let i = 0; i < res.data.status.length; ++i) {
            //     if (res.data.status[i].status === "rejected") {
            //         finalPayload.names.push("");
            //         finalPayload.landingImages.push("");
            //     }
            //     else {
            //         console.log("productTitle: ", res.data.status[i].value.data.productTitle);
            //         console.log("landingImage: ", res.data.status[i].value.data.landingImage);
            //         finalPayload.names.push(res.data.status[i].value.data.productTitle);
            //         finalPayload.landingImages.push(res.data.status[i].value.data.landingImage);
            //     }
            // }

            store.dispatch(getGiftsImagesTextSuccess(await appendDonateATreeToChoices(finalPayload)));
            store.dispatch(setGiftsNamesStart(await appendDonateATreeToChoices(finalPayload)));
        });


};

export const setGiftsNames = async (payload) => {
    const { currentUser: { idToken } } = payload;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Type firebase Token ${idToken}`,
            'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
        }
    };

    await axios.post(`${API_BASE_URL}/post/set-gifts-names`, payload, options)
        .then(_ => {
            store.dispatch(setGiftsNamesSuccess());
        }, error => {
            store.dispatch(setGiftsNamesFailed(error));
        })
    // await store.dispatch(setGiftsNamesSuccess());
};
