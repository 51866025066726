import { createSelector } from 'reselect';

const selectCards = state => state.cards;

export const selectAllCards = createSelector(
  [selectCards],
  c => c.cards
);

export const selectedCard = createSelector(
  [selectCards],
  c => c.selectedCard
);

export const selectSender = createSelector(
  [selectedCard],
  c => c.sender,
);

export const selectMessage = createSelector(
  [selectedCard],
  c => c.message,
);