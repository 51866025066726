import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Header from '../../components/header/header.component';
import { selectAuthAttemptData, selectCurrentUser } from '../../redux/user/user.selectors';
import FooterFinal from '../footer/footer.component';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import InitBuyModalComponent from "./init-buy-modal.component";


const GiverExecution = (props) => {
  const { authAttempt, history } = props;



  useEffect(() => {
    if (!authAttempt.processing && authAttempt.status === "success") {
      setTimeout(function () {
        history.push("/user/to-give");
      }, 60000);
    }
  }, [history, authAttempt.processing, authAttempt.status]);

  return <>
    <Header titlePage={"Purchase Activation"} />
    <InitBuyModalComponent authAttempt={authAttempt}/>
    <FooterFinal />
  </>;
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  authAttempt: selectAuthAttemptData,
});

export default withRouter(connect(mapStateToProps)(GiverExecution));
