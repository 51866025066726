import styled from "styled-components";

export const DownloadWrapper = styled.div`
  cursor: pointer;
  margin: -8px;
  &:hover {
    opacity: .7;
  }

  i.material-icons {
    font-size: 30px;
    color: #000;
    height: 30px;
    width: 30px;
    margin: auto;
    display: block;
  }

  p {
    text-align: center;
    color: #000;
    margin: auto;
    height: 30px;
    font-size: 12px;
    font-family: 'Abel', sans-serif;
  }
`;

export const ECardContainer = styled.div`
  width: 100vw;
`;

export const CardPanelContainer = styled.div`
  width: 79vw;
  @media screen and (max-width: 800px) {
    width: 100vw;
  }
`;
