import Menu from "../../components/menu/menu.component"
import "./contact.styles.scss"
import { useEffect } from "react";
import { connect } from 'react-redux';
import HubspotForm from 'react-hubspot-form';
import { setDestinationPath } from "../../redux/user/user.actions";
import FooterFinal from "../../pages/footer/footer.component";

const ContactComponent = ({ setDestinationPath }) => {
  useEffect(() => {
    setDestinationPath("/");
  }, []);
  return (
    <>
      <Menu></Menu>

      <div className="contact">
        <h1 className="text-lighten-4 text-center" style={{ marginTop: "20vh", color: "#6335bf" }}>
          Contact us
        </h1>
        <div className="grey-text text-lighten-4 white"
          style={{
            width: "20rem", color: 'white', margin: '0 auto',
            backgroundColor: '#6746d0', borderRadius: '25px', padding: '17px'
          }}>
          <HubspotForm
            portalId='8836058'
            formId="263508f7-ac46-4038-a052-8db60300ffa5"
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </div>
      </div>

      <FooterFinal />
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  setDestinationPath: path => dispatch(setDestinationPath(path)),
});

export default connect(null, mapDispatchToProps)(ContactComponent);