import React from 'react';
import { TextInput } from 'react-materialize';
import './form-input.styles.scss';

const FormInput = ({ handleChange, label, ...otherProps }) => (
    <div className='group'>
        <div>{otherProps.groupTitle}</div>
        {otherProps.type === 'radio' ? (            
            radioInput({ label, ...otherProps })
        ) : (normaInput({ handleChange, label, ...otherProps }))}
    </div>
);

const radioInput = ({ label, ...otherProps }) => {
    const { groupName } = otherProps;
    const ids = otherProps.radioIds.split(",");
    const vals = otherProps.radioValues.split(",");
    console.log(otherProps)
    return vals.map((value,index) => (
        <div key={ index }>
            <input type="radio" id={ids[index]} name={groupName} value={value} onChange={ otherProps.onChange } defaultChecked={otherProps.value === value ? true : false} />
                <label for={ids[index]}>{ value }</label>
        </div>
    ));
};

// const normaInput = ({ handleChange, label, ...otherProps }) => (
//     <>
//         <input className='form-input' onChange={handleChange} {...otherProps} />
//         {
//             label ? (
//                 <label
//                     className={ `${otherProps.value.length ? 'shrink' : ''} form-input-label` }
//                 >
//                     {label}
//                 </label>                 
//             )
//             : null
//         }
//     </>
// );

const normaInput = ({ handleChange, label, ...otherProps }) => (
    <>
        <div className="input-field">
        <TextInput
            onChange={handleChange} {...otherProps}
        />
        </div>
    </>
);



export default FormInput;