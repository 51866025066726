import { CardPanelContainer } from "./recipient-identity.styles";
import React, { useState } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { TextInput } from "react-materialize";
import { selectDestinationPath } from "../../redux/user/user.selectors";

const RecipientIdentity = (props) => {
  const [email, setEmail] = useState("");
  const { history } = props;

  const handleSubmit = async (event) => {
    event.preventDefault();
    history.push({
      pathname: history.location.state.url,
      state: { email },
    });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      default:
        console.error("Something went wrong!");
        break;
    }
  };

  return (
    <>
      <section className="section section-identity">
        <div className="container">
          <div className="row">
            <div className="col s12 m8 offset-m2 l6 offset-l3">
              <div className="card-panel hoverable blue darken-2">
                <CardPanelContainer>
                  <h4>Proceed with your Email</h4>
                  <span></span>
                  <form onSubmit={handleSubmit}>
                    <div className="input-field">
                      <TextInput
                        icon="email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={email}
                        placeholder="Email"
                        required
                      />
                    </div>
                    {/* <Link to={{
                  pathname: "/giver",
                  state: {
                    recipients: theRecipients
                  }
                }}> */}
                    <input
                      type="submit"
                      value="Proceed"
                      className="btn btn-large blue btn-extended__ae140c51 white-text"
                    />
                    {/* </Link> */}
                  </form>
                </CardPanelContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectDestPath: selectDestinationPath,
});

export default connect(mapStateToProps)(RecipientIdentity);
