import React from "react";
import {
  selectAmazonAccount,
  selectCurrentUser,
  selectDestinationPath,
  selectPaycards,
  selectProcessingCard,
} from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  setCurrentUser,
  showOtpModal,
  insertUserStart,
  verifyAmazonStart,
  resetDestinationPath,
  updateCreditCardStart,
  fetchCCInfoStart,
} from "../../redux/user/user.actions";
import "./user-setup.styles.scss";
import { withRouter } from "react-router-dom";
import Card from "../../components/credit-card/Card";

import { Form, Field } from "react-final-form";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../components/credit-card/cardUtils";
import useSocNsRm from "../../hooks/useSocNsRm";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {isProduction, localInfo} from "../../utils/constants";
import LoadingComponent from "../../components/loading/loading.component";

const UserSetup = (props) => {
  const { displayName, email, idToken } = props.currentUser;
  const currentUserString = JSON.stringify(props.currentUser.email);
  const { saveCreditCard, isFetchingCards, paycards } = props;
  const currentRoom = `user-${props.currentUser.id}`;
  const [socket, joinTheRoom] = useSocNsRm(currentRoom, "/");
  let myFormRef;
  useEffect(() => {
    const { history, selectDestPath, resetDestPath, fetchCreditCardBasicInfo } = props;
    if (selectDestPath !== "/") {
      history.push(selectDestPath);
      resetDestPath();
    }
    joinTheRoom(currentRoom, socket);
    fetchCreditCardBasicInfo(props.currentUser);
    localInfo("[LOG] room: ", currentRoom);
    localInfo("currentUserString: ", currentUserString);
    return () => { };
  }, [currentUserString]);

  const onSubmit = (ev) => ev.preventDefault();

  const handleChange = async (event) => {
    // const { name, value } = event.target;
    // this.setState({ [name]: value }, () => {
    // });
  };

  return (
    <>
      <div className="card-panel grey lighten-4 grey-text text-darken-4 z-depth-0">
        <form
          className="user-setup-form"
          onSubmit={() => { }}
          ref={(el) => (myFormRef = el)}
        >
          <br />
          <div className="input-field">
            <input
              type="text"
              id="displayName"
              name="displayName"
              value={displayName === undefined ? "" : displayName}
              onChange={handleChange}
              label="Display Name"
              required
              disabled
            />
            <label htmlFor="displayName"></label>
            <span className="helper-text">Display Name</span>
          </div>
          <div className="input-field">
            <input
              type="email"
              id="email"
              name="email"
              value={email === undefined ? "" : email}
              onChange={handleChange}
              label="Email"
              required
              disabled
            />
            <label htmlFor="email"></label>
            <span className="helper-text">Email</span>
          </div>
          <br />
          <div className="container">
            <div className="row">
              <div className="col s12 m12">
                {isFetchingCards && <LoadingComponent message=" " circleGif={true} />}
                {!isFetchingCards && (paycards.length > 0) && (
                  <><div class="col-sm-1 col-xs-2 col-pad-0"><span class="icon-container-radius cards-icon shadow visa-card"></span></div><div class="col-sm-9 col-xs-8 inner-pad-left-15 col-pad-0"><p class="col-sm-9 col-xs-12 mymoney-desc col-pad-0">Your Card: <span role="text" class="pad-left-6"> • • • • {paycards[0].card_number}</span></p><span class="col-sm-9 col-xs-12 mymoney-desc col-pad-0">Expires {paycards[0].expiry}<div class="col-xs-12 col-pad-0"></div></span></div><div class="col-xs-2 text-right"><a href="/businesswallet/money/carddetails/CC-WZ6UJQQPR2KK2?flow=eyJyZXR1cm5VcmwiOiIvYnVzaW5lc3NtYW5hZ2UvYWNjb3VudC9tb25leSIsImNhbmNlbFVybCI6Ii9idXNpbmVzc21hbmFnZS9hY2NvdW50L21vbmV5In0=" target="_top" data-pa-click="main:businessweb:profile:mymoney:editcard" pa-marked="1">Delete</a></div></>
                )}
                {!isFetchingCards && (paycards.length === 0) && (<Form
                  onSubmit={onSubmit}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                    active,
                  }) => {
                    return (
                      <React.Fragment>
                        <Card
                          number={values.number || ""}
                          name={values.name || ""}
                          expiry={values.expiry || ""}
                          cvc={values.cvc || ""}
                          focused={active}
                        />
                        <div>
                          <Field
                            name="number"
                            id="number"
                            component="input"
                            type="text"
                            pattern="[\d| ]{16,22}"
                            placeholder="Card Number"
                            format={formatCreditCardNumber}
                          />
                        </div>
                        <div>
                          <Field
                            name="name"
                            id="name"
                            component="input"
                            type="text"
                            placeholder="Name on card"
                          />
                        </div>
                        <div>
                          <Field
                            name="expiry"
                            id="expiry"
                            component="input"
                            type="text"
                            pattern="\d\d/\d\d"
                            placeholder="Valid Thru"
                            format={formatExpirationDate}
                          />
                          <Field
                            name="cvc"
                            id="cvc"
                            component="input"
                            type="text"
                            pattern="\d{3,4}"
                            placeholder="CVC"
                            format={formatCVC}
                          />
                        </div>
                        <div className="buttons">
                          <div className="container">
                            <div className="row">
                              <div className="col s12 m12">
                                <Link to="/user/to-give">
                                  <button
                                    className="btn btn-large orange btn-extended__60a3d156 waves-effect waves-light"
                                    type="button"
                                    onClick={() => {
                                      toast.info(
                                        "Updating your card.",
                                        {
                                          position: "bottom-center",
                                          autoClose: 3000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                      saveCreditCard({
                                        email,
                                        idToken,
                                        creditCard: window.btoa(
                                          JSON.stringify(values, 0, 2)
                                        ),
                                      });
                                    }}
                                  >
                                    Confirm
                                    <i className="material-icons left">
                                      send
                                    </i>
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }}
                />)}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  selectDestPath: selectDestinationPath,
  amaAcct: selectAmazonAccount,
  paycards: selectPaycards,
  isFetchingCards: selectProcessingCard,
});

const mapDispatchToProps = (dispatch) => ({
  // getAmaAcct: (user) => dispatch(getAmazonAccountStart(user)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  showOtpModal: (showIt) => dispatch(showOtpModal(showIt)),
  insertUser: (user) => dispatch(insertUserStart(user)),
  verifyAmazon: (user) => dispatch(verifyAmazonStart(user)),
  resetDestPath: () => dispatch(resetDestinationPath()),
  saveCreditCard: (data) => dispatch(updateCreditCardStart(data)),
  fetchCreditCardBasicInfo: (payload) => dispatch(fetchCCInfoStart(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSetup)
);
