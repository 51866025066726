import { createSelector } from 'reselect';

const selectGiftSet = state => state.giftSets;
const selectGiftSetError = state => state.error;

export const selectedGiftSetError = createSelector(
    [selectGiftSetError],
    g => g?.error
);

export const selectedGiftSet = createSelector(
    [selectGiftSet],
    giftset => Object.values(
        giftset.giftSets.arr.find(
            element => element.selected === true
        ).gifts)
);

export const selectAllGifts = createSelector(
    [selectGiftSet],
    giftsets => giftsets.giftSets.arr[0]?.gifts
);

export const selectAllNames = createSelector(
    [selectGiftSet],
    giftset => giftset.giftSets.arr.find(
        element => element.selected === true
    ).names
);

export const selectAllLandingImages = createSelector(
    [selectGiftSet],
    giftset =>
        giftset.giftSets.arr.find(
            element => element.selected === true
        ).landingImages
);

export const selectProcessingStatus = createSelector(
    [selectGiftSet],
    giftset => giftset.processing
);

export const selectCardCategory = createSelector(
    [selectGiftSet],
    giftset =>
        giftset.giftSets.arr.find(
            element => element.selected === true
        ).card_category ?? "holiday"
);

export const selectCardName = createSelector(
    [selectGiftSet],
    giftset =>
        giftset.giftSets.arr.find(
            element => element.selected === true
        ).card_name
);
