const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  INSERT_USER_START: 'INSERT_USER_START',
  INSERT_USER_SUCCESS: 'INSERT_USER_SUCCESS',
  INSERT_USER_FAILURE: 'INSERT_USER_FAILURE',
  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  SHOW_OTP_MODAL: 'SHOW_OTP_MODAL',
  SEND_OTP_START: 'SEND_OTP_START',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_FAILURE: 'SEND_OTP_FAILURE',
  VERIFY_AMAZON_START: 'VERIFY_AMAZON_START',
  VERIFY_AMAZON_SUCCESS: 'VERIFY_AMAZON_SUCCESS',
  VERIFY_AMAZON_FAILURE: 'VERIFY_AMAZON_FAILURE',
  PROCESSING_START: 'PROCESSING_START',
  PROCESSING_END: 'PROCESSING_END',
  RESET_CODE_START: 'RESET_CODE_START',
  RESET_CODE_SUCCESS: 'RESET_CODE_SUCCESS',
  RESET_CODE_FAILURE: 'RESET_CODE_FAILURE',
  SET_DESTINATION_PATH: 'SET_DESTINATION_PATH',
  RESET_DESTINATION_PATH: 'RESET_DESTINATION_PATH',
  GET_PENDING_RECIPIENTS_START: 'GET_PENDING_RECIPIENTS_START',
  GET_PENDING_RECIPIENTS_SUCCESS: 'GET_PENDING_RECIPIENTS_SUCCESS',
  GET_PENDING_RECIPIENTS_FAILURE: 'GET_PENDING_RECIPIENTS_FAILURE',
  GET_RECIPIENTS_BY_TEMPLATENAME_START: 'GET_RECIPIENTS_BY_TEMPLATENAME_START',
  GET_RECIPIENTS_BY_TEMPLATENAME_SUCCESS: 'GET_RECIPIENTS_BY_TEMPLATENAME_SUCCESS',
  GET_RECIPIENTS_BY_TEMPLATENAME_FAILED: 'GET_RECIPIENTS_BY_TEMPLATENAME_FAILED',
  EXECUTE_PURCHASE_START: 'EXECUTE_PURCHASE_START',
  EXECUTE_PURCHASE_SUCCESS: 'EXECUTE_PURCHASE_SUCCESS',
  EXECUTE_PURCHASE_FAILURE: 'EXECUTE_PURCHASE_FAILURE',
  VERIFY_AMAZON_ACCOUNT_AVAILABLE_START: 'VERIFY_AMAZON_ACCOUNT_AVAILABLE_START',
  VERIFY_AMAZON_ACCOUNT_AVAILABLE_SUCCESS: 'VERIFY_AMAZON_ACCOUNT_AVAILABLE_SUCCESS',
  VERIFY_AMAZON_ACCOUNT_AVAILABLE_FAILURE: 'VERIFY_AMAZON_ACCOUNT_AVAILABLE_FAILURE',
  GET_AMAZON_ACCOUNT_START: 'GET_AMAZON_ACCOUNT_START',
  GET_AMAZON_ACCOUNT_SUCCESS: 'GET_AMAZON_ACCOUNT_SUCCESS',
  GET_AMAZON_ACCOUNT_FAILURE: 'GET_AMAZON_ACCOUNT_FAILURE',
  SAVE_TEMPLATE_START: 'SAVE_TEMPLATE_START',
  SAVE_TEMPLATE_SUCCESS: 'SAVE_TEMPLATE_SUCCESS',
  SAVE_TEMPLATE_FAILURE: 'SAVE_TEMPLATE_FAILURE',
  GET_GIFT_TEMPLATE_ARRAY_START: 'GET_GIFT_TEMPLATE_ARRAY_START',
  GET_GIFT_TEMPLATE_ARRAY_SUCCESS: 'GET_GIFT_TEMPLATE_ARRAY_SUCCESS',
  GET_GIFT_TEMPLATE_ARRAY_FAILURE: 'GET_GIFT_TEMPLATE_ARRAY_FAILURE',
  FETCH_GIFT_HTML_TEMPLATE_START: 'FETCH_GIFT_HTML_TEMPLATE_START',
  FETCH_GIFT_HTML_TEMPLATE_SUCCESS: 'FETCH_GIFT_HTML_TEMPLATE_SUCCESS',
  FETCH_GIFT_HTML_TEMPLATE_FAILURE: 'FETCH_GIFT_HTML_TEMPLATE_FAILURE',
  SET_AMAZON_EMAIL: 'SET_AMAZON_EMAIL',
  SET_AMAZON_EMAIL_SUCCESS: 'SET_AMAZON_EMAIL_SUCCESS',
  SET_AMAZON_EMAIL_FAILURE: 'SET_AMAZON_EMAIL_FAILURE',
  SET_AMAZON_PKE: 'SET_AMAZON_PKE',
  SET_AMAZON_PKE_SUCCESS: 'SET_AMAZON_PKE_SUCCESS',
  SET_AMAZON_PKE_FAILURE: 'SET_AMAZON_PKE_FAILURE',
  UPDATE_CREDITCARD_START: 'UPDATE_CREDITCARD_START',
  UPDATE_CREDITCARD_FAILED: 'UPDATE_CREDITCARD_FAILED',
  UPDATE_CREDITCARD_SUCCESS: 'UPDATE_CREDITCARD_SUCCESS',
  UPDATE_TEMPLATE_DATA_START: 'UPDATE_TEMPLATE_DATA_START',
  UPDATE_TEMPLATE_DATA_SUCCESS: 'UPDATE_TEMPLATE_DATA_SUCCESS',
  UPDATE_TEMPLATE_DATA_FAILED: 'UPDATE_TEMPLATE_DATA_FAILED',
  FETCH_TEMPLATE_DATA_START: 'FETCH_TEMPLATE_DATA_START',
  FETCH_TEMPLATE_DATA_SUCCESS: 'FETCH_TEMPLATE_DATA_SUCCESS',
  FETCH_TEMPLATE_DATA_FAILED: 'FETCH_TEMPLATE_DATA_FAILED',
  FETCH_SELECTED_GIFT_URL_START: 'FETCH_SELECTED_GIFT_URL_START',
  FETCH_SELECTED_GIFT_URL_SUCCESS: 'FETCH_SELECTED_GIFT_URL_SUCCESS',
  FETCH_SELECTED_GIFT_URL_FAILED: 'FETCH_SELECTED_GIFT_URL_FAILED',
  SET_USER_AS_START: 'SET_USER_AS_START',
  SET_USER_AS_FAILURE: 'SET_USER_AS_FAILURE',
  SET_USER_AS_SUCCESS: 'SET_USER_AS_SUCCESS',
  FETCH_ALL_TEMPLATES_START: 'FETCH_ALL_TEMPLATES_START',
  FETCH_ALL_TEMPLATES_SUCCESS: 'FETCH_ALL_TEMPLATES_SUCCESS',
  FETCH_ALL_TEMPLATES_FAILURE: 'FETCH_ALL_TEMPLATES_FAILURE',
  UPDATE_ACTIVE_ECARD_START: 'UPDATE_ACTIVE_ECARD_START',
  UPDATE_ACTIVE_ECARD_SUCCESS: 'UPDATE_ACTIVE_ECARD_SUCCESS',
  UPDATE_ACTIVE_ECARD_FAILURE: 'UPDATE_ACTIVE_ECARD_FAILURE',
  DELETE_RECIPIENT_BY_CREATED_ID_START: 'DELETE_RECIPIENT_BY_CREATED_ID_START',
  DELETE_RECIPIENT_BY_CREATED_ID_SUCCESS: 'DELETE_RECIPIENT_BY_CREATED_ID_SUCCESS',
  DELETE_RECIPIENT_BY_CREATED_ID_FAILURE: 'DELETE_RECIPIENT_BY_CREATED_ID_FAILURE',
  UPDATE_APPROVED_RECIPIENT_START: 'UPDATE_APPROVED_RECIPIENT_START',
  UPDATE_APPROVED_RECIPIENT_SUCCESS: 'UPDATE_APPROVED_RECIPIENT_SUCCESS',
  UPDATE_APPROVED_RECIPIENT_FAILURE: 'UPDATE_APPROVED_RECIPIENT_FAILURE',
  FUSIONAUTH_SIGN_IN_START: 'FUSIONAUTH_SIGN_IN_START',
  AUTH_ATTEMPT: 'AUTH_ATTEMPT',
  RESET_AUTH_ATTEMPT: 'RESET_AUTH_ATTEMPT',
  SET_NEW_ORG_START: 'SET_NEW_ORG_START',
  SET_NEW_ORG_SUCCESS: 'SET_NEW_ORG_SUCCESS',
  SET_NEW_ORG_FAILURE: 'SET_NEW_ORG_FAILURE',
  FETCH_CC_INFO_START: 'FETCH_CC_INFO_START',
  FETCH_CC_INFO_FAILURE: 'FETCH_CC_INFO_FAILURE',
  FETCH_CC_INFO_SUCCESS: 'FETCH_CC_INFO_SUCCESS',
  FETCH_ORDERS_START: 'FETCH_ORDERS_START',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILURE: 'FETCH_ORDERS_FAILURE',
};

export default UserActionTypes;
