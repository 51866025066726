import { ReceiveActionTypes } from './receive.types';

export const setAuthorizeUser = authorized => ({
    type: ReceiveActionTypes.SET_AUTHORIZED,
    payload: authorized
});

export const setPassword = password => ({
    type: ReceiveActionTypes.SET_PASSWORD,
    payload: password
});

export const setItems = items => ({
    type: ReceiveActionTypes.SET_ITEMS,
    payload: items
});

export const setGiver = giverEmail => ({
    type: ReceiveActionTypes.SET_GIVER,
    payload: giverEmail
});

export const confirmGiftTreeStart = _ => ({
  type: ReceiveActionTypes.CONFIRM_GIFT_TREE_START,
});

export const confirmGiftTreeSuccess = _ => ({
  type: ReceiveActionTypes.CONFIRM_GIFT_TREE_SUCCESS,
});

export const confirmGiftTreeFailed = error => ({
  type: ReceiveActionTypes.CONFIRM_GIFT_TREE_FAILED,
  payload: error
})

export const confirmGiftSelectionStart = recipientData => ({
    type: ReceiveActionTypes.CONFIRM_GIFT_SELECTION_START,
    payload: recipientData
});

export const confirmGiftSelectionSuccess = () => ({
    type: ReceiveActionTypes.CONFIRM_GIFT_SELECTION_SUCCESS,
});

export const confirmGiftSelectionFailure = error => ({
    type: ReceiveActionTypes.CONFIRM_GIFT_SELECTION_FAILURE,
    payload: error
});

export const emailConfirmProgressStart = payload => ({
    type: ReceiveActionTypes.EMAIL_CONFIRM_PROGRESS_START,
    payload
});

export const emailConfirmProgressSuccess = payload => ({
    type: ReceiveActionTypes.EMAIL_CONFIRM_PROGRESS_SUCCESS,
    payload,
});

export const emailConfirmProgressFailed = payload => ({
    type: ReceiveActionTypes.EMAIL_CONFIRM_PROGRESS_FAILED,
    payload
})

export const resetGiftClaimedToFalse = _ => ({
    type: ReceiveActionTypes.RESET_GIFT_CLAIMED_TO_FALSE,
})

// export const setSelectedItem = itemIndex => ({
//     type: ReceiveActionTypes.TOGGLE_SELECTED,
//     payload: itemIndex
// })

