import React from 'react';
import { createStructuredSelector } from 'reselect';
import { selectItems, selectGiver } from '../../redux/receive/receive.selectors';
import { setItems, setGiver } from '../../redux/receive/receive.actions';
import { connect } from 'react-redux';
import CustomButton from '../../components/custom-button/custom-button.component';
import GiftList from '../../components/gift-list/gift-list.component';
class ReceiverSelection extends React.Component {

    constructor(props) {
        super(props);
        this.seeItems = this.seeItems.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        fetch(`${process.env.REACT_APP_API}/post/gifting`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "created_id": id
            })
        })
            .then(response => (() => {
                if (response.status === 200) {
                    return response.json();
                }
            })())
            .then(result => {
                if (result) {
                    const { setItems, setGiver } = this.props;
                    const itemsArr = result.msg.item_urls.map((val, index) => Object({ "id": index, "link": val, "selected": false }));
                    setItems(itemsArr);
                    // console.log("result:",result)
                    setGiver(result.msg.giver_email);
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    handleSubmit = event => {
        event.preventDefault();
    }

    clickHandler = () => {
        const { items, giver } = this.props;
        const selected = items.find(element => element.selected === true);
        if (selected === undefined) alert("none selected!");
        else {
            fetch(`${process.env.REACT_APP_API}/post/selecteditem`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "created_id": this.props.match.params.id,
                    "selectedUrl": selected.link,
                    "giver": giver
                })
            })
                .then(response => (() => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })())
                .then(result => {
                    if (result) {
                        alert("successful");
                        const { history } = this.props;
                        history.push("/");
                    }
                })
                .catch(error => {
                    alert("Error cannot transmit your request")
                    console.error(error);
                });
        }

    }

    seeItems() {
        return (
            <div className="receiver-selection">
                <h1>Select one gift and confirm: </h1>
                <div className='buttons'>
                    <CustomButton type="button" onClick={this.clickHandler}>Confirm</CustomButton>
                </div>
                <br />
                <GiftList />
            </div>
        );
    }

    render() {
        return this.seeItems();
    }
}

const mapStateToProps = createStructuredSelector({
    items: selectItems,
    giver: selectGiver,
});

const mapDispatchToProps = dispatch => ({
    setItems: items => dispatch(setItems(items)),
    setGiver: giver => dispatch(setGiver(giver))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiverSelection);