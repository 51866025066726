import React from 'react';
import SignIn from '../../components/sign-in/sign-in.component';
import { SignInSignUpContainer } from './sign-in-and-sign-up.styles';
const SignInAndSignUpPage = () => {
    return (
    <SignInSignUpContainer>
        <SignIn />
    </SignInSignUpContainer>
)};

export default SignInAndSignUpPage;