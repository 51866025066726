import CardsTypes from "./cards.types";

export const updateCards = cards => ({
  type: CardsTypes.UPDATE_CARDS,
  payload: cards
});

export const fetchAllEcardsTemplatesStart = payload => ({
  type: CardsTypes.FETCH_ALL_ECARDS_TEMPLATES_START,
  payload
});

export const fetchAllEcardsTemplatesSuccess = payload => ({
  type: CardsTypes.FETCH_ALL_ECARDS_TEMPLATES_SUCCESS,
  payload
});

export const fetchAllEcardsTemplatesFailure = error => ({
  type: CardsTypes.FETCH_ALL_ECARDS_TEMPLATES_FAILURE,
  payload: error
});

export const setSelectedCard = payload => ({
  type: CardsTypes.SET_SELECTED_CARD,
  payload,
})

export const setSender = sender => ({
  type: CardsTypes.SET_SENDER,
  payload: sender,
});

export const setMessage = message => ({
  type: CardsTypes.SET_MESSAGE,
  payload: message,
})