import styled from 'styled-components';

export const TextSplash = styled.div`
color: #5938BA;
`;

export const SplashHeader = styled.h1`
font-family: Helvetica;
font-size: 87px;
font-style: normal;
font-weight: 700;
@media screen and (max-width: 800px) {
  font-size: 28px;
}
`;

export const SplashSubHeader = styled.h5`
font-family: 'Darker Grotesque', 'Helvetica';
font-size: 45px;
font-style: normal;
font-weight: 400;
color: #ffffff;
@media screen and (max-width: 800px) {
  font-size: 34px;
}
`;


export const GiveButton = styled.div`
font-size: 55px;
font-style: normal;
font-weight: 800;
font-family: 'Darker Grotesque', 'Helvetica';
width: 172px;
padding: 18px;
line-height: 0.9;
padding-top: 3px;
background-color: #b6d4d4;
border-radius: 40px;
height: calc(100.00% - 8px);
display: inline-block;
vertical-align: middle;
`;


export const CardContainer = styled.div`
width: 24rem;
`;
