import { Link } from 'react-router-dom';
import { SplashHeader, SplashSubHeader, GiveButton, CardContainer, TextSplash } from './splash.styles';
import first from '../../assets/website-zen-giving_10.png';

const SplashComponent = () => (<>

  <div className="showcase">
    <div className='container'>
      <div className="row row-1 purple-text text-darken-1">
        <div className="col m6">
          <TextSplash>
            <h2><b>The easy way to give gifts</b></h2>
            <h4>ZenGiving lets you create your own e-card with a customizable list of gifts. Send it to friends, family and clients and always give the perfect gift!</h4>
            <br /><br />
            <div className="buttons">
              <Link to="/signin"><GiveButton className="orange waves-effect waves-light white-text">Give!</GiveButton></Link>
            </div>
          </TextSplash>
        </div>
        <div className="col m6">
          {/* <CardContainer className="card"> */}
          {/* <div className="card-image"> */}
          <img src={first} alt="" />
          {/* </div> */}
          {/* </CardContainer> */}
        </div>
      </div>
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#f3f4f5" fillOpacity="1" d="M0,160L48,160C96,160,192,160,288,186.7C384,213,480,267,576,261.3C672,256,768,192,864,170.7C960,149,1056,171,1152,186.7C1248,203,1344,213,1392,218.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
    </svg>
  </div>

</>);

export default SplashComponent;
