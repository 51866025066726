import axios from 'axios';
import { API_BASE_URL, localError, localInfo } from '../../utils/constants';
import { store } from '../../redux/store';
import {
  confirmGiftSelectionFailure,
  confirmGiftSelectionSuccess, confirmGiftTreeFailed,
  confirmGiftTreeSuccess,
  emailConfirmProgressFailed,
  emailConfirmProgressSuccess
} from './receive.actions';
import { toast } from 'react-toastify';

export const confirmGiftSelection = async (payload) => {
    const { giverEmail, recipient, selectedUrl, templateName, itemName } = payload;
    const thePayload = {
        giverEmail,
        recipient,
        selectedUrl,
        templateName,
        itemName
    };
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
        },
    };
    try {
        await axios.post(`${API_BASE_URL}/post/giftconfirm`, thePayload, options)
            .then(res => {
                store.dispatch(confirmGiftSelectionSuccess());
                toast.info("Thank you. Your selected request is pending approval. ", {
                    position: "bottom-left",
                    autoClose: 8000,
                    hideProgressBar: false
                });
            }, error => {
                store.dispatch(confirmGiftSelectionFailure(error));
                toast.error("Something went wrong, please try again later.", {
                    position: "bottom-left",
                    autoClose: 8000,
                    hideProgressBar: false
                });
            });

    } catch (error) {
        localError("Error while confirming gift selection");
    }
};

export const emailConfirmProgress = async (payload) => {
    localInfo("Payload: ", payload);
    localInfo("REACT_APP_ALLOW_ORIGINS: ", process.env.REACT_APP_ALLOW_ORIGINS);
    const options = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    };


    try {
        await axios.post(`${API_BASE_URL}/post/was-consumed`, payload, options)
            .then(res => {
                localInfo("RESPONSE:", res.data.message);
                store.dispatch(emailConfirmProgressSuccess(res.data.message));
            }, error => {
                localError(`ERROR: ${error}`);
                store.dispatch(emailConfirmProgressFailed(error.message));
            });
    } catch (error) {
        localError(`ERROR: ${error.message}`);
    }
};


export const giftTreeProgress = async (payload) => {
  const options = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
  };

  try {
    await axios.post(`${API_BASE_URL}/post/gift-tree`, payload, options)
      .then(res => {
        localInfo("RESPONSE:", res);
        store.dispatch(confirmGiftTreeSuccess(res.data.message));
      }, error => {
        localError(`ERROR: ${error}`);
        store.dispatch(confirmGiftTreeFailed(error.message));
      });
  }catch (e) {
    localError(`ERROR: ${e.message}`);
  }
};
