import styled, { css } from 'styled-components';

export const MainContainer = styled.div`

    color: #5938BA;
    padding: 1rem;
    top: -1.9rem;
    left: -2rem;
    text-align: center;
    margin: 0;
    width: 100%;

`;

export const NextButton = styled.button`
  margin: 0 auto;
  border-radius: 50px;
  background-color: #fc9900;
  text-align: center;
  margin: 0;
  display: block;
  margin: 10px auto;
`;

export const HeaderContainer = styled.h4`
  color: #5938BA;
  padding: 1rem;
  top: -1.9rem;
  left: -2rem;
  text-align: center;
  margin: 0px;


  @media screen and (max-width: 800px) {
    width: 80%;
    margin: 0 auto;

  }
`;


export const TopicContainer = styled.div`
  color: #5938BA;
  padding: 1rem;
  top: -1.9rem;
  left: -2rem;
  text-align: center;
  margin: 0px;


  @media screen and (max-width: 800px) {
    width: 80%;
    margin-left: -2.5rem;
  }
`;


export const SelectContainer = styled.select`
  display: inline;
  height: fit-content;
  width: fit-content;
  border: 0.3rem solid #000;
  border-radius: 12px;
`;

export const ParentSelectContainer = styled.div`
  margin: 0;
  margin-left: 10rem;
`;

const selectedGridItemStyle = css`
  background-color: rgba(255, 255, 255, 0.8);
`;

const getSelectedCard = props => {
  if (props.selected) {
    return selectedGridItemStyle;
  }
};

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
`;

export const GridItem = styled.div`
  font-size: 30px;
  text-align: center;
`;


export const PersonalizedMessageContainer = styled.div`
  border: 1px solid #000;
  padding: 0 0;
  border-radius: 12px;
  margin: 20px 30px;
  overflow: hidden;
  min-height: 10rem;
`;

export const SenderContainer = styled.div`
  border: 1px solid #000;
  padding: 0px 0px;
  border-radius: 12px;
  margin: 0px 30px;
  overflow: hidden;
  width: auto;
  height: 5rem;
  > input#sender::placeholder {
    color: red;
  }
`;


export const HorizontalLineBottom = styled.div`
  margin-bottom: 2rem;
  border-bottom: 1px solid #a8aaaa;
`;

export const GridMessageContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  padding: 9px;
`;
