import { combineReducers } from 'redux';
import apiReducer from './api/api.reducer';
import receiveReducer from './receive/receive.reducer';
import userReducer from './user/user.reducer';
import giftSetsReducer from './gift-sets/gift-sets.reducer';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import cardsReducer from './cards/cards.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist:['receive', 'user', 'giftSets']
};

const rootReducer = combineReducers({
    user: userReducer,
    receive: receiveReducer,
    api: apiReducer,
    giftSets: giftSetsReducer,
    cards: cardsReducer
});

export default persistReducer(persistConfig, rootReducer);