import React, { useEffect } from 'react';
import "./overlay.css";

const Overlay = () => {
  useEffect(()=>{
    document.body.style.overflow = "hidden";
    return ()=>{
      document.body.style.overflow = "auto";
    }
  },[]);
  return (
    <div className='overlay_container'>
      <div className='loader'></div>
    </div>
  );
};

export default Overlay;