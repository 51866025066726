import { useMemo } from "react";
import { localInfo } from "../utils/constants";

const useAuthed = (currentUser) => {
  const isAuthed = email => email !== null && email !== undefined && email !== "";
  const isLoggedIn = useMemo(() => isAuthed, []);

  if (currentUser) {
    localInfo("currentUser: ", currentUser);
    const { email, displayName, idToken } = currentUser;




    return [email, idToken, isLoggedIn, displayName];
  }
  else return [null, null, null, null];
};

export default useAuthed;
