import styled from 'styled-components';

export const AddCredCardText = styled.span`
  color: #5938BA;
`;

export const HeaderContainer = styled.h4`
  color: #000000;
  padding: 1rem;
  top: -1.9rem;
  left: -2rem;
  text-align: center;
  margin: 0px;


  @media screen and (max-width: 800px) {
    width: 80%;
    margin: 0 auto;

  }
`;

export const GiftsContainer = styled.div`
  color: #5938BA;
  padding: 1rem;
  top: -1.9rem;
  left: -2rem;
  text-align: center;
  margin: 0px;
  width: 100%;
`;

export const ConfirmButtonContainer = styled.div`
  margin-left: 30%;
`;


export const TopicContainer = styled.div`
  color: #5938BA;
  padding: 1rem;
  top: -1.9rem;
  left: -2rem;
  text-align: center;
  margin: 0px;


  @media screen and (max-width: 800px) {
    width: 80%;
    margin-left: -2.5rem;
  }
`;


export const MessageContainer = styled.div`
  background-color: white;
  font-weight: bolder;
  font-size: 1.5rem;
  color: #566ec9;
`;

export const DataContainer = styled.div`
  background-color: white;
  border: 1px solid #9e9e9e;
  input[type=text]:not(.browser-default) {
  border-bottom:0px solid #9e9e9e !important;
  }

`;

