import { Collapsible, CollapsibleItem, Icon } from "react-materialize";
import Header from "../../components/header/header.component";
import FooterFinal from "../footer/footer.component";
import './faq.styles.scss';

const Faq = _ => {

  return <>
    <Header titlePage={"Frequently asked questions"} />
    <div>
      <Collapsible accordion className="main-collapsible">
        <CollapsibleItem
          expanded={false}
          header="Better safe than sorry. That's my motto."
          icon={<Icon>arrow_drop_down</Icon>}
          node="div"
          className="main-collapsible-item"
        >
          Better safe than sorry. That's my motto.
        </CollapsibleItem>
        <CollapsibleItem
          expanded={false}
          header="Yeah, you do seem to have a little 'shit creek' action going."
          icon={<Icon>arrow_drop_down</Icon>}
          node="div"
        >
          Yeah, you do seem to have a little 'shit creek' action going.
        </CollapsibleItem>
        <CollapsibleItem
          expanded={false}
          header="You know, FYI, you can buy a paddle. Did you not plan for this contingency?"
          icon={<Icon>arrow_drop_down</Icon>}
          node="div"
        >
          You know, FYI, you can buy a paddle. Did you not plan for this contingency?
        </CollapsibleItem>
      </Collapsible>
    </div>
    <FooterFinal />
  </>;
};

export default Faq;