
import { Footer } from "react-materialize";
import "./footer.styles.scss";

import SocialMediaFooter from "../../components/footer/social-media-footer/social-media-footer.component";
import LinksFooter from "../../components/footer/links-footer/links-footer.component";


const FooterFinal = () => {
  return <><Footer
    className="footer-bg-color-9478afc0"
    links={<SocialMediaFooter />}
  >
    <LinksFooter />
  </Footer>
  </>
};

export default FooterFinal;