import styled from 'styled-components';

export const LoadingTag = styled.div`
  margin: "0 auto";
  text-align: center;
  float: "none";
`;


export const LoadingGif = styled.img`
  text-align: "center";
  display: "block";
  width: "280px";
  height: "280px";
  left: "0px";
  margin-top: -107px;
  opacity: 0.2;
`;
