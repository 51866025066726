import styled from 'styled-components';

export const PendingPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media screen and (max-width: 800px) {
    align-items: center;
  }
`;


export const PreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

export const ItemContainer = styled.div`
  width: 22vw;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  &:hover {
    .image {
      opacity: 0.8;
    }
    button {
      opacity: 0.85;
      display: flex;
    }
  }
  @media screen and (max-width: 800px) {
    width: 40vw;
    &:hover {
      .image {
        opacity: unset;
      }
      button {
        opacity: unset;
      }
    }
  }
`;

// export const BackgroundImage = styled.div`
//   width: 100%;
//   height: 95%;
//   background-size: cover;
//   background-position: center;
//   margin-bottom: 5px;
//   background-image: ${({ imageUrl }) => `url(${imageUrl})`};
// `;


export const BackgroundImage = styled.div`
  width: 100%;
  height: 95%;
  background-size: cover;
  background-position: center;
  margin-bottom: 5px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
`;

export const TextPlacing = styled.div`
  width: 80%;
  opacity: 0.7;
  position: absolute;
  top: 255px;
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
    opacity: 0.9;
    min-width: unset;
    padding: 0 10px;
  }
`;


export const AboutSectionContainer = styled.div`
background-color: transparent;
position: relative;
z-index: 5;
height:0vh;
`;

export const ParaDescriptor = styled.p`
  color: #724fc9;
  font-size: 1rem;
  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }

`;

export const CardPanelContainer = styled.div`
  height: 14rem;
`;