import styled from 'styled-components';

export const ConfirmButtonContainer = styled.div`
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
`;

export const MainContainer = styled.div`
    margin-top: 0rem;
    margin-bottom: 1rem;
    background-color: #5938BA;
    border-radius: 50px;


`;

export const NextButton = styled.button`
  margin: auto;
  display: block;
  border-radius: 20px;
  background-color: #f29500;
  text-align: center;
  margin-top: 20px;
  font-weight: bolder;
  width: 14rem;
`;

export const TopicContainer = styled.div`
  background-color: #5938BA;
  border-radius: 50px;
  color: #ffffff;
  padding: 1rem;
  font-weight: bolder;
  position: relative;
  text-align: center;


`;
