
import { Card, Col } from "react-materialize";
import styled from "styled-components";

export const CardContainer = styled.div`
  color: black;
`;

export const ColStyled = styled(Col)`
  color: black;
  width: 10rem;
`;


export const CardStyled = styled(Card)({
  ".horizontal": {
    width: "10rem"
  }
});
