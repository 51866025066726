import React from "react";
import "./header.styles.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { setUserAsStart, signOutStart } from "../../redux/user/user.actions";
import { Navbar, Icon } from "react-materialize";
import { withRouter } from "react-router-dom";
import SplashComponent from "../splash/splash.component";
import logo from "../../assets/logo2.png";
import { useState } from "react";

const Header = ({
  currentUser,
  signOutStart,
  history,
  titlePage,
  setUserAsEmail,
}) => {
  const [userAsEmail, setUserAsEmailState] = useState("");

  const setUserAsEmailChange = (ev) => {
    const { value, name } = ev.target;
    switch (name) {
      case "email":
        setUserAsEmailState((_) => value);
        break;
      default:
    }
  };

  return (
    <>
      <header className="main-header">
        <Navbar
          alignLinks="left"
          centerLogo
          brand={
            <img src={logo} className="App-logo" alt="logo" />
          }
          id="mobile-nav"
          className="transparent"
          menuIcon={<Icon style={{ color: "#000" }}>menu</Icon>}
          options={{
            draggable: true,
            edge: "left",
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 200,
            preventScrolling: true,
          }}
        >

          <Link to="/">
            <div className="option">Home</div>
          </Link>

          {currentUser ? (
            <>
              <Link className="option" to="/user/dashboard">
                Dashboard
              </Link>
            </>
          ) : (
            <></>
          )}
          <>
            <Link className="option" to="/">
              How It Works
            </Link>
          </>
          <>
            {/* <Link className="option" to="/faq">
              Faq
            </Link> */}
          </>
          <div className="faq-width">
            &nbsp;
          </div>

          {currentUser ? (
            <>
              <Link className="option" to="/user/to-give">
                Give
              </Link>
            </>
          ) : (
            <></>
          )}

          {currentUser ? (
            <>
              <Link className="option" to="/user">
                Settings
              </Link>
            </>
          ) : (
            <></>
          )}
          {currentUser ? (
            <Link
              to=""
              onClick={() => {
                history.push("/");
                signOutStart();
              }}
              className="option"
            >
              Log out
            </Link>
          ) : (
            <Link to="/signin" className="option" style={{ "marginLeft": "20rem" }}>
              <div className="option">Login</div>
            </Link>
          )}
          {/* <Link className="option" to="/contact">
            Contact
          </Link> */}

        </Navbar>
        {/* {currentUser && currentUser.email === "khairul@tunabear.com" && (
          <>
            <Button
              className="modal-trigger red admin-button"
              href="#another_user"
              node="button"
            >
              Assimilate as different user
            </Button>
            <Modal
              actions={[
                <Button
                  onClick={() => setUserAsEmail(userAsEmail)}
                  modal="close"
                  node="button"
                  waves="light"
                  className="blue lighten-2 white-text"
                >
                  Confirm
                </Button>,
                <span style={{ padding: "10px" }}>&nbsp;</span>,
                <Button
                  modal="close"
                  node="button"
                  waves="light"
                  className="white black-text"
                >
                  Cancel
                </Button>,
              ]}
              bottomSheet={false}
              fixedFooter={false}
              header="Assimilate as another User"
              id="another_user"
              open={false}
              options={{
                dismissible: true,
                endingTop: "10%",
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: "4%",
              }}
              className="black-text"
            >
              <TextInput
                id="userEmail"
                placeholder="User Email"
                onChange={setUserAsEmailChange}
                icon="email"
                name="email"
                type="email"
                required
                value={userAsEmail}
              />
            </Modal>
          </>
        )} */}

        {currentUser ? (
          <>
            {titlePage ? (
              <>
                <div className="showcase">
                  <div className="row">
                    <div className="col s12 m10 offset-m1 center purple-text">
                      <h1>{titlePage}</h1>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <SplashComponent />
              </>
            )}
          </>
        ) : (
          <>
            {titlePage ? (
              <>
                <div className="showcase">
                  <div className="row">
                    <div className="col s12 m10 offset-m1 center purple-text">
                      <h1>{titlePage}</h1>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <SplashComponent />
              </>
            )}
          </>
        )}
      </header>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
  setUserAsEmail: (userEmail) => dispatch(setUserAsStart(userEmail)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
