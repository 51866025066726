import React, { Suspense, useEffect } from 'react';
import { GlobalStyle } from './global.styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignInAndSignUpPage from './pages/sign-in-and-sign-up/sign-in-and-sign-up.component';
import { selectCurrentUser } from './redux/user/user.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ToGive from './pages/to-give/to-give.component';
import GiverSelection from './pages/giver-selection/giver-selection.component';
import { ToastContainer } from 'react-toastify';
import ReceiverSelection from './pages/receiver-selection/receiver-selection.component';
import GiverExecution from './pages/giver-execution/giver-execution.components.jsx';
import { checkUserSession } from './redux/user/user.actions';
import MainComponent from './pages/main/main.component';
import ContactComponent from './pages/contact/contact.component';
import VerifyAma from './pages/verify-ama/verify-ama.component';
import Thank from './pages/thank/thank.component';
import AmazonCredentialsPage from './pages/ama-creds/ama-creds.component';
import ChooseEcard from './pages/choose-ecard/choose-ecard.component';
import Spinner from './components/spinner/spinner.component';
import PendingDashboard from './pages/pending-dashboard/pending-dashboard.component';
import ECardPage from './pages/ecard/ecard.component';
import RecipientIdentity from './pages/recipient-identity/recipient-identity.component';
import Terms from './pages/terms/terms.component';
import ReviewGiftComponent from './pages/review-gift/review-gift.component';
import CreatedEcard from './pages/created-ecard/created-ecard.component';
import Faq from './pages/faq/faq.component';
import Dmca from './pages/dmca/dmca.component';
import PrivacyPolicy from './pages/privacy-policy/privacy-policy.component';
import UserSettings from './pages/user-settings/user-settings.component';
import useAuthed from './hooks/useAuthed';
import GiftTreePage from "./pages/gift-tree/gift-tree.component";
import TemplatePage from "./pages/template/template.component";


const App = (props) => {

  const { currentUser } = props;
  const [email, idToken, isLoggedIn] = useAuthed(currentUser);
  useEffect(() => {
    const { checkUserSession } = props;

    checkUserSession();
  }, []);

  return (
    <>
      <GlobalStyle />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Switch>
        <Suspense fallback={<Spinner />}>
          <Route exact path='/' component={MainComponent} />
          <Route path='/ecard/:email/:templatename' component={ECardPage} />
          <Route path='/contact' component={ContactComponent} />
          <Route path='/recipient-is' component={RecipientIdentity} />
            <Route path='/gift/tree' component={GiftTreePage} />
          <Route path='/gift/:templatename/:url/:email/:itemname' component={ToGive} />
          <Route exact path='/give/:id' component={ReceiverSelection} />
          <Route exact path='/ama-cred' component={AmazonCredentialsPage} />
          <Route exact path='/giver' component={GiverExecution} />
          <Route exact path='/user' render={() => currentUser ? (<UserSettings />) : (<Redirect to='/signin/' />)} />
          <Route path="/user/dashboard" component={PendingDashboard} />
          <Route path="/user/template/:templatename" component={TemplatePage} />
          <Route path='/user/pending/:item' component={PendingDashboard} />
          <Route path='/s/:item' component={UserSettings} />
          <Route exact path='/user/verify-ama' render={() => currentUser ? (<VerifyAma />) : (<Redirect to='/signin/' />)} />

          <Route exact path='/user/to-give' render={() => currentUser ? (<GiverSelection />) : (<Redirect to='/signin/' />)} />
          <Route path='/user/review-gift' render={() => currentUser ? (<ReviewGiftComponent />) : (<Redirect to='/signin/' />)} />
          <Route path='/user/created-ecard' render={() => currentUser ? (<CreatedEcard />) : (<Redirect to='/signin/' />)} />
          <Route path='/user/choose-ecard' render={() => currentUser ? <ChooseEcard /> : <Redirect to='/signin/' />} />
          <Route exact path='/signin' render={() => currentUser ? (<Redirect to='/user/to-give' />) : (<SignInAndSignUpPage />)} />
          <Route exact path='/thank-you' component={Thank} />
          <Route exact path='/terms-condition' component={Terms} />
          <Route exact path='/faq' component={Faq} />
          <Route exact path='/dmca' component={Dmca} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        </Suspense>
      </Switch>
      {/* <FooterFinal /> */}
    </>
  );

}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  checkUserSession: () => dispatch(checkUserSession())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
