import React from "react";
import { connect } from "react-redux";
import { confirmGiftSelectionStart, resetGiftClaimedToFalse } from "../../redux/receive/receive.actions";
import { emailConfirmProgressStart } from '../../redux/receive/receive.actions'
import Header from "../../components/header/header.component";
import { Button } from "react-materialize";
import { TextInput, Select, Icon } from "react-materialize";
import FooterFinal from "../footer/footer.component";
import { localInfo } from "../../utils/constants";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { createStructuredSelector } from "reselect";
import { selectEmailConfirmProgress, selectVerifyEmailDone, selectWasGiftClaimed } from "../../redux/receive/receive.selectors";
import LoadingComponent from "../../components/loading/loading.component";
import {doNothingOnReturnKey} from "../../utils/keyCapture";
class ToGive extends React.Component {
  constructor() {
    super();

    this.state = {
      verifyEmailDone: false,
      proceedSelected: false,
      recipient: {
        recipientemail: "",
        recipientFullName: "",
        recipientAddressLine1: "",
        recipientAddressLine2: "",
        recipientCity: "",
        recipientState: "",
        recipientZip: "",
        recipientPhone: "",
        recipientCountry: "US",
        selectedItem: "",
      },
      selectedUrl: null,
      giverEmail: null,
      itemName: "",
      templateName: "",
    };
  }

  componentDidMount() {
    const { url } = this.props.match.params;
    const { email } = this.props.match.params;
    const { templatename } = this.props.match.params;
    const { itemname } = this.props.match.params;
    const selectedUrl = decodeURIComponent(window.atob(url));
    const giverEmail = decodeURIComponent(window.atob(email));
    const templateName = decodeURIComponent(window.atob(templatename));
    const itemName = decodeURIComponent(window.atob(itemname));
    this.props.resetGiftClaimedToFalse();
    this.setState(_ => ({
      giverEmail,
      selectedUrl,
      templateName,
      itemName,
    }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { confirmGiftSelection, history } = this.props;
    confirmGiftSelection(this.state);
    localInfo("Confirmed: ", this.state);
    history.push("/");
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    const formattedInputValue = formatPhoneNumber(value);

    this.setState((prevState) => ({
      ...prevState,
      recipient: {
        ...prevState.recipient,
        [name]: name === "recipientPhone" ? formattedInputValue : value,
      },
    }));
  };

  handleVerifyEmail = _ => {
    const { confirmRecipientEmail } = this.props;
    const { giverEmail, templateName } = this.state;
    confirmRecipientEmail({
      recipientEmail: this.state.recipient.recipientemail,
      templateName,
      giverEmail,
    });
  };

  render() {
    return (
      <>
        <Header titlePage={"Congratulations"} />
        <section className="section section-recipient">
          <div className="container">
            <div className="row">
              <div className="col s12 m8 offset-m2 l6 offset-l3">
                <div className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-5">
                  {!this.props.wasClaimed && <React.Fragment>
                    <h4>You selected: </h4>
                    <h5>{this.state.itemName}</h5>
                    <span>
                      Enter your information, address and finally submit.
                    </span>
                  </React.Fragment>}
                  <form className="recipient-form" onSubmit={this.handleSubmit}>
                    {!this.props.emailConfirmProgress && !this.props.verifyEmailDone && <div className="input-field">
                      <TextInput
                        icon="email"
                        name="recipientemail"
                        type="email"
                        onChange={this.handleChange}
                        onKeyPress={doNothingOnReturnKey}
                        value={this.state.recipient.recipientemail}
                        placeholder="Email"
                        label="Email"
                        required
                      />
                    </div>}

                    {!this.props.emailConfirmProgress && this.props.verifyEmailDone && this.props.wasClaimed && <h5>Please note that you cannot choose a gift more than once. It seems like you have previously already made a gift selection. And it's on its way. </h5>}

                    {!this.props.emailConfirmProgress && !this.props.verifyEmailDone && <div className="container">
                      <div className="row">
                        <div className="col s12 m12">
                          <Button
                            node="button"
                            type="button"
                            className="btn btn-large orange btn-extended__60a3d156 waves-effect waves-light"
                            waves="light"
                            onClick={this.handleVerifyEmail}
                          >
                            <i className="material-icons left">done</i>Proceed
                          </Button>
                        </div>
                      </div>
                    </div>}

                    {this.props.emailConfirmProgress && <div className="container">
                      <div className="row">
                        <div className="col s12 m12">
                          <LoadingComponent circleGif={true} message={"Please wait while we verify your identity.."} />
                        </div>
                      </div>
                    </div>}

                    {!this.props.wasClaimed && this.props.verifyEmailDone && <React.Fragment>
                      <div className="input-field">
                        <TextInput
                          icon="account_circle"
                          name="recipientFullName"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.recipient.recipientFullName}
                          placeholder="Recipient Full Name"
                          label="Recipient Full Name"
                          required
                        />
                      </div>
                      <div className="input-field">
                        <TextInput
                          icon="art_track"
                          name="recipientAddressLine1"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.recipient.recipientAddressLine1}
                          placeholder="Recipient Address Line 1"
                          label="Recipient Address Line 1"
                          required
                        />
                      </div>
                      <div className="input-field">
                        <TextInput
                          icon="art_track"
                          name="recipientAddressLine2"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.recipient.recipientAddressLine2}
                          placeholder="Recipient Address Line 2"
                          label="Recipient Address Line 2"
                        />
                      </div>
                      <div className="input-field">
                        <TextInput
                          icon="location_city"
                          name="recipientCity"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.recipient.recipientCity}
                          placeholder="Recipient City"
                          label="Recipient City"
                          required
                        />
                      </div>
                      <div className="input-field">
                        <Select
                          icon={<Icon>nature</Icon>}
                          id="recipientState"
                          name="recipientState"
                          onChange={this.handleChange}
                          label="Recipient State/Province/Region"
                          multiple={false}
                          options={{
                            classes: "",
                            dropdownOptions: {
                              alignment: "left",
                              autoTrigger: true,
                              closeOnClick: true,
                              constrainWidth: true,
                              coverTrigger: true,
                              hover: false,
                              inDuration: 150,
                              onCloseEnd: null,
                              onCloseStart: null,
                              onOpenEnd: null,
                              onOpenStart: null,
                              outDuration: 250,
                            },
                          }}
                          value={this.state.recipient.recipientState}
                        >
                          <option disabled value="">
                            Choose state
                          </option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AS">American Samoa</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District of Columbia</option>
                          <option value="FM">
                            Federated States of Micronesia
                          </option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="GU">Guam</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PW">Palau</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VI">Virgin Islands</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                          <option value="AA">Armed Forces - AA</option>
                          <option value="AE">Armed Forces - AE</option>
                          <option value="AP">Armed Forces - AP</option>
                        </Select>{" "}
                      </div>
                      <div className="input-field">
                        <TextInput
                          icon="my_location"
                          name="recipientZip"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.recipient.recipientZip}
                          placeholder="Recipient Zip"
                          label="Recipient Zip"
                          required
                        />
                      </div>
                      <div className="input-field">
                        <Select
                          disabled
                          icon={<Icon>edit_location</Icon>}
                          label="Only shipping to US addresses."
                          id="recipientCountry"
                          name="recipientCountry"
                          onChange={this.handleChange}
                          multiple={false}
                          options={{
                            classes: "",
                            dropdownOptions: {
                              alignment: "left",
                              autoTrigger: true,
                              closeOnClick: true,
                              constrainWidth: true,
                              coverTrigger: true,
                              hover: false,
                              inDuration: 150,
                              onCloseEnd: null,
                              onCloseStart: null,
                              onOpenEnd: null,
                              onOpenStart: null,
                              outDuration: 250,
                            },
                          }}
                          value={this.state.recipient.recipientCountry}
                          required
                        >
                          <option value="AF">Afghanistan</option>
                          <option value="AX">Aland Islands</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                          <option value="AG">Antigua and Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas, The</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">Bolivia</option>
                          <option value="BQ">
                            Bonaire, Saint Eustatius and Saba
                          </option>
                          <option value="BA">Bosnia and Herzegovina</option>
                          <option value="BW">Botswana</option>
                          <option value="BV">Bouvet Island</option>
                          <option value="BR">Brazil</option>
                          <option value="IO">
                            British Indian Ocean Territory
                          </option>
                          <option value="BN">Brunei Darussalam</option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="IC">Canary Islands</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">Central African Republic</option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <option value="CX">Christmas Island</option>
                          <option value="CC">Cocos (Keeling) Islands</option>
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CG">Congo</option>
                          <option value="CD">
                            Congo, The Democratic Republic of the
                          </option>
                          <option value="CK">Cook Islands</option>
                          <option value="CR">Costa Rica</option>
                          <option value="CI">Cote D'ivoire</option>
                          <option value="HR">Croatia</option>
                          <option value="CW">Curaçao</option>
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">Dominican Republic</option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">Equatorial Guinea</option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <option value="FK">Falkland Islands (Malvinas)</option>
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FR">France</option>
                          <option value="GF">French Guiana</option>
                          <option value="PF">French Polynesia</option>
                          <option value="TF">French Southern Territories</option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia, The</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernsey</option>
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-Bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <option value="HM">
                            Heard Island and the McDonald Islands
                          </option>
                          <option value="VA">Holy See</option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <option value="IM">Isle of Man</option>
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option value="XK">Kosovo</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">
                            Lao People's Democratic Republic
                          </option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option value="MK">
                            Macedonia, The Former Yugoslav Republic of
                          </option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MQ">Martinique</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">
                            Micronesia, Federated States of
                          </option>
                          <option value="MD">Moldova, Republic of</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="AN">Netherlands Antilles</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NZ">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <option value="NU">Niue</option>
                          <option value="NF">Norfolk Island</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">Palestinian Territories</option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papua New Guinea</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="KR">Republic of Korea</option>
                          <option value="RE">Reunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">Russian Federation</option>
                          <option value="RW">Rwanda</option>
                          <option value="BL">Saint Barthelemy</option>
                          <option value="SH">
                            Saint Helena, Ascension and Tristan da Cunha
                          </option>
                          <option value="KN">Saint Kitts and Nevis</option>
                          <option value="LC">Saint Lucia</option>
                          <option value="MF">Saint Martin</option>
                          <option value="PM">Saint Pierre and Miquelon</option>
                          <option value="VC">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <option value="ST">Sao Tome and Principe</option>
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <option value="SX">Sint Maarten</option>
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <option value="SB">Solomon Islands</option>
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <option value="GS">
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard and Jan Mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="TW">Taiwan</option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">Tanzania, United Republic of</option>
                          <option value="TH">Thailand</option>
                          <option value="TL">Timor-leste</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinidad and Tobago</option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <option value="TC">Turks and Caicos Islands</option>
                          <option value="TV">Tuvalu</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">United Arab Emirates</option>
                          <option value="GB">United Kingdom</option>
                          <option value="US">United States</option>
                          <option value="UM">
                            United States Minor Outlying Islands
                          </option>
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">Venezuela</option>
                          <option value="VN">Vietnam</option>
                          <option value="VG">Virgin Islands, British</option>
                          <option value="VI">Virgin Islands, U.S.</option>
                          <option value="WF">Wallis and Futuna</option>
                          <option value="EH">Western Sahara</option>
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                        </Select>
                      </div>
                      <div className="input-field">
                        <TextInput
                          icon="phone"
                          name="recipientPhone"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.recipient.recipientPhone}
                          placeholder="206111111"
                          label="Recipient Phone"
                          required
                        />
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col s12 m12">
                            <Button
                              node="button"
                              type="submit"
                              className="btn btn-large orange btn-extended__60a3d156 waves-effect waves-light"
                              waves="light"
                              onKeyPress={doNothingOnReturnKey}
                            >
                              <i className="material-icons left">done</i>Confirm
                            </Button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterFinal />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  wasClaimed: selectWasGiftClaimed,
  verifyEmailDone: selectVerifyEmailDone,
  emailConfirmProgress: selectEmailConfirmProgress,
})

const mapDispatchToProps = (dispatch) => ({
  confirmGiftSelection: (data) => dispatch(confirmGiftSelectionStart(data)),
  confirmRecipientEmail: email => dispatch(emailConfirmProgressStart(email)),
  resetGiftClaimedToFalse: _ => dispatch(resetGiftClaimedToFalse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToGive);
