import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
body {
    font-family: 'Darker Grotesque', 'Helvetica';
    background-color: rgb(251,251,251);
    color: #ffffff;
}

`;
