import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import GiftSetsActionTypes from './gift-sets.types';
import {
    createCountGiftSets,
    createGetGiftsImageTextStart,
    createGiftsNamesStart,
    createInsertGiftSetByOrder,
    createLoadGiftSetStart
} from './gift-sets.hooks';
import { countGiftSetsFailure, getGiftsImagesTextFailure, insertGiftSetByOrderFailure, loadGiftSetsFailure, setGiftsNamesFailed } from './gift-sets.actions';

export function* countGiftSets(action) { 
    try {
        yield call(createCountGiftSets, action.payload);
    } catch (error) {
        yield put(countGiftSetsFailure(error));
    }
}

export function* onCountGiftSets() {
    yield takeLatest(GiftSetsActionTypes.COUNT_GIFT_SETS, countGiftSets);
}

export function* insertGiftSetByOrderNum(action) {
    try {        
        yield call(createInsertGiftSetByOrder, action.payload)
    } catch (error) {
        yield put(insertGiftSetByOrderFailure(error));
    }
}

export function* onInsertGiftSet() {
    yield takeLatest(GiftSetsActionTypes.INSERT_GIFT_SET_START, insertGiftSetByOrderNum);
}

export function* loadGiftSetStart(action) {
    try {
        yield call(createLoadGiftSetStart, action.payload);
    } catch (error) {
        yield put(loadGiftSetsFailure(error));
    }
}

export function* onLoadGiftSetStart() {
    yield takeLatest(GiftSetsActionTypes.LOAD_GIFT_SETS_START, loadGiftSetStart);
}

export function* getGiftsImageTextStart(action) {
    try {
        yield call(createGetGiftsImageTextStart, action.payload);
    } catch (error) {
        yield put(getGiftsImagesTextFailure(error));
    }
}

export function* onGetGiftsImageTextStart() {
    yield takeLatest(GiftSetsActionTypes.GET_GIFTS_IMAGES_NAME_START, getGiftsImageTextStart);
}

export function* setGiftsNamesStart(action) {
    try {
        yield call(createGiftsNamesStart, action.payload);
    } catch (error) {
        yield put(setGiftsNamesFailed(error));
    }
}

export function* onSetGiftsNamesStart() {
    yield takeLatest(GiftSetsActionTypes.SET_GIFTS_NAMES_START, setGiftsNamesStart);
}

export function* giftSetsSagas() {
    yield all([
        fork(onCountGiftSets),
        fork(onInsertGiftSet),
        fork(onLoadGiftSetStart),
        fork(onGetGiftsImageTextStart),
        fork(onSetGiftsNamesStart)
    ]);
}