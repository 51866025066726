import Header from "../../components/header/header.component";
import FooterFinal from "../footer/footer.component";

const Dmca = _ => {
  return <>
    <Header titlePage={"DMCA"} />
    <section className="section section-verify-ama">
      <div className="container">
        <div className="row">
          <div className="col s12 m8 offset-m2 l15 offset-13 deep-orange-text text-darken-1">

            If you are a copyright owner or an agent thereof and believe, in good faith, that any materials provided on the Service infringe upon your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act (see 17 U.S.C 512) (“DMCA”)
            <ul>
              <li>The date of your notification;</li>
              <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
              <li>A description of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
              <li>A description of the material that is claimed to be infringing or to be the subject of infringing activity and information sufficient to enable us to locate such work;</li>
              <li>Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and/or email address;</li>
              <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
              <li>A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
              <li>Counter-Notices. If you believe that your User Content that has been removed from the Site is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use the content in your User Content, you may send a counter-notice containing the following information to our copyright agent using the contact information set forth above:</li>
              <li>Your physical or electronic signature;</li>
              <li>A description of the content that has been removed and the location at which the content appeared before it was removed;</li>
              <li>A statement that you have a good faith belief that the content was removed as a result of a mistake or a misidentification of the content; and</li>
              <li>Your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court in New York, and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</li>
            </ul>
            If a counter-notice is received by the Company copyright agent, the Company may send a copy of the counter-notice to the original complaining party informing such person that it may reinstate the removed content in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may (in the Company's discretion) be reinstated on the Site in 10 to 14 business days or more after receipt of the counter-notice.

          </div>
        </div>
      </div>
    </section>
    <FooterFinal />
  </>;
};

export default Dmca;