import { OrganizationContainer } from "./organization.styles";
import { Dropdown, Divider, Button, Icon, Modal, TextInput, Row, Col, Collection, CollectionItem } from "react-materialize";

const Organization = props => {

  return <>
    <section className="section section-pending-list">
      <OrganizationContainer className="container">
        <div className="row">
          <div className="col s12 m12 l12">
            <div className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-0">
              <Row>
                <Col
                  s={4}
                >
                  <h4>Organization</h4>
                </Col>
                <Col
                  s={6}
                >
                  <Modal
                    actions={[
                      <Button
                        onClick={() => { }}
                        modal="close"
                        node="button"
                        waves="light"
                        className="blue lighten-2 white-text"
                      >
                        Confirm
                      </Button>,
                      <span style={{ padding: "10px" }}>&nbsp;</span>,
                      <Button
                        modal="close"
                        node="button"
                        waves="light"
                        className="white black-text"
                      >
                        Cancel
                      </Button>,
                    ]}
                    bottomSheet={false}
                    fixedFooter={false}
                    header="Create new organization"
                    id="new_org"
                    open={false}
                    options={{
                      dismissible: true,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                    className="black-text"
                  >
                    <TextInput
                      id="orgName"
                      placeholder="Organization Name"
                      onChange={() => { }}
                      icon="add"
                      name="orgName"
                      type="text"
                      required
                      value={""}
                    />

                    <TextInput
                      id="orgEmail"
                      placeholder="Organization Email"
                      onChange={() => { }}
                      icon="email"
                      name="email"
                      type="email"
                      required
                      value={""}
                    />
                  </Modal>

                </Col>
              </Row>

              <br />
              <form>
                <table className="highlight responsive-table">
                  <thead>
                    <tr>
                      <th style={{ width: "5rem" }}>Organization name</th>
                      <th style={{ width: "10rem" }}>Roles</th>
                      <th style={{ width: "1rem" }}>&nbsp;</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>khairul@tunabear.com</td>
                      <td>Owner</td>
                      <td></td>
                    </tr>

                  </tbody>
                </table>
                <br />
              </form>
            </div>
          </div>
        </div>
      </OrganizationContainer>
    </section>

  </>;
};



export default Organization;
