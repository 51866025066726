import { createSelector } from 'reselect';

const isAuthorized = state => state.receive;
const allItems = state => state.receive.items;
const theGiver = state => state.receive.giver;
const wasGiftClaimed = state => state.receive.wasGiftClaimed;
const verifyEmailDone = state => state.receive.verifyEmailDone;
const emailConfirmProgress = state => state.receive.emailConfirmProgress;

export const selectIsAuthorized = createSelector(
    [isAuthorized],
    receive => receive.authorized
);

export const selectPassword = createSelector(
    [isAuthorized],
    receive => receive.password
);

export const selectItems = createSelector(
    [allItems],
    items => items
);

export const selectGiver = createSelector(
    [theGiver],
    giver => giver
);

export const selectWasGiftClaimed = createSelector(
    [wasGiftClaimed],
    claimed => claimed
);

export const selectVerifyEmailDone = createSelector(
    [verifyEmailDone],
    done => done,
);

export const selectEmailConfirmProgress = createSelector(
    [emailConfirmProgress],
    progress => progress,
);