import { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectShowOtpModal } from '../../redux/user/user.selectors';
import { connect } from 'react-redux';
import { showOtpModal, sendOtpStart, authAttempt } from '../../redux/user/user.actions';
import FormInput from '../../components/form-input/form-input.component';
import { ButtonsContainer } from "./otp-modal.styles";
import { selectCurrentUser, selectProcessing } from '../../redux/user/user.selectors';
import "./otp-modal.styles.scss";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import useSocNsRm from '../../hooks/useSocNsRm';
import {doNothingOnReturnKey} from "../../utils/keyCapture";
const OtpModal = (props) => {
    const [otpCode, setOtpCode] = useState('');
    const { giver_exe, currentUser } = props;

    const handleSubmit = event => {
        event.preventDefault();
    }

    const handleCloseModal = () => {
        if (otpCode !== "") {
            const { sendOtp, history, setAuthAttempt } = props;
            setAuthAttempt({
                status: "success", // success, or error
                code: "",
                message: "Checking your OTP code..",
                processing: true,
            });
            const payload = {
                code: btoa(otpCode),
                currentUser
            };
            sendOtp(payload);
            if (giver_exe) {
                history.push("/giver");
            }
        }
    }

    const handleChangeOtp = e => {
        setOtpCode(e.target.value);
    }

    const currentRoom = `user-${currentUser.id}`;
    const [socket, joinTheRoom] = useSocNsRm(currentRoom, "/purchasing");

    useEffect(() => {
        joinTheRoom(currentRoom, socket);
        return () => { };
    });

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col s12 m12">
                        <h4>Watch your phone or email. </h4>
                        <span>Note that because this is a time-sensitive process, please enter the OTP code as soon as it comes in. </span>
                    </div></div>
                <form
                    className='otp-modal-form'
                    onSubmit={handleSubmit}
                    onKeyPress={doNothingOnReturnKey}
                >
                    <div className="row">
                        <div className="col s12 m12">

                            <FormInput
                                type='text'
                                name='otpCode'
                                value={otpCode}
                                onChange={handleChangeOtp}
                                label='Otp Code'
                                required
                            ></FormInput>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12 m12">

                            <ButtonsContainer>


                                {
                                    giver_exe ? <button onClick={handleCloseModal} className="btn btn-large btn-extended__076c2b37 orange waves-effect waves-light" type="submit">Confirm<i className="material-icons left">send</i></button>
                                        :
                                        <Link to="to-give"><button onClick={handleCloseModal} className="btn btn-large btn-extended__076c2b37 orange waves-effect waves-light" type="submit" disabled={otpCode === ""}>Confirm<i className="material-icons left">send</i></button></Link>
                                }
                            </ButtonsContainer>
                        </div>
                    </div>

                </form>
            </div>

        </>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    showModal: selectShowOtpModal,
    isProcessing: selectProcessing
});

const mapDispatchToProps = dispatch => ({
    showOtpModal: showIt => dispatch(showOtpModal(showIt)),
    sendOtp: code => dispatch(sendOtpStart(code)),
    setAuthAttempt: setting => dispatch(authAttempt(setting)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OtpModal));
