import {Modal} from "react-materialize";
import LoadingComponent from "../../components/loading/loading.component";
import {Field, Form} from "react-final-form";
import {AddCredCardText, DataContainer} from "../giver-selection/credit-card-modal.styles";
import Card from "../../components/credit-card/Card";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "../../components/credit-card/cardUtils";
import React, {useEffect, useState} from "react";
import {LoadingGif, LoadingTag} from "./giver-execution.styles";
import OtpModal from "../../components/otp-modal/otp-modal.component";
import { withRouter } from 'react-router-dom';
import {Link} from "react-router-dom";


const InitBuyModalComponent = ({history, authAttempt}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [counter, setCounter] = useState(23);
    const isAuthenticationVerificationComplete = _ => !authAttempt.processing && authAttempt.status === "success";
    const [stepMessage, setStepMessage] = useState("Verifying your credentials");

    const initialCountDown = _ => /*authAttempt.processing &&
        authAttempt.code !== "PASSWORD_OK" authAttempt.message &&*/
        counter > 0 && authAttempt.code !== "OTP_REQUIRED" && authAttempt.status !== "error" &&
        <LoadingTag className="col deep-orange-text text-darken-1">
            <LoadingComponent showProgressBar={true} counter={counter} message={stepMessage} />
            <br />
        </LoadingTag>;

    const counterDone = _ => counter === 0 && authAttempt.code !== "OTP_REQUIRED" && authAttempt.status !== "error" &&
        <LoadingTag className="col deep-orange-text text-darken-1">
        <h4>Authentication Passed!</h4>
        <span>
                Congratulations your purchase was activated.
              </span>
        <br />
            <span>
                <Link to="/user/to-give">Done</Link>
            </span>
    </LoadingTag>;

    const otpModalHandler = _ => authAttempt.code === "OTP_REQUIRED" &&
      <div className="card-panel hoverable verify-ama__65d2355b yellow darken-2 black-text"><OtpModal giver_exe /></div>;

    const emailPassFailed = _ => !authAttempt.processing && authAttempt.status === "error" && <LoadingTag className="col deep-orange-text text-darken-1">
      <h4>Authentication Failed!</h4>
      <span>
                {authAttempt.message}<br />
                And remember, everything is case-sensitive. <br />
                Please try <Link to="/user/dashboard">again</Link>.
              </span>
      <br />
    </LoadingTag>;


  useEffect(() => {
      setModalOpen(_ => true);
        let c = setInterval(function() {
            setCounter(prevState => {
                if(prevState > 8) {
                  setStepMessage(_ => authAttempt?.message);
                }
                if(prevState === 8) {
                    setStepMessage(_ => "Almost there");
                }
                if(prevState === 2) {
                    setStepMessage(_ => "We're Done!");
                }
                if(prevState === 1) {
                    clearInterval(c);
                    return 0;
                }
                return --prevState;
            });
        }, 1000);
        return () => {
          setModalOpen(_ => false);
        }
    }, []);
    return <>
        <Modal
            actions={[
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header="Initializing purchase"
            className="black-text"
            id="modalInitPurchase"
            style={{ height: 'auto' }}
            open={isModalOpen}
            options={{
                dismissible: false,
                endingTop: '10%',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: '4%'
            }}
        >


            <section className="section section-verify-ama">
                <div className="container">
                    <div className="row">
                        <div className="col s12 m8 offset-m2 l15 offset-13">
                            {initialCountDown()}
                            {otpModalHandler()}
                            {emailPassFailed()}
                            {counterDone()}
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="section section-verify-ama">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col s12 m8 offset-m2 l15 offset-13">*/}

            {/*                {authAttempt.processing && authAttempt.code !== "PASSWORD_OK" && <LoadingTag className="col deep-orange-text text-darken-1">*/}
            {/*                    <h4>Verifying your credentials...</h4>*/}
            {/*                    <span>*/}
            {/*    {authAttempt.message}{" "}*/}
            {/*  </span>*/}
            {/*                    <br />*/}
            {/*                    <>*/}
            {/*                        <LoadingGif*/}
            {/*                            src="https://media2.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"*/}
            {/*                            alt="Loading GIF"*/}
            {/*                        />*/}
            {/*                    </>*/}
            {/*                </LoadingTag>*/}
            {/*                }*/}

            {/*                {authAttempt.processing && authAttempt.code === "PASSWORD_OK" && <div className="card-panel hoverable verify-ama__65d2355b yellow darken-2 black-text"><OtpModal giver_exe /></div>}*/}

              {/*              {!authAttempt.processing && authAttempt.status === "error" && <LoadingTag className="col deep-orange-text text-darken-1">*/}
              {/*                  <h4>Authentication Failed!</h4>*/}
              {/*                  <span>*/}
              {/*  {authAttempt.message}<br />*/}
              {/*  And remember, everything is case-sensitive. <br />*/}
              {/*  Please try <Link to="/user/dashboard/recipients">again</Link>.*/}
              {/*</span>*/}
              {/*                  <br />*/}
              {/*              </LoadingTag>*/}
              {/*              }*/}

            {/*                {isAuthenticationVerificationComplete() && <LoadingTag className="col deep-orange-text text-darken-1">*/}
            {/*                    <h4>Authentication Passed!</h4>*/}
            {/*                    <span>*/}
            {/*    {authAttempt.message}<br />*/}
            {/*    Congratulations your purchase was activated.*/}
            {/*  </span>*/}
            {/*                    <br />*/}
            {/*                </LoadingTag>*/}
            {/*                }*/}

            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

        </Modal>
    </>;

};

export default withRouter(InitBuyModalComponent);
