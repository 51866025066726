export const ReceiveActionTypes = {
    SET_AUTHORIZED: 'SET_AUTHORIZED',
    SET_PASSWORD: 'SET_PASSWORD',
    SET_ITEMS: 'SET_ITEMS',
    SET_GIVER: 'SET_GIVER',
    CONFIRM_GIFT_SELECTION_START: 'CONFIRM_GIFT_SELECTION_START',
    CONFIRM_GIFT_SELECTION_SUCCESS: 'CONFIRM_GIFT_SELECTION_SUCCESS',
    CONFIRM_GIFT_SELECTION_FAILURE: 'CONFIRM_GIFT_SELECTION_FAILURE',
    EMAIL_CONFIRM_PROGRESS_START: 'EMAIL_CONFIRM_PROGRESS_START',
    EMAIL_CONFIRM_PROGRESS_SUCCESS: 'EMAIL_CONFIRM_PROGRESS_SUCCESS',
    EMAIL_CONFIRM_PROGRESS_FAILED: 'EMAIL_CONFIRM_PROGRESS_FAILED',
    RESET_GIFT_CLAIMED_TO_FALSE: 'RESET_GIFT_CLAIMED_TO_FALSE',
    CONFIRM_GIFT_TREE_START: 'CONFIRM_GIFT_TREE_START',
    CONFIRM_GIFT_TREE_SUCCESS: 'CONFIRM_GIFT_TREE_SUCCESS',
    CONFIRM_GIFT_TREE_FAILED: 'CONFIRM_GIFT_TREE_FAILED',
    // TOGGLE_SELECTED: 'TOGGLE_SELECTED'
}
