import Header from "../../components/header/header.component";
import FooterFinal from "../footer/footer.component";
import React, {useEffect} from "react";
import treeplant from "../../assets/plantree.png";
import {MainCard} from "./gift-tree.styles";
import { connect } from "react-redux";
import {confirmGiftTreeStart} from "../../redux/receive/receive.actions";

function GiftTreePage({giftTree}) {
  useEffect(() => {
    giftTree();
  }, []);
    return (
      <>
        <Header titlePage={"Thank you for choosing a Tree."} />
        <section className="section section-recipient">
          <div className="container">
            <div className="row">
              <div className="col s12 m8 offset-m2 l6 offset-l3">
                <MainCard className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-5">
                  <h4>You have selected </h4>
                  <h5>To plant a tree!</h5>
                  <img src={treeplant} width={150} height={150} />
                </MainCard>
              </div>
            </div>
          </div>
        </section>
        <FooterFinal />
      </>

    );
};

const mapDispatchToProps = (dispatch) => ({
  giftTree: _ => dispatch(confirmGiftTreeStart()),
});

export default connect(null, mapDispatchToProps)(GiftTreePage);
