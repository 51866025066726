import EcardControlContainer from "./ecard-control.container";
import EcardRow from "../../components/ecard-row/ecard-row.component";
import { createStructuredSelector } from "reselect";
import { selectTemplateArray } from "../../redux/user/user.selectors";
import { connect } from "react-redux";
import { DownloadWrapper } from "./ecard-control.styles";
import { CSVLink } from "react-csv";
import React, { useState } from "react";
import { Table } from "react-materialize";
import { ECardContainer, CardPanelContainer } from "./ecard-control.styles";

const headers = [
  { label: "Card name", key: "template_name" },
  { label: "Pending Approval", key: "num_pending_approval" },
  { label: "Approved", key: "num_approved" },
  { label: "Purchased", key: "num_purchased" },
  { label: "Needs Action", key: "num_need_action" }
];

const EcardControl = ({ allTemplates }) => {

  const csvLinkEl = React.createRef();
  const [csvFilename, setCsvFilename] = useState(`${(new Date()).toString().substring(0, 21).replaceAll(":", " ").replaceAll(" ", "_")}_ecard_stats.csv`);

  const downloadReport = async _ => {
    setCsvFilename(_ => `${(new Date()).toString().substring(0, 21).replaceAll(":", " ").replaceAll(" ", "_")}_ecard_stats.csv`);

    csvLinkEl.current.link.click();
  };

  return (
    <EcardControlContainer>
      {({ onSubmitForm, onChecked }) => {
        return (
          <section className="section ecard-control">
            <ECardContainer>
              <div className="row">
                <div className="col s12 m12">
                  <CardPanelContainer className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-0">
                    <h4>Ecards</h4>
                    <span>
                      This is a list of Ecard templates that was created by you:{" "}
                    </span>
                    <form onSubmit={onSubmitForm}>
                      <Table>
                        <thead>
                          <tr>
                            <th>View &nbsp;&nbsp;&nbsp; Card name</th>
                            <th>Pending approval</th>
                            <th>Approved</th>
                            <th>Purchased</th>
                            <th>Needs Action</th>
                            {/* <th>Active</th> */}
                          </tr>
                        </thead>
                        {allTemplates?.map((val, index) => (
                          <EcardRow
                            key={index}
                            onChecked={onChecked}
                            theTemplate={val}
                          />
                        ))}
                      </Table>

                      <br />
                      <div className="container">
                        <div className="row">
                          <DownloadWrapper className="col s12 m12" onClick={downloadReport}>
                            <p><i className="material-icons">file_download</i></p>
                            <p>Download CSV</p>
                            <CSVLink
                              headers={headers}
                              filename={csvFilename}
                              data={allTemplates}
                              ref={csvLinkEl}
                            />
                          </DownloadWrapper>
                        </div>
                      </div>


                    </form>
                  </CardPanelContainer>
                </div>
              </div>
            </ECardContainer>
          </section>
        );
      }}
    </EcardControlContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  allTemplates: selectTemplateArray,
});

export default connect(mapStateToProps)(EcardControl);
