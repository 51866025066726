import Header from "../../components/header/header.component"
import FooterFinal from "../footer/footer.component";

const Thank = () => { 
  return <>
    <Header titlePage={"Thank you"} />
    <section className="section section-verify-ama">
      <div className="container">
        <div className="row">
          <div className="col s12 m8 offset-m2 l15 offset-13 deep-orange-text text-darken-1">

              <h4>Your purchase was activated <br />and is on it's way. </h4>
              <span>Please feel free to give more. </span>

          </div>
        </div>
      </div>
    </section>
    <FooterFinal />
  </>;
};

export default Thank;