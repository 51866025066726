import GiftSetsActionTypes from './gift-sets.types';

export const countGiftSets = currentUser => ({
    type: GiftSetsActionTypes.COUNT_GIFT_SETS,
    payload: currentUser
});

export const countGiftSetsFailure = error => ({
    type: GiftSetsActionTypes.COUNT_GIFT_SETS_FAILURE,
    payload: error
});

export const countGiftSetsSuccessful = () => ({
    type: GiftSetsActionTypes.COUNT_GIFT_SETS_SUCCESSFUL
});

export const setGiftSetsCount = amount => ({
    type: GiftSetsActionTypes.SET_GIFT_SETS_COUNT,
    payload: amount
});

export const fetchGiftSetsStart = giftSetsData => ({
    type: GiftSetsActionTypes.FETCH_GIFT_SETS_START,
    payload: giftSetsData
});

export const fetchGiftSetsSuccess = () => ({
    type: GiftSetsActionTypes.FETCH_GIFT_SETS_SUCCESS
});

export const fetchGiftSetsFailure = error => ({
    type: GiftSetsActionTypes.FETCH_GIFT_SETS_FAILURE,
    payload: error
});

export const setGiftSet = giftSet => ({
  type: GiftSetsActionTypes.SET_GIFT_SET,
  payload: giftSet,
});

export const insertGiftSetByOrderStart = giftSetData => ({
    type: GiftSetsActionTypes.INSERT_GIFT_SET_START,
    payload: giftSetData
});

export const insertGiftSetByOrderSuccess = () => ({
    type: GiftSetsActionTypes.INSERT_GIFT_SET_SUCCESS
});

export const insertGiftSetByOrderFailure = error => ({
    type: GiftSetsActionTypes.INSERT_GIFT_SET_FAILURE,
    payload: error
});

export const loadGiftSetsStart = currentUser => ({
    type: GiftSetsActionTypes.LOAD_GIFT_SETS_START,
    payload: currentUser
});

export const loadGiftSetsSuccess = allGiftsOfSetArray => ({
    type: GiftSetsActionTypes.LOAD_GIFT_SETS_SUCCESS,
    payload: allGiftsOfSetArray
});

export const loadGiftSetsFailure = error => ({
    type: GiftSetsActionTypes.LOAD_GIFT_SETS_FAILURE,
    payload: error
});

export const getGiftsImagesTextStart = ({ currentUser, giftSet }) => ({
    type: GiftSetsActionTypes.GET_GIFTS_IMAGES_NAME_START,
    payload: { currentUser, giftSet, processing: true }
});

export const getGiftsImagesTextSuccess = ({ currentUser, names, landingImages, gifts }) => ({
    type: GiftSetsActionTypes.GET_GIFTS_IMAGES_NAME_SUCCESS,
    payload: { currentUser, names, landingImages, gifts, processing: false }
});

export const getGiftsImagesTextFailure = error => ({
    type: GiftSetsActionTypes.GET_GIFTS_IMAGES_NAME_FAILURE,
    payload: error
});

export const setCardCategory = category => ({
    type: GiftSetsActionTypes.SET_CARD_CATEGORY,
    payload: category
});

export const getCardCategory = () => ({
    type: GiftSetsActionTypes.GET_CARD_CATEGORY
});

export const setCardName = name => ({
    type: GiftSetsActionTypes.SET_CARD_NAME,
    payload: name
});

export const getCardName = () => ({
    type: GiftSetsActionTypes.GET_CARD_NAME
});

export const setGiftsNamesStart = payload => ({
    type: GiftSetsActionTypes.SET_GIFTS_NAMES_START,
    payload
});

export const setGiftsNamesSuccess = () => ({
    type: GiftSetsActionTypes.SET_GIFTS_NAMES_SUCCESS
});

export const setGiftsNamesFailed = error => ({
    type: GiftSetsActionTypes.SET_GIFTS_NAMES_FAILED,
    payload: error
});
