import UserActionTypes from './user.types';

export const setCurrentUser = user => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user
});

export const insertUserStart = user => ({
  type: UserActionTypes.INSERT_USER_START,
  payload: user
});

export const insertUserSuccess = _ => ({
  type: UserActionTypes.INSERT_USER_SUCCESS
});

export const insertUserFailure = error => ({
  type: UserActionTypes.INSERT_USER_FAILURE,
  payload: error
});

export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START
});

export const fusionAuthSignInStart = () => ({
  type: UserActionTypes.FUSIONAUTH_SIGN_IN_START,
})

export const signInSuccess = user => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const signInFailure = error => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error
});

export const emailSignInStart = emailAndPassword => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
});

export const showOtpModal = (show) => ({
  type: UserActionTypes.SHOW_OTP_MODAL,
  payload: show
});

export const sendOtpStart = code => ({
  type: UserActionTypes.SEND_OTP_START,
  payload: code
});

export const sendOtpSuccess = () => ({
  type: UserActionTypes.SEND_OTP_SUCCESS
});

export const sendOtpFailure = error => ({
  type: UserActionTypes.SEND_OTP_FAILURE,
  payload: error
});

export const verifyAmazonStart = currentUser => ({
  type: UserActionTypes.VERIFY_AMAZON_START,
  payload: currentUser
});

export const verifyAmazonSuccess = () => ({
  type: UserActionTypes.VERIFY_AMAZON_SUCCESS
});

export const verifyAmazonFailure = error => ({
  type: UserActionTypes.VERIFY_AMAZON_FAILURE,
  payload: error
});

export const processStart = () => ({
  type: UserActionTypes.PROCESSING_START,
  payload: true
});

export const processEnd = () => ({
  type: UserActionTypes.PROCESSING_END,
  payload: false
});

export const resetCodeStart = currentUser => ({
  type: UserActionTypes.RESET_CODE_START,
  payload: currentUser
});

export const resetCodeSuccess = () => ({
  type: UserActionTypes.RESET_CODE_SUCCESS
});

export const resetCodeFailure = error => ({
  type: UserActionTypes.RESET_CODE_FAILURE,
  payload: error
});

export const setDestinationPath = path => ({
  type: UserActionTypes.SET_DESTINATION_PATH,
  payload: path
});

export const resetDestinationPath = () => ({
  type: UserActionTypes.RESET_DESTINATION_PATH
});

export const getPendingRecipientsStart = currentUser => ({
  type: UserActionTypes.GET_PENDING_RECIPIENTS_START,
  payload: currentUser
});

export const getPendingRecipientsSuccess = (pendingRecipients) => ({
  type: UserActionTypes.GET_PENDING_RECIPIENTS_SUCCESS,
  payload: pendingRecipients
});

export const getPendingRecipientsFailure = error => ({
  type: UserActionTypes.GET_PENDING_RECIPIENTS_FAILURE,
  payload: error
});

export const getRecipientsByTemplatenameStart = ({currentUser, template_name}) => ({
  type: UserActionTypes.GET_RECIPIENTS_BY_TEMPLATENAME_START,
  payload: {currentUser, template_name},
});

export const getRecipientsByTemplatenameSuccess = recipients => ({
  type: UserActionTypes.GET_RECIPIENTS_BY_TEMPLATENAME_SUCCESS,
  payload: recipients,
});

export const getRecipientsByTemplatenameFailed = error => ({
  type: UserActionTypes.GET_RECIPIENTS_BY_TEMPLATENAME_FAILED,
  payload: error,
})

export const executePurchaseStart = payload => ({
  type: UserActionTypes.EXECUTE_PURCHASE_START,
  payload
});

export const executePurchaseSuccess = () => ({
  type: UserActionTypes.EXECUTE_PURCHASE_SUCCESS
});

export const executePurchaseFailure = error => ({
  type: UserActionTypes.EXECUTE_PURCHASE_FAILURE,
  payload: error
});

export const verifyAmazonAccountAvailableStart = currentUser => ({
  type: UserActionTypes.VERIFY_AMAZON_ACCOUNT_AVAILABLE_START,
  payload: currentUser
});

export const verifyAmazonAccountAvailableSuccess = () => ({
  type: UserActionTypes.VERIFY_AMAZON_ACCOUNT_AVAILABLE_SUCCESS
});

export const verifyAmazonAccountAvailableFailure = error => ({
  type: UserActionTypes.VERIFY_AMAZON_ACCOUNT_AVAILABLE_FAILURE,
  payload: error
});

export const getAmazonAccountStart = currentUser => ({
  type: UserActionTypes.GET_AMAZON_ACCOUNT_START,
  payload: currentUser
})

export const getAmazonAccountSuccess = account => ({
  type: UserActionTypes.GET_AMAZON_ACCOUNT_SUCCESS,
  payload: account
})

export const getAmazonAccountFailure = error => ({
  type: UserActionTypes.GET_AMAZON_ACCOUNT_FAILURE,
  payload: error
})

export const saveTemplateStart = payload => ({
  type: UserActionTypes.SAVE_TEMPLATE_START,
  payload
})

export const saveTemplateSuccess = () => ({
  type: UserActionTypes.SAVE_TEMPLATE_SUCCESS
})

export const saveTemplateFailure = error => ({
  type: UserActionTypes.SAVE_TEMPLATE_FAILURE,
  payload: error
})

export const getGiftTemplateArrayStart = payload => ({
  type: UserActionTypes.GET_GIFT_TEMPLATE_ARRAY_START,
  payload
})

export const getGiftTemplateArraySuccess = templateArray => ({
  type: UserActionTypes.GET_GIFT_TEMPLATE_ARRAY_SUCCESS,
  payload: templateArray
})

export const getGiftTemplateArrayFailure = error => ({
  type: UserActionTypes.GET_GIFT_TEMPLATE_ARRAY_FAILURE,
  payload: error
})

export const fetchGiftHtmlTemplateStart = payload => ({
  type: UserActionTypes.FETCH_GIFT_HTML_TEMPLATE_START,
  payload
})

export const fetchGiftHtmlTemplateSuccess = theHtml => ({
  type: UserActionTypes.FETCH_GIFT_HTML_TEMPLATE_SUCCESS,
  payload: theHtml
})

export const fetchGiftHtmlTemplateFailure = error => ({
  type: UserActionTypes.FETCH_GIFT_HTML_TEMPLATE_FAILURE,
  payload: error
})

export const setAmazonEmail = email => ({
  type: UserActionTypes.SET_AMAZON_EMAIL,
  payload: email
})

export const setAmazonEmailSuccess = () => ({
  type: UserActionTypes.SET_AMAZON_EMAIL_SUCCESS
})

export const setAmazonEmailFailure = error => ({
  type: UserActionTypes.SET_AMAZON_EMAIL_FAILURE,
  payload: error
})


export const setAmazonPke = pke => ({
  type: UserActionTypes.SET_AMAZON_PKE,
  payload: pke
})

export const setAmazonPkeSuccess = () => ({
  type: UserActionTypes.SET_AMAZON_PKE_SUCCESS,
})

export const setAmazonPkeFailure = error => ({
  type: UserActionTypes.SET_AMAZON_PKE_FAILURE,
  payload: error
})

export const updateCreditCardStart = payload => ({
  type: UserActionTypes.UPDATE_CREDITCARD_START,
  payload
})

export const updateCreditCardFailed = error => ({
  type: UserActionTypes.UPDATE_CREDITCARD_FAILED,
  payload: error
})

export const updateCreditCardSuccess = () => ({
  type: UserActionTypes.UPDATE_CREDITCARD_SUCCESS
})

export const updateTemplateDataStart = payload => ({
  type: UserActionTypes.UPDATE_TEMPLATE_DATA_START,
  payload
})

export const updateTemplateDataSuccess = payload => ({
  type: UserActionTypes.UPDATE_TEMPLATE_DATA_SUCCESS,
  payload
})

export const updateTemplateDataFailed = error => ({
  type: UserActionTypes.UPDATE_TEMPLATE_DATA_FAILED,
  payload: error
})

export const fetchTemplateDataStart = payload => ({
  type: UserActionTypes.FETCH_TEMPLATE_DATA_START,
  payload
})

export const fetchTemplateDataSuccess = payload => ({
  type: UserActionTypes.FETCH_TEMPLATE_DATA_SUCCESS,
  payload
})

export const fetchTemplateDataFailed = error => ({
  type: UserActionTypes.FETCH_TEMPLATE_DATA_FAILED,
  payload: error
})

export const fetchSelectedGiftUrlStart = payload => ({
  type: UserActionTypes.FETCH_SELECTED_GIFT_URL_START,
  payload
});

export const fetchSelectedGiftUrlSuccess = payload => ({
  type: UserActionTypes.FETCH_SELECTED_GIFT_URL_SUCCESS,
  payload
});

export const fetchSelectedGiftUrlFailed = error => ({
  type: UserActionTypes.FETCH_SELECTED_GIFT_URL_FAILED,
  payload: error
});

export const setUserAsStart = userEmail => ({
  type: UserActionTypes.SET_USER_AS_START,
  payload: userEmail
});

export const setUserAsFailure = error => ({
  type: UserActionTypes.SET_USER_AS_FAILURE,
  payload: error
});

export const setUserAsSuccess = _ => ({
  type: UserActionTypes.SET_USER_AS_SUCCESS
});

export const fetchAllTemplatesStart = currentUser => ({
  type: UserActionTypes.FETCH_ALL_TEMPLATES_START,
  payload: currentUser
});

export const fetchAllTemplatesSuccess = allTemplates => ({
  type: UserActionTypes.FETCH_ALL_TEMPLATES_SUCCESS,
  payload: allTemplates
});

export const fetchAllTemplatesFailure = error => ({
  type: UserActionTypes.FETCH_ALL_TEMPLATES_FAILURE,
  payload: error
});

export const updateActiveEcardStart = ecardObj => ({
  type: UserActionTypes.UPDATE_ACTIVE_ECARD_START,
  payload: ecardObj
});

export const updateActiveEcardSuccess = _ => ({
  type: UserActionTypes.UPDATE_ACTIVE_ECARD_SUCCESS
});

export const updateActiveEcardFailure = error => ({
  type: UserActionTypes.UPDATE_ACTIVE_ECARD_FAILURE,
  payload: error
});

export const deleteRecipientByCreatedIdStart = ({ theRecipient, currentUser }) => ({
  type: UserActionTypes.DELETE_RECIPIENT_BY_CREATED_ID_START,
  payload: { theRecipient, currentUser }
});

export const deleteRecipientByCreatedIdSuccess = _ => ({
  type: UserActionTypes.DELETE_RECIPIENT_BY_CREATED_ID_SUCCESS
});

export const deleteRecipientByCreatedIdFailure = error => ({
  type: UserActionTypes.DELETE_RECIPIENT_BY_CREATED_ID_FAILURE,
  payload: error
});

export const updateApprovedRecipientStart = payload => ({
  type: UserActionTypes.UPDATE_APPROVED_RECIPIENT_START,
  payload
});

export const updateApprovedRecipientSuccess = _ => ({
  type: UserActionTypes.UPDATE_APPROVED_RECIPIENT_SUCCESS,
});

export const updateApprovedRecipientFailure = error => ({
  type: UserActionTypes.UPDATE_APPROVED_RECIPIENT_FAILURE,
  payload: error,
});

export const authAttempt = payload => ({
  type: UserActionTypes.AUTH_ATTEMPT,
  payload
})

export const resetAuthAttempt = _ => ({
  type: UserActionTypes.RESET_AUTH_ATTEMPT,
})

export const setNewOrgStart = payload => ({
  type: UserActionTypes.SET_NEW_ORG_START,
  payload,
});

export const setNewOrgFailure = error => ({
  type: UserActionTypes.SET_NEW_ORG_FAILURE,
  payload: error,
});

export const setNewOrgSuccess = payload => ({
  type: UserActionTypes.SET_NEW_ORG_SUCCESS,
  payload,
});

export const fetchCCInfoStart = payload => ({
  type: UserActionTypes.FETCH_CC_INFO_START,
  payload,
});

export const fetchCCInfoFailure = error => ({
  type: UserActionTypes.FETCH_CC_INFO_FAILURE,
  payload: error,
});

export const fetchCCInfoSuccess = payload => ({
  type: UserActionTypes.FETCH_CC_INFO_SUCCESS,
  payload,
});

export const fetchOrdersStart = payload => ({
  type: UserActionTypes.FETCH_ORDERS_START,
  payload,
});

export const fetchOrdersSuccess = orders => ({
  type: UserActionTypes.FETCH_ORDERS_SUCCESS,
  payload: orders,
});

export const fetchOrdersFailure = error => ({
  type: UserActionTypes.FETCH_ORDERS_FAILURE,
  payload: error,
});
