import Header from '../../components/header/header.component';
import { MainContainer, NextButton, TopicContainer, SelectContainer, ParentSelectContainer, HorizontalLineBottom, PersonalizedMessageContainer, SenderContainer, HeaderContainer } from './choose-ecard.styles';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCardCategory } from '../../redux/gift-sets/gift-sets.actions';
import { createStructuredSelector } from 'reselect';
import { selectCardCategory, selectedGiftSet } from '../../redux/gift-sets/gift-sets.selectors';
import CardList from '../../components/card-list/card-list.component';
import FooterFinal from '../footer/footer.component';
import { selectAllCards, selectedCard } from '../../redux/cards/cards.selectors';
import { Textarea, TextInput } from 'react-materialize';
import { setMessage, setSender } from '../../redux/cards/cards.actions';
import { getGiftsImagesTextStart } from "../../redux/gift-sets/gift-sets.actions";
import { useEffect } from "react";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { monthString, dayString } from "../../utils/dateformat";
import { useState } from 'react';
import namor from "namor";
import "./choose-ecard.styles.scss";

const ChooseEcard = ({ setMessage, setSender, setCategoryName, getAllCards, getSelectedCard, giftSet, currentUser, fetchNamesImages }) => {
  const [templateType, setTemplateType] = useState(namor.generate());
  const [templateName, setTemplateName] = useState("");


  useEffect(() => {

    (async () => {
      await fetchNamesImages({ currentUser, giftSet });
    })();

    const dateNow = new Date();
    const month = monthString(dateNow);
    const day = dayString(dateNow);
    const template_name = `${dateNow.getUTCFullYear()}_${month}_${day}_${dateNow.getUTCHours()}${dateNow.getUTCMinutes()}_${dateNow.getUTCSeconds()}_${templateType}`;
    setTemplateName((_) => template_name);

  }, [currentUser]);

  const handleSubmit = () => {
  }

  const onChangePersonalizedMessage = e => {
    setMessage(e.target.value);
  };

  const onChangeSender = e => {
    setSender(e.target.value);
  };

  return <>
    <div className="choose-ecard">
      <Header titlePage={"Create your e-card"} />
      <section className="section section-choose-ecard">
        <div className="container">
          <div className="row row-1 purple-text text-darken-1">
            <div className="col s12 m12">
              <TopicContainer>
                <img src={getSelectedCard.cardUrl} alt="" width="368" height="308" />
              </TopicContainer>
            </div>
          </div>
          <div className="row row-1 purple-text text-darken-1 center">
            <MainContainer className="">
              <div className="">
                <HeaderContainer>
                  <b>Select a category</b>
                </HeaderContainer>

                <HeaderContainer>
                  <SelectContainer name="Category Name Select" id="category-name-select" onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}>
                    <option key="holiday" value="holiday">Holiday</option>
                    <option key="birthday" value="birthday">Birthday</option>
                    <option key="congratulations" value="congratulations">Congratulations</option>
                    <option key="thank_you" value="thank_you">Thank you</option>
                    <option key="graduation" value="graduation">Graduation</option>
                  </SelectContainer>
                </HeaderContainer>
                <HeaderContainer>
                  <b>Choose a design</b>
                </HeaderContainer>
                <CardList />
              </div>
            </MainContainer>
          </div>
          <div className="row row-1 purple-text text-darken-1">
            <div>
              <HorizontalLineBottom />
            </div>
            <SenderContainer style={{ backgroundColor: 'white' }}>
              <TextInput
                id="sender"
                placeholder="Sender name"
                style={{ borderBottom: 0, placeholder: 'red' }}
                l={12}
                m={12}
                s={12}
                xl={12}
                onChange={onChangeSender}
                inputClassName="blackPlaceholder_39e9178d4fde2d70e2f1"
              />
            </SenderContainer>
            <div>
              <PersonalizedMessageContainer style={{ backgroundColor: 'white' }}>
                <Textarea id="message"
                  l={12}
                  m={12}
                  s={12}
                  xl={12}
                  placeholder="Add a personalized message"
                  style={{ borderBottom: 0 }}
                          className="blackPlaceholder_39e9178d4fde2d70e2f1"
                  onChange={onChangePersonalizedMessage}
                />
              </PersonalizedMessageContainer>

            </div>
            <Link to={{
              pathname: "review-gift",
              state: {
                templateName
              }
            }}><NextButton onClick={handleSubmit} className="btn btn-large waves-effect waves-light white-text" type="button">Next<i className="material-icons left">send</i></NextButton></Link>

          </div>
        </div>
      </section>

    </div>
    <FooterFinal />
  </>;
};

const mapStateToProps = createStructuredSelector({
  getCategoryName: selectCardCategory,
  getSelectedCard: selectedCard,
  getAllCards: selectAllCards,
  currentUser: selectCurrentUser,
  giftSet: selectedGiftSet,
});

const mapDispatchToProps = dispatch => ({
  setCategoryName: name => dispatch(setCardCategory(name)),
  setSender: sender => dispatch(setSender(sender)),
  setMessage: message => dispatch(setMessage(message)),
  fetchNamesImages: (payload) => dispatch(getGiftsImagesTextStart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseEcard);
