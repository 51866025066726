import React from 'react';

import './sign-in.styles.scss';

import { SigninSectionContainer } from './sign-in.styles';

import { googleSignInStart, emailSignInStart, fusionAuthSignInStart } from '../../redux/user/user.actions';

import { connect } from 'react-redux';
import { Button } from 'react-materialize'
import Header from '../header/header.component'
import FooterFinal from '../../pages/footer/footer.component';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }
  fusionAuthSignIn22() {
    try {
      fetch(`http://localhost:3001/login`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
        },
      })
        .then(response => response.json())
        .then(response => {
          console.log("RESPONSE: ", response);
          this.setState(
            {
              body: response
            })
        });

    } catch (error) {
      console.log(error.message);
    }

  }

  handleSubmit = async event => {
    event.preventDefault();
    const { emailSignInStart } = this.props;
    const { email, password } = this.state;
    emailSignInStart({ email, password });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { googleSignInStart, fusionAuthSignIn } = this.props;
    return (
      <>
        <Header titlePage={"Login"} />
        <SigninSectionContainer>
          <section className="section section-login">
            <div className="container">
              <div className="row">
                <div className="col s12 m8 offset-m2 l6 offset-l3">
                  <div className="card-panel hoverable login__58c5c660">
                    <h4>I already have an account with Google.</h4>
                    <span>Sign in with.. </span>
                    <form onSubmit={this.handleSubmit}>
                      <Button
                        node="a"
                        className="btn btn-large white btn-extended__ae140c51 purple-text"
                        waves="light"
                        onClick={googleSignInStart}
                      >
                        Google Sign-In
                      </Button>
                    </form>
                    {/* ~ OR ~
                                        <Button
                                            node="a"
                                            className="btn btn-large white btn-extended__ae140c51 purple-text"
                                            waves="light"
                                            onClick={this.fusionAuthSignIn22}
                                            href="http://localhost:9011/oauth2/authorize?client_id=50055a13-e2df-45bf-9af0-7016bc3f06e5&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Foauth-callback&response_type=code"
                                        >
                                            Register / Sign In with FusionAuth
                                        </Button> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </SigninSectionContainer>
        <FooterFinal />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  googleSignInStart: () => dispatch(googleSignInStart()),
  fusionAuthSignIn: () => dispatch(fusionAuthSignInStart()),
  emailSignInStart: (email, password) => dispatch(emailSignInStart({ email, password }))
});

export default connect(null, mapDispatchToProps)(SignIn);
