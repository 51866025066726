import React, {useState} from "react";
import GiftUrlInput from "../UI/GiftUrlInput/GiftUrlInput";
import GiftUrlInputContainer from "../UI/GiftUrlInput/GiftUrlInputContainer";
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { selectAllGifts } from "../../redux/gift-sets/gift-sets.selectors";
import { useEffect } from "react";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { loadGiftSetsStart } from "../../redux/gift-sets/gift-sets.actions";

const OneGiftSet = ({
    loadGiftSetsStart,
    currentUser,
    selectAllGifts }) => {
  const [url, setUrl] = useState("");
    useEffect(()=>{
        loadGiftSetsStart(currentUser);
    },[loadGiftSetsStart, currentUser]);
    return <React.Fragment>
        {
            Object.keys(selectAllGifts).map((key, index) => {
                return <GiftUrlInputContainer key={index}>
                    {
                        __ => <GiftUrlInput keyValue={key} item={url} items={selectAllGifts} handleChange={__.handleChange} />
                    }
                </GiftUrlInputContainer>;
            })
        }
    </React.Fragment>;
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    selectAllGifts: selectAllGifts,
});

const mapDispatchToProps = dispatch => ({
    loadGiftSetsStart: (currentUser) => dispatch(loadGiftSetsStart(currentUser)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OneGiftSet);
