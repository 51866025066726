import { all, call, fork, takeLatest, put } from "redux-saga/effects";
import {confirmGiftSelectionFailure, confirmGiftTreeFailed, emailConfirmProgressFailed} from "./receive.actions";
import { ReceiveActionTypes } from "./receive.types";
import {createConfirmGiftSelection, createEmailConfirmProgress, startGiftTreeProgress} from './receive.hooks';
import { push } from 'react-router-redux';
export function* startConfirmGiftSelection(action) {
    try {
        yield call(createConfirmGiftSelection, action.payload);
        yield put(push('/'))
    } catch (error) {
        yield put(confirmGiftSelectionFailure(error));
        yield put(push('/'))
    }
}

export function* onConfirmGiftSelectionStart() {
    yield takeLatest(ReceiveActionTypes.CONFIRM_GIFT_SELECTION_START, startConfirmGiftSelection)
}

export function* startEmailConfirmProgress(action) {
    try {
        yield call(createEmailConfirmProgress, action.payload);
    } catch (error) {
        yield put(emailConfirmProgressFailed(error));
    }
}

export function* onEmailConfirmProgressStart() {
    yield takeLatest(ReceiveActionTypes.EMAIL_CONFIRM_PROGRESS_START, startEmailConfirmProgress);
}

export function* startGiftTree(action) {
  try{
    yield call(startGiftTreeProgress, action.payload);
  }catch (e) {
    yield put(confirmGiftTreeFailed(e));
  }
}

export function* onGiftTreeStart() {
  yield takeLatest(ReceiveActionTypes.CONFIRM_GIFT_TREE_START, startGiftTree);
}

export function* receiveSagas() {
    yield all([
        fork(onConfirmGiftSelectionStart),
        fork(onEmailConfirmProgressStart),
        fork(onGiftTreeStart),
    ]);
}
