import { Button, Modal } from "react-materialize";
import { Form, Field } from "react-final-form";
import React, { useEffect, useState } from "react";
import { AddCredCardText, DataContainer } from "./credit-card-modal.styles";
import Card from "../../components/credit-card/Card";
import { formatCVC, formatCreditCardNumber, formatExpirationDate } from "../../components/credit-card/cardUtils";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { fetchCCInfoStart, updateCreditCardStart } from "../../redux/user/user.actions";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser, selectPaycards, selectProcessingCard } from "../../redux/user/user.selectors";
import LoadingComponent from "../../components/loading/loading.component";
import {CENTURY, localInfo} from "../../utils/constants";

const CreditCardModalComponent = ({ updateCard, currentUser, fetchCreditCardBasicInfo, isFetchingCards, paycards }) => {
  const { email, idToken } = currentUser;
  const [isModalOpen, setModalOpen] = useState(true);
  const onSubmit = _ => { };
  const validateCreditCardInputs = creditCardObjectValues => {
    const EXPECTED_NUM_ITEMS = 4;
    return Object.values(creditCardObjectValues).length === EXPECTED_NUM_ITEMS;
  };
  const onModalConfirmClick = creditCardObjectValues => {
    // console.log("values: ", Object.values(creditCardObjectValues).length === 4);
    if (validateCreditCardInputs(creditCardObjectValues)) {
      toast.info(
        "Updating your card.",
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      updateCard({
        email,
        idToken,
        creditCard: window.btoa(
          JSON.stringify(creditCardObjectValues, 0, 2)
        ),
      });
      setModalOpen(!isModalOpen);
    }
    else {
      toast.error(
        "Card validation failed. Please enter proper values.",
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const shouldCreditCardModalOpen = _ => {
    if(paycards && paycards?.length > 0 ) {
      const [expiryMonth,expiryYear] = paycards[0].expiry.split("/").map(num=>parseInt(num));
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear() % 1000;
      if(expiryYear === currentYear) {
        if(expiryMonth <= currentMonth) {
          setModalOpen(true);
        }
        else {
          setModalOpen(false);
        }
      }
      else if(expiryYear < currentYear) {
        setModalOpen(true);
      }
      else {
        setModalOpen(false);
      }

    }
    else { setModalOpen(true); }

  };

  useEffect(() => {
    fetchCreditCardBasicInfo(currentUser);
    shouldCreditCardModalOpen();
  }, [currentUser]);


  return <>
    <Modal
      actions={[
      ]}
      bottomSheet={false}
      fixedFooter={false}
      header="Before you proceed.."
      className="black-text"
      id="modalCreditCard"
      style={{ height: 'auto' }}
      open={isModalOpen}
      options={{
        dismissible: false,
        endingTop: '10%',
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: '4%'
      }}
    >
      {isFetchingCards && <LoadingComponent message="Checking your credit card" circleGif={true} />}
      {!isFetchingCards && <>
        It seems like we do not have your credit card information. <br />
        We need your default credit card that you set as default in Amazon. <br />
        This card will be charged for every successful automated purchase. <br />
        <div className="col s12 m6">
          <div className="container">
            <div className="row">
              <div className="col s12 m12 black-text text-darken-4">
                <Form
                  onSubmit={onSubmit}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                    active,
                  }) => {
                    return <React.Fragment>
                      <h3 className="purple-text text-darken-1">
                        <b><AddCredCardText>Add your credit card information</AddCredCardText></b>
                      </h3>
                      <Card
                        number={values.number || ""}
                        name={values.name || ""}
                        expiry={values.expiry || ""}
                        cvc={values.cvc || ""}
                        focused={active}
                      />
                      <h5>
                        Card Number
                      </h5>
                      <DataContainer>
                        <Field
                          name="number"
                          id="number"
                          component="input"
                          type="text"
                          pattern="[\d| ]{16,22}"
                          placeholder="  Card Number"
                          format={formatCreditCardNumber}
                        />
                      </DataContainer>
                      <h5>
                        Name on card
                      </h5>
                      <DataContainer>
                        <Field
                          name="name"
                          id="name"
                          component="input"
                          type="text"
                          placeholder="  Name on card"
                        />
                      </DataContainer>
                      <h5>
                        Expiration
                      </h5>
                      <DataContainer>
                        <Field
                          name="expiry"
                          id="expiry"
                          component="input"
                          type="text"
                          pattern="\d\d/\d\d"
                          placeholder="  MM/YY"
                          format={formatExpirationDate}
                        />
                      </DataContainer>
                      <h5>
                        CVC
                      </h5>
                      <DataContainer>
                        <Field
                          name="cvc"
                          id="cvc"
                          component="input"
                          type="text"
                          pattern="\d{3,4}"
                          placeholder="  XXX"
                          format={formatCVC}
                        />
                      </DataContainer>
                      <br />
                      <div className="buttons">
                        <div className="container">
                          <div className="row">
                            <div className="col s12 m12">
                              <button
                                className="btn btn-large orange btn-extended__60a3d156 waves-effect waves-light"
                                type="button"
                                onClick={_ => { onModalConfirmClick(values); }}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </React.Fragment>
                  }}
                >

                </Form>
              </div>
            </div>
          </div>
        </div>
      </>}
    </Modal>
  </>;
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isFetchingCards: selectProcessingCard,
  paycards: selectPaycards,
});

const mapDispatchToProps = dispatch => ({
  updateCard: payload => dispatch(updateCreditCardStart(payload)),
  fetchCreditCardBasicInfo: (payload) => dispatch(fetchCCInfoStart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardModalComponent);
