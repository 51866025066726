import GiftSetsActionTypes from './gift-sets.types';

/**
 * Each object in arr represents one gift
 * **/
export const INITIAL_STATE = {
    currentUser: null,
    giftSets: {
        arr: [
            {
                selected: true,
                gifts: {
                    giftChoice_0_0: '',
                    giftChoice_0_1: '',
                    giftChoice_0_2: ''
                },
                names: ["", "", ""],
                landingImages: ["", "", ""],
                card_category: "holiday",
                card_name: ""
            }
        ],
        giftSetsCount: 1
    },
    error: null,
    processing: true
};

export const giftSetsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GiftSetsActionTypes.COUNT_GIFT_SETS:
            return {
                ...state,
                currentUser: action.payload
            }
      case GiftSetsActionTypes.SET_GIFT_SET:
      {
        const t = [...state.giftSets.arr];
        t[0].gifts = {...action.payload};
        return {
          ...state,
          giftSets: {
            ...state.giftSets,
            arr: [
              ...t,
            ]
          },
        }
      }
        case GiftSetsActionTypes.FETCH_GIFT_SETS_START:
        case GiftSetsActionTypes.INSERT_GIFT_SET_START:
            return {
                ...state,
                giftSets: {
                    ...state.giftSets,
                    arr: action.payload.arr
                },
                // processing: true
            }
        case GiftSetsActionTypes.FETCH_GIFT_SETS_SUCCESS:
        case GiftSetsActionTypes.INSERT_GIFT_SET_SUCCESS:
        case GiftSetsActionTypes.COUNT_GIFT_SETS_SUCCESSFUL:
            return {
                ...state,
                error: null,
                // processing: false
            }
        case GiftSetsActionTypes.GET_GIFTS_IMAGES_NAME_SUCCESS:
            return {
                ...state,
                error: null,
                processing: false,
                giftSets: {
                    ...state.giftSets,
                    arr: [
                        {
                            selected: true,
                            gifts: action.payload.gifts,
                            names: action.payload.names,
                            landingImages: action.payload.landingImages
                        }
                    ]
                }
            }
        case GiftSetsActionTypes.FETCH_GIFT_SETS_FAILURE:
        case GiftSetsActionTypes.INSERT_GIFT_SET_FAILURE:
        case GiftSetsActionTypes.COUNT_GIFT_SETS_FAILURE:
        case GiftSetsActionTypes.LOAD_GIFT_SETS_FAILURE:
        case GiftSetsActionTypes.GET_GIFTS_IMAGES_NAME_FAILURE:
        case GiftSetsActionTypes.SET_GIFTS_NAMES_FAILED:
            return {
                ...state,
                error: action.payload,
                processing: false,
            }
        case GiftSetsActionTypes.SET_GIFT_SETS_COUNT:
            return {
                ...state,
                giftSets: {
                    ...state.giftSets,
                    giftSetsCount: action.payload
                }
            }
        case GiftSetsActionTypes.LOAD_GIFT_SETS_START:
        case GiftSetsActionTypes.GET_GIFTS_IMAGES_NAME_START:
            return {
                ...state,
                currentUser: action.payload.currentUser,
                error: null,
                processing: true
            }
        case GiftSetsActionTypes.LOAD_GIFT_SETS_SUCCESS:
            return {
                ...state,
                error: null,
                // processing: false,
                giftSets: {
                    arr: [
                        {
                            selected: true,
                            gifts: {
                                giftChoice_0_0: action.payload === [] ? '' : action.payload[0],
                                giftChoice_0_1: action.payload === [] ? '' : action.payload[1],
                                giftChoice_0_2: action.payload === [] ? '' : action.payload[2]
                            }
                        }
                    ]
                }
            }
        case GiftSetsActionTypes.SET_CARD_CATEGORY:
            return {
                ...state,
                error: null,
                // processing: false,
                giftSets: {
                    ...state.giftSets,
                    arr: [
                        {
                            ...state.giftSets.arr[0],
                            card_category: action.payload,
                        }
                    ]
                }
            }
        case GiftSetsActionTypes.SET_CARD_NAME:
            return {
                ...state,
                error: null,
                // processing: false,
                giftSets: {
                    ...state.giftSets,
                    arr: [
                        {
                            ...state.giftSets.arr[0],
                            card_name: action.payload,
                        }
                    ]
                }
            }
        default:
            return state;
    }
};

export default giftSetsReducer;
