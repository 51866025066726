import styled, { css } from 'styled-components';

export const MessagePara = styled.p`
  text-align: center;
  margin: 6rem auto;
  padding: 0rem 3rem;
`;


const claimedButtonBackground = css`
  background: red; 
`;

const getEnabledButton = (props) => props.disabled ? claimedButtonBackground : null;

const hideSelectButton = css`
  display: none;
  visibility: hidden;
`;

const toShow = props => props.show ? null:hideSelectButton;

export const SelectButton = styled.a`
  background: #15d798; 
  border-radius: 11px; 
  padding: 10px 35px; 
  color: #ffffff; 
  display: inline-block; 
  font: bold 10px / 1 'Open Sans', sans-serif; 
  text-align: center; 
  text-decoration: none;
  margin-top: 1px;
  ${getEnabledButton}
  ${toShow}
`;

