import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyAywMULMOaq31fsyLrGXPYNK_F2veQmitk",
    authDomain: "lazy-giver.firebaseapp.com",
    databaseURL: "https://lazy-giver.firebaseio.com",
    projectId: "lazy-giver",
    storageBucket: "lazy-giver.appspot.com",
    messagingSenderId: "358855851581",
    appId: "1:358855851581:web:ad292843a2d62e55803454",
    measurementId: "G-V17J9SKRCT"
};

export const createUserProfileDocument = async (userAuth, additionalData) => { 
    if (!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();

    // if user does not exist
    if (!snapShot.exists) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            });
        } catch (error) {
            console.log('error creating user', error.message);
        }
    }
    return userRef;
};

firebase.initializeApp(config);

export const getCurrentUser = () => { 
    return new Promise((resolve, reject) => { 
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);            
        }, reject);
    });
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithRedirect(googleProvider);

export default firebase;
