import React from 'react';
import './card.styles.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectItems } from '../../redux/receive/receive.selectors';
import { GridItem } from './card.styles';
import { selectAllCards } from '../../redux/cards/cards.selectors';
const Card = ({ pathSrc, changeHandler, position, getAllCards, type, selected }) => {

    return <div onClick={() => { changeHandler(position, type); }}>
        <GridItem selected={selected}><div><img src={pathSrc} alt="" width="158" height="98" /></div></GridItem>
    </div>
};

const mapStateToProps = createStructuredSelector({
    items: selectItems,
    getAllCards: selectAllCards
});

export default connect(mapStateToProps)(Card);