import styled from 'styled-components';

export const InputUrlBox = styled.input`
  background-color: white !important;
  border-radius: 50px !important;
  padding-left: 3rem !important;
  padding-right: 1rem !important;
  width: 30rem !important;

  @media screen and (max-width: 800px) {
    width: 17rem !important;
  }

  &.danger {
    border: 3px solid #a21f1f !important;
  }
`;
