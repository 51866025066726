import { all, fork } from 'redux-saga/effects';
import { userSagas } from './user/user.sagas';
import { giftSetsSagas } from './gift-sets/gift-sets.sagas';
import { receiveSagas } from './receive/receive.sagas';

export default function* rootSaga() {
    yield all([
        fork(userSagas),
        fork(giftSetsSagas),
        fork(receiveSagas)
    ]);
}