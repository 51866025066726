const GiftSetsActionTypes = {
    COUNT_GIFT_SETS: 'COUNT_GIFT_SETS',
    COUNT_GIFT_SETS_FAILURE: 'COUNT_GIFT_SETS_FAILURE',
    COUNT_GIFT_SETS_SUCCESSFUL: 'COUNT_GIFT_SETS_SUCCESSFUL',
    SET_GIFT_SETS_COUNT: 'SET_GIFT_SETS_COUNT',
    FETCH_GIFT_SETS_START: 'FETCH_GIFT_SETS_START',
    FETCH_GIFT_SETS_SUCCESS: 'FETCH_GIFT_SETS_SUCCESS',
    FETCH_GIFT_SETS_FAILURE: 'FETCH_GIFT_SETS_FAILURE',
    SET_GIFT_SET: 'SET_GIFT_SET',
    INSERT_GIFT_SET_START: 'INSERT_GIFT_SET_START',
    INSERT_GIFT_SET_SUCCESS: 'INSERT_GIFT_SET_SUCCESS',
    INSERT_GIFT_SET_FAILURE: 'INSERT_GIFT_SET_FAILURE',
    LOAD_GIFT_SETS_START: 'LOAD_GIFT_SETS_START',
    LOAD_GIFT_SETS_SUCCESS: 'LOAD_GIFT_SETS_SUCCESS',
    LOAD_GIFT_SETS_FAILURE: 'LOAD_GIFT_SETS_FAILURE',
    GET_GIFTS_IMAGES_NAME_START: 'GET_GIFTS_IMAGES_NAME_START',
    GET_GIFTS_IMAGES_NAME_SUCCESS: 'GET_GIFTS_IMAGES_NAME_SUCCESS',
    GET_GIFTS_IMAGES_NAME_FAILURE: 'GET_GIFTS_IMAGES_NAME_FAILURE',
    SET_CARD_CATEGORY: 'SET_CARD_CATEGORY',
    GET_CARD_CATEGORY: 'GET_CARD_CATEGORY',
    SET_CARD_NAME: 'SET_CARD_NAME',
    GET_CARD_NAME: 'GET_CARD_NAME',
    SET_GIFTS_NAMES_START: 'SET_GIFTS_NAMES_START',
    SET_GIFTS_NAMES_SUCCESS: 'SET_GIFTS_NAMES_SUCCESS',
    SET_GIFTS_NAMES_FAILED: 'SET_GIFTS_NAMES_FAILED'
};
export default GiftSetsActionTypes;
