import { channel, thePromise } from '../main.hooks';
import { countGiftSets, getGiftsImageText, insertGiftSetByOrder, loadGiftSet, setGiftsNames } from './gift-sets.services';

export function createCountGiftSets(payload) {
    const theChannel = channel();
    const countGiftSetsPromise = thePromise(countGiftSets, payload);
    return [countGiftSetsPromise, theChannel];
}

export function createInsertGiftSetByOrder(payload) {
    const theChannel = channel();
    const insertGiftSetByOrderPromise = thePromise(insertGiftSetByOrder, payload);
    return [insertGiftSetByOrderPromise, theChannel];
}

export function createLoadGiftSetStart(payload) {
    const theChannel = channel();
    const loadGiftSetStartPromise = thePromise(loadGiftSet, payload);
    return [loadGiftSetStartPromise, theChannel]
}

export function createGetGiftsImageTextStart(payload) {
    const theChannel = channel();
    const getGiftsImageTextStartPromise = thePromise(getGiftsImageText, payload);
    return [getGiftsImageTextStartPromise, theChannel];
}

export function createGiftsNamesStart(payload) {
    const theChannel = channel();
    const setGiftsNamesStartPromise = thePromise(setGiftsNames, payload);
    return [setGiftsNamesStartPromise, theChannel];
}