const CardsTypes = {
  UPDATE_CARDS: "UPDATE_CARDS",
  FETCH_ALL_ECARDS_TEMPLATES_START: "FETCH_ALL_ECARDS_TEMPLATES_START",
  FETCH_ALL_ECARDS_TEMPLATES_SUCCESS: "FETCH_ALL_ECARDS_TEMPLATES_SUCCESS",
  FETCH_ALL_ECARDS_TEMPLATES_FAILURE: "FETCH_ALL_ECARDS_TEMPLATES_FAILURE",
  SET_SELECTED_CARD: "SET_SELECTED_CARD",
  SET_SENDER: "SET_SENDER",
  SET_MESSAGE: "SET_MESSAGE",
};

export default CardsTypes;