import styled from "styled-components";

export const OptionHeaderContainer = styled.div`
  text-align: center;
  color: #6335bf;
`;

export const CopyInstructionContainer = styled.div`
  text-align: center;
  color: black;
  justify-content: center;
  margin-left: 1rem;
`;
