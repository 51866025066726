import styled from "styled-components";

export const GridItem = styled.div`
  padding: 20px;
  font-size: 30px;
  text-align: center;
`;
export const GridContainer = styled.div`
  padding: 10px;
`;
