import React, {useState} from 'react';
import { InputUrlBox } from './GiftUrlInput.styles';

const GiftUrlInput = ({ keyValue, handleChange, item }) => {

  return (
    <div className='input-field' key={keyValue}>
      <div style={{ color: "#5938BA" }}>
        <i className="material-icons prefix" style={{ paddingLeft: "1rem" }}>card_giftcard</i>
      </div>
      <InputUrlBox
        type="url"
        name={keyValue}
        value={item}
        onChange={handleChange}
        onClick={handleChange}
        placeholder={'Copy and paste an Amazon.com product url'}
        label={'Url of Gift Choice'}
      />
    </div >
  );
};

export default GiftUrlInput;
