import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  user => (user.currentUser && user.currentUser?.idToken !== "" && user.currentUser?.idToken !== null) ? user.currentUser : null
);

export const selectOrders = createSelector(
  [selectUser],
  user => user.orders,
);


export const selectShowOtpModal = createSelector(
  [selectUser],
  user => user.showModal
);

export const selectProcessing = createSelector(
  [selectUser],
  user => user.processing
);

export const selectDestinationPath = createSelector(
  [selectUser],
  user => user.destinationPath
);

export const selectPendingRecipients = createSelector(
  [selectUser],
  user => user.pendingRecipients
);

export const selectAmazonAccountVerified = createSelector(
  [selectUser],
  user => user.amazonAccountVerified
);

export const selectAmazonAccount = createSelector(
  [selectUser],
  user => user.amazonAccount
)

export const selectTemplateArray = createSelector(
  [selectUser],
  user => user.templateArray
)

export const selectHtmlTemplate = createSelector(
  [selectUser],
  user => user.currentTemplate
)

export const selectTemplateData = createSelector(
  [selectUser],
  user => user.templateData
)

export const selectSelectedGiftUrl = createSelector(
  [selectUser],
  user => user.selectedGiftUrl
)

export const selectAuthAttemptData = createSelector(
  [selectUser],
  user => user.authAttempt
)

export const selectProcessingCard = createSelector(
  [selectUser],
  user => user.processingCard
);

export const selectPaycards = createSelector(
  [selectUser],
  user => user.paycards
)
