import { END, eventChannel } from 'redux-saga';

let emit = null;

export const channel = () => eventChannel(emitter => {
    emit = emitter;
    return () => { };
});

export const thePromise = (theService, payload) => theService(payload, event => { 
    if (event.loaded.total === 1) {
            emit(END);
        }
    emit(event.loaded.total);
});
