import Header from "../../components/header/header.component"
// import AboutSection from "../../components/about/about.component";
import "./main.styles.scss"
import { useEffect } from "react";
import { connect } from 'react-redux';
import { setDestinationPath } from "../../redux/user/user.actions";
import HowWorksComponent from "../../components/how-works/how-works.component";
import FooterFinal from "../../pages/footer/footer.component";
import Chatbot from 'react-best-chatbot';

const MainComponent = ({ setDestinationPath }) => {
  const steps = [
    {
      id: 1,
      content: "Hi, there!",
      goTo: 2,
    },
    {
      id: 2,
      content: "How can I help you?",
      options: [
        {
          content: "I want to start giving 🐱",
          value: 1,
          goTo: "give",
        },
        {
          content: "Where is the setting? 🐶",
          value: 2,
          goTo: 3,
        },
        {
          content: "Where can I enter the Amazon urls? 🐻",
          value: 3,
          goTo: "give",
        },
        {
          content: "No I don't need anything. 🐻",
          value: 4,
          goTo: 4,
        },

      ],
    },
    {
      id: "give",
      content: "Ok. So you have to click Give in the top menu, then input your Amazon url gifts and proceed.",
      goTo: 2,
    },
    {
      id: 3,
      content: "Click settings on the menu, and there you go.",
      goTo: 2,
    },
    {
      id: 4,
      content: "Wow! You're good to go. Let me know if I can help you with anything else.",
      receiveInput: true,
    }
  ];
  useEffect(() => {
    setDestinationPath("/");
  }, []);
  return (
    <>
      <Header></Header>
      <Chatbot steps={steps} />
      <HowWorksComponent />
      <FooterFinal />
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  setDestinationPath: path => dispatch(setDestinationPath(path)),
});

export default connect(null, mapDispatchToProps)(MainComponent);